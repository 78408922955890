import { SET_DAILY_REPORTS, SET_ITEM_GROSS_PROFIT, SET_REPORT } from "../type";

const initialState = {
  report: {},   //overall report
  dailyReports: [],
  itemGrossProfit: []
};

const report = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORT:
      return {
        ...state,
        report: action.payload,
      };
    case SET_DAILY_REPORTS:
      return {
        ...state,
        dailyReports: action.payload,
      };
    case SET_ITEM_GROSS_PROFIT:
      return {
        ...state,
        itemGrossProfit: action.payload,
      };
    default:
      return state;
  }
};

export default report;
