import React, { useEffect } from "react";
import {
    Form,
    Typography,
    Space,
    Spin,
    Row,
    Col,
    Popconfirm,
    Table,
    Divider,
    InputNumber,
    message
} from "antd";
import Layout from "antd/lib/layout/layout";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import Label from "../../components/Label";
import IconButton from "../../components/IconButton";
import ShowText from "../../components/ShowText";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createPurchaseInvoiceCredit, deletePurchaseInvoiceCredit, getPurchaseInvoice } from "../../store/actions";
import { getReadableDateDisplay } from "../../utils/convertToHumanReadableTime";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const CreatePurchaseCredit = () => {
    const [form] = Form.useForm();
    const params = useParams();
    const dispatch = useDispatch();
    const id = params.id;

    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const purchaseInvoice = useSelector((state) => state.purchase.purchaseInvoice);
    const [creditTotal, setCreditTotal] = useState(0);
    const [payCreditRecords, setPayCreditRecords] = useState([]);
    const invoiceCode = purchaseInvoice?.invoice_code;
    console.log("invoice_code:", invoiceCode);
    const {t} = useTranslation();

    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        if (status.success) {
            form.resetFields();
            message.success(t("message.successCreateMessage"));
        }
        return () => status.success;
    }, [form, status.success, t]);

    useEffect(() => {
        if (status.deleteSuccess) {
            message.success(t("message.successDeleteMessage"));
        }
        return () => status.deleteSuccess;
    }, [status.deleteSuccess, t]);

    useEffect(() => {
        dispatch(getPurchaseInvoice(id));
    }, [dispatch, id])

    useEffect(() => {
        setCreditTotal(purchaseInvoice.credit);
        const transformedData = purchaseInvoice?.purchase_credits?.map((record, index) => {
            return {
                ...record,
                key: index,
            }
        });
        setPayCreditRecords(transformedData);
    }, [purchaseInvoice]);


    const onFinish = async (values) => {
        const data = {
            ...values,
            purchase_id: parseInt(id),
        }
        await dispatch(createPurchaseInvoiceCredit(data))
        await dispatch(getPurchaseInvoice(id));     //to get changed purchase invoice, call api that do that.
    }

    const handleDelete = async (recordID) => {
        await dispatch(deletePurchaseInvoiceCredit(recordID));
        await dispatch(getPurchaseInvoice(id));   //to get changed purchase invoice, call api that do that.

    }

    console.log("purchase invoice:", purchaseInvoice)


    const columns = [
        {
            title: t("purchaseInvoice.date"),
            dataIndex: "created_at",
            render: (created_at) => getReadableDateDisplay(created_at)
        },
        {
            title: t("purchaseInvoice.amount"),
            dataIndex: "amount",
        },
        {
            title: "Actions",
            dataIndex: "id",
            render: (id) => (
                <Popconfirm
                    title={t("purchaseInvoice.sureDelete")}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => { handleDelete(id) }}
                >
                    <IconButton icon={<DeleteOutlined />} bgColor="var(--red-color)"> </IconButton>
                </Popconfirm>

            ),
        },
    ];

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }} >
                <Space direction="vertical">
                    <Title level={3} style={{ marginBottom: "35px" }}>
                    {t("purchaseInvoice.createPurchaseCredit")}
                    </Title>
                    <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
                        <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <Space>
                                <Label text={t("purchaseInvoice.invoiceNo")} />
                                <ShowText text={invoiceCode} />
                            </Space>

                        </Col>
                        <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <Space>
                                <Label text={t("purchaseInvoice.creditAmount")} />
                                <ShowText text={creditTotal} />
                            </Space>

                        </Col>
                    </Row>
                    <Row >
                        <Col span={12} >
                            <Form
                                colon={false}
                                labelCol={{
                                    xl: {
                                        span: 8,
                                    },
                                }}
                                wrapperCol={{
                                    xl: {
                                        span: 24,
                                    },
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                form={form}
                                layout={"vertical"}
                            >
                                <Form.Item
                                    name="amount"
                                    label={t("purchaseInvoice.amount")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("purchaseInvoice.enterAmount"),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        placeholder={t("purchaseInvoice.pleaseEnterAmount")}
                                        style={{ borderRadius: "10px", width: "100%" }}
                                        size="large"
                                    />


                                </Form.Item>
                                <Form.Item style={{ textAlign: "right" }}>
                                    <LargeButton icon={<SaveOutlined />} text={t("component.save")} />
                                </Form.Item>
                            </Form>
                        </Col>


                        <Col span={12}>

                        </Col>
                    </Row>
                    <Divider />
                    <Title level={4} style={{ marginBottom: "20px" }}>
                    {t("purchaseInvoice.payRecord")}
                    </Title>
                    <Table
                        bordered
                        columns={columns}
                        pagination={{ defaultPageSize: 10 }}
                        dataSource={payCreditRecords}
                    />
                </Space>


            </Layout>
        </Spin>
    );
};

export default CreatePurchaseCredit;
