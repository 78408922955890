import React, { useState } from "react";
import {
  Form,
  Typography,
  Space,
  Table,
  Spin,
  Row,
  Col,
  Input,
  Select,
  message,
  Button,
  InputNumber
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
  SaveOutlined,
  PlusSquareOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import IconButton from "../../components/IconButton";
import InputUpload from "../../components/InputUpload";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createItems, getCategories } from "../../store/actions";
import { useTranslation } from "react-i18next"

const { Title, Text } = Typography;
const { Option } = Select;
const CreateItems = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [items, setItems] = useState([]);

  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const { t } = useTranslation();


  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch])

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      setItems([]);
      message.success(t("message.successCreateMessage"));
    }

    return () => status.success;
  }, [form, status.success, t]);



  const onFinish = (values) => {
    // if not upload image,
    if (fileList.length === 0) {
      message.error("ကျေးဇူးပြု၍ပစ္စည်းပုံထည့်ပါ");
    }
    else {
      setItems(
        [...items,
        { ...values, image: fileList[0], key: items.length + 1 }]
      );
      setFileList([]);
      form.resetFields();

    }

  }

  const handleDelete = (record) => {
    const filteredItems = items.filter((item) => item !== record);
    const transformedItems = filteredItems.map((item, index) => {
      return {
        ...item,
        key: index + 1
      }
    });
    setItems(transformedItems);
  };

  const handleSave = async () => {
    if (items.length === 0) {
      message.error(t("item.pleaseInsertItems"));
    } else {
      const formData = new FormData();
      items.forEach((item, index) => {
        formData.append(`products[${index}][code]`, item.code);
        formData.append(`products[${index}][category_id]`, item.category);  //coz in api , accept value as category_id
        formData.append(`products[${index}][name]`, item.name);
        formData.append(`products[${index}][cod_buy_price]`, item.cod_buy_price);
        formData.append(`products[${index}][cod_sale_price]`, item.cod_sale_price);
        formData.append(`products[${index}][credit_buy_price]`, item.credit_buy_price);
        formData.append(`products[${index}][credit_sale_price]`, item.credit_sale_price);
        formData.append(`images[${index}]`, item.image.originFileObj);
      });

      await dispatch(createItems(formData));
    }
  };

  const columns = [
    {
      title: t("item.image"),
      dataIndex: "image",
      render: (_, record) => (
        <img
          src={record.image.thumbUrl}
          alt="ပစ္စည်းပုံ"
          width={100}
          height={100}
        />
      ),
    },
    {
      title: t("item.code"),
      dataIndex: "code",
    },
    {
      title: t("item.category"),
      dataIndex: "category",

    },
    {
      title: t("item.name"),
      dataIndex: "name",
    },
    {
      title: t("item.codBuyPrice"),
      dataIndex: "cod_buy_price",
    },
    {
      title: t("item.codSalePrice"),
      dataIndex: "cod_sale_price",
    },
    {
      title: t("item.creditBuyPrice"),
      dataIndex: "credit_buy_price",
    },
    {
      title: t("item.creditSalePrice"),
      dataIndex: "credit_sale_price",
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (_, record) => (
        <IconButton icon={<DeleteOutlined />} bgColor="var(--red-color) " onClick={() => { handleDelete(record) }} />
      ),
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }} >
        <Space direction="vertical">
          <Title level={3}>
            {t("item.createItem")}
          </Title>
          <Row >

            <Col span={12} >
              <Form
                colon={false}
                labelCol={{
                  xl: {
                    span: 8,
                  },
                }}
                wrapperCol={{
                  xl: {
                    span: 24,
                  },
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                form={form}
                layout={"vertical"}
              >
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <InputUpload fileList={fileList} setFileList={setFileList} />
                  <Text type="secondary">{t("item.pleaseInsertImage")}</Text>
                </Space>
                <Form.Item
                  name="code"
                  label={t("item.code")}
                  rules={[
                    {
                      required: true,
                      message: t("item.pleaseCode"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("item.enterCode")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="category"
                  label={t("item.category")}
                  rules={[
                    {
                      required: true,
                      message: t("item.pleaseCategory"),
                    },
                  ]}
                >

                  <Select
                    showSearch
                    placeholder={t("item.enterCategory")}
                    optionFilterProp="children"
                    allowClear={true}
                    size="large"
                    style={{ borderRadius: "10px" }}
                  >

                    {categories.map((category) => (
                      <Option value={category.id} key={category.id}>
                        {category.name}
                      </Option>
                    ))}

                  </Select>

                </Form.Item>
                <Form.Item
                  name="name"
                  label={t("item.name")}
                  rules={[
                    {
                      required: true,
                      message: t("item.pleaseName"),
                    },
                  ]}
                >

                  <Input
                    placeholder={t("item.enterName")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="cod_buy_price"
                  label={t("item.codBuyPrice")}
                  rules={[
                    {
                      required: true,
                      message: t("item.pleaseCodBuyPrice"),
                    },
                  ]}
                >

                  <InputNumber
                    placeholder={t("item.enterCodBuyPrice")}
                    style={{ borderRadius: "10px", width: "100%" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="cod_sale_price"
                  label={t("item.codSalePrice")}
                  rules={[
                    {
                      required: true,
                      message: t("item.pleaseCodSalePrice"),
                    },
                  ]}
                >

                  <InputNumber
                    placeholder={t("item.enterCodSalePrice")}
                    style={{ borderRadius: "10px", width: "100%" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="credit_buy_price"
                  label={t("item.creditBuyPrice")}
                  rules={[
                    {
                      required: true,
                      message: t("item.pleaseCreditBuyPrice"),
                    },
                  ]}
                >

                  <InputNumber
                    placeholder={t("item.enterCreditBuyPrice")}
                    style={{ borderRadius: "10px", width: "100%" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="credit_sale_price"
                  label={t("item.creditSalePrice")}
                  rules={[
                    {
                      required: true,
                      message: t("item.pleaseCreditBuyPrice"),
                    },
                  ]}
                >

                  <InputNumber
                    placeholder={t("item.enterCreditSalePrice")}
                    style={{ borderRadius: "10px", width: "100%" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                  <Button
                    style={{
                      backgroundColor: "var(--primary-color)",
                      color: "var(--white-color)",
                      borderRadius: "10px",
                    }}
                    size="medium"
                    htmlType="submit"
                  >
                    <PlusSquareOutlined />
                    {t("component.addNew")}
                  </Button>
                </Form.Item>
              </Form>
            </Col>

            <Col span={12}>
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            pagination={{ position: ["none", "none"] }}
            dataSource={items}
          />
          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "right" }}
          >
            <Button
              style={{
                backgroundColor: "var(--primary-color)",
                color: "var(--white-color)",
                borderRadius: "10px",
              }}
              size="large"
              onClick={handleSave}
            >
              <SaveOutlined />
              {t("component.save")}
            </Button>
          </Space>

        </Space>

      </Layout>
    </Spin>
  );
};


export default CreateItems;
