import React, { useEffect } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Table,
  Spin,
  Popconfirm,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import { PlusSquareOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import MediumButton from "../../components/MediumButton";
import IconButton from "../../components/IconButton";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteMerchant, getMerchants } from "../../store/actions";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from "react-i18next";


const { Title } = Typography;

const ShowMerchants = () => {
  const merchants = useSelector((state) => state.merchant.merchants);
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const { t} = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    error.message !== null && message.error(error.message);
    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(t("message.successDeleteMessage"));
    }
    return () => status.success;
  }, [status.success, t]);


  useEffect(() => {
    dispatch(getMerchants());
  }, [dispatch]);

  const navigate = useNavigate();

  const handleDelete = async (id) => {
    await dispatch(deleteMerchant(id));
  }

  //for excel export
  const fileName = "Merchants";
  const transformedData = merchants.map((merchant)=>{
    return {
      Code: merchant.name,
      Name: merchant.name,
      CompanyName: merchant.company_name,
      CompanyPhone: merchant.company_phone,
      CompanyAddress: merchant.company_address,
      CompanyEmail: merchant.company_email,
    };
  });

  const columns = [
    {
      title: t("merchant.code"),
      dataIndex: "code",
    },
    {
      title: t("merchant.name"),
      dataIndex: "name",
    },
    {
      title: t("merchant.comName"),
      dataIndex: "company_name",
    },
    {
      title: t("merchant.comPhone"),
      dataIndex: "company_phone",
    },
    {
      title: t("merchant.comAddress"),
      dataIndex: "company_address",
    },
    {
      title: t("merchant.comEmail"),
      dataIndex: "company_email",
    },
    {
      title: t("merchant.remark"),
      dataIndex: "remark",
    },
    {
      title: t("component.actions"),
      dataIndex: "id",
      render: (id) => (
        <Space direction="horizontal">
          <IconButton icon={<EditOutlined />} onClick={() => { navigate(`/admin/edit-merchants/${id}`) }} bgColor="var(--green-color)"> </IconButton>
          <Popconfirm
            title={t("component.sureDelete")}
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDelete(id)}
          >
            <IconButton icon={<DeleteOutlined />} onClick={() => { }} bgColor="var(--red-color)"> </IconButton>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Spin spinning={false}>
      <Layout style={{ margin: "20px 40px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 18 }} md={{ span: 12 }}>
              <Title level={3}> {t("merchant.merchantList")}</Title>
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 6 }}>
              <MediumButton icon={<PlusSquareOutlined />} text={t("component.addNew")} bgColor="var(--primary-color)" onClick={() => { navigate("/admin/create-merchant") }} />
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 6 }}>
              <ExportToExcel apiData={transformedData} fileName={fileName}/>
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={merchants}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

export default ShowMerchants;
