import React, { useEffect } from "react";
import {
    Typography,
    Space,
    Row,
    Col,
    Table,
    Spin,
    Popconfirm,
    message
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
    EditOutlined,
    DeleteOutlined,
    PlusSquareOutlined,
} from "@ant-design/icons";
import IconButton from "../../components/IconButton";
import MediumButton from "../../components/MediumButton";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteExpenseName, getExpenseNames } from "../../store/actions";
import {useTranslation} from "react-i18next";
const { Title } = Typography;


const ShowExpenseNames = () => {
    const navigate = useNavigate();
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const expenseNames = useSelector((state) => state.expenseName.expenseNames);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        if (status.success) {
            message.success(t("message.successDeleteMessage"));
        }
        return () => status.success;
    }, [status.success, t]);

    useEffect(() => {
        dispatch(getExpenseNames());
    }, [dispatch]);

    const handleDelete = async (id) => {
        await dispatch(deleteExpenseName(id));
    }

    const columns = [
        {
            title: t("expenseName.expenseName"),
            dataIndex: "name",
        },
        {
            title: t("component.actions"),
            dataIndex: "id",
            render: (id) => (
                <Space direction="horizontal">
                    <IconButton icon={<EditOutlined />} onClick={() => { navigate(`/admin/edit-expense-names/${id}`) }} bgColor="var(--green-color)"> </IconButton>
                    <Popconfirm
                        title={t("component.sureDelete")}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => { handleDelete(id) }}
                    >
                        <IconButton icon={<DeleteOutlined />} onClick={() => { }} bgColor="var(--red-color)"> </IconButton>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }}>
                <Space direction="vertical" size="middle">
                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 21 }} md={{ span: 18 }}>
                            <Title level={3}> {t("expenseName.expenseNames")}</Title>
                        </Col>
                        <Col xl={{ span: 3 }} md={{ span: 6 }}>
                            <MediumButton icon={<PlusSquareOutlined />} text={t("component.addNew")} bgColor="var(--primary-color)" onClick={() => { navigate("/admin/create-expense-name") }} />
                        </Col>
                    </Row>

                    <Table
                        bordered
                        columns={columns}
                        pagination={{ defaultPageSize: 10 }}
                        dataSource={expenseNames}
                    />
                </Space>
            </Layout>
        </Spin>
    );
};

export default ShowExpenseNames;
