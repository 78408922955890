import React, { useEffect, useState } from "react";
import {
  Form,
  Typography,
  Space,
  Table,
  Spin,
  Row,
  Col,
  Input,
  Select,
  message,
  Button
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
  SaveOutlined,
  PlusSquareOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import IconButton from "../../components/IconButton";
import MediumButton from "../../components/MediumButton";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createExpenses, getExpenseNames } from "../../store/actions";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;
const CreateExpenses = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const expenseNames = useSelector((state) => state.expenseName.expenseNames);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [expenses, setExpenses] = useState([]);

  useEffect(() => {
    dispatch(getExpenseNames());
  }, [dispatch]);

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      setExpenses([]);
      message.success(t("message.successCreateMessage"));
    }

    return () => status.success;
  }, [form, status.success, t]);

  const onFinish = (values) => {
    setExpenses(
      [
        ...expenses,
        { ...values, key: expenses.length + 1 }
      ]
    )
    form.resetFields();
  }

  const handleDelete = (record) => {
    const filteredExpenses = expenses.filter((expense) => expense !== record);
    const transformedExpenses = filteredExpenses.map((expense, index) => {
      return {
        ...expense,
        key: index + 1
      }
    })
    setExpenses(transformedExpenses);
  }


  const handleSave = async () => {
    await dispatch(createExpenses(expenses));
  }
  const columns = [
    {
      title: t("expense.expenseName"),
      dataIndex: "name",
    },
    {
      title: t("expense.amount"),
      dataIndex: "amount",
    },
    {
      title: t("component.actions"),
      render: (record) => (
        <IconButton icon={<DeleteOutlined />} bgColor="var(--red-color)" onClick={() => handleDelete(record)} />
      ),
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }} >
        <Space direction="vertical" >
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 20 }} md={{ span: 16 }}>
              <Title level={3}>{t("expense.createExpense")}</Title>
            </Col>

            <Col xl={{ span: 4 }} md={{ span: 8 }}>
              <MediumButton icon={<PlusSquareOutlined />} text={t("expense.toCreateExpenseName")} bgColor="var(--primary-color)" onClick={() => navigate("/admin/create-expense-name")} />
            </Col>
          </Row>

          {/* Form data */}
          <Row >
            <Col xl={{ span: 12 }} >
              <Form
                colon={false}
                labelCol={{
                  xl: {
                    span: 8,
                  },
                }}
                wrapperCol={{
                  xl: {
                    span: 24,
                  },
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                form={form}
                layout={"vertical"}
              >
                <Form.Item
                  name="name"
                  label={t("expense.expenseName")}
                  rules={[
                    {
                      required: true,
                      message: t("expense.pleaseSelectExpense"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={t("expense.selectExpenseName")}
                    optionFilterProp="children"
                    allowClear={true}
                    size="large"
                    style={{ borderRadius: "10px" }}
                  >

                    {expenseNames.map((expenseName) => (
                      <Option key={expenseName.id} value={expenseName.name}>{expenseName.name}</Option>
                    ))}

                  </Select>

                </Form.Item>
                <Form.Item
                  name="amount"
                  label={t("expense.amount")}
                  rules={[
                    {
                      required: true,
                      message: t("expense.pleaseEnterAmount"),
                    },
                  ]}
                >

                  <Input
                    placeholder={t("expense.enterAmount")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />

                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                  <Button
                    style={{
                      backgroundColor: "var(--primary-color)",
                      color: "var(--white-color)",
                      borderRadius: "10px",
                    }}
                    size="medium"
                    htmlType="submit"
                  >
                    <PlusSquareOutlined />
                    {t("component.add")}
                  </Button>
                </Form.Item>
              </Form>
            </Col>

            <Col>
            </Col>
          </Row>

          <Table
            bordered
            columns={columns}
            pagination={{ position: ["none", "none"] }}
            dataSource={expenses}
          />

          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "right" }}
          >
            <Button
              style={{
                backgroundColor: "var(--primary-color)",
                color: "var(--white-color)",
                borderRadius: "10px",
              }}
              size="large"
              onClick={handleSave}
            >
              <SaveOutlined />
              {t("component.save")}
            </Button>
          </Space>

        </Space>

      </Layout>
    </Spin>
  );
};


export default CreateExpenses;
