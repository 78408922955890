import React,{useEffect, useState} from "react";
import {
    Form,
    Typography,
    Space,
    Spin,
    Row,
    Col,
    Popconfirm,
    Table,
    Divider,
    Input,
    message
} from "antd";
import Layout from "antd/lib/layout/layout";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import Label from "../../components/Label";
import IconButton from "../../components/IconButton";
import ShowText from "../../components/ShowText";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSaleInvoiceCredit, deleteSaleInvoiceCredit, getSaleInvoice } from "../../store/actions";
import { successCreateMessage, successDeleteMessage } from "../../utils/messages";
import { getReadableDateDisplay } from "../../utils/convertToHumanReadableTime";
import {useTranslation} from "react-i18next";

const { Title } = Typography;

const CreateSaleCredit = () => {
    const [form] = Form.useForm();
    const params = useParams();
    const invoiceID = params.id; 
    const dispatch = useDispatch();
    const status = useSelector((state)=> state.status);
    const error = useSelector((state)=> state.error);
    const saleInvoice = useSelector((state)=> state.saleInvoice.saleInvoice);
    const [creditTotal, setCreditTotal ] = useState();
    const [payCreditRecords , setPayCreditRecords ] = useState();
    const vouncherCode = saleInvoice?.voucher_code;
    const {t} = useTranslation();

    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        if (status.success) {
            form.resetFields();
            message.success(successCreateMessage);
        }
        return () => status.success;
    }, [form, status.success]);

    useEffect(() => {
        if (status.deleteSuccess) {
            message.success(successDeleteMessage);
        }
        return () => status.deleteSuccess;
    }, [status.deleteSuccess]);

    useEffect(()=>{
        dispatch(getSaleInvoice(invoiceID));
    },[dispatch, invoiceID])

    useEffect(()=>{
        setCreditTotal(saleInvoice?.credit);
        const transformedData = saleInvoice?.credits?.map((record)=> {
            return {
                ...record,
                key: record.id,
            }
        });
        setPayCreditRecords(transformedData);
    },[saleInvoice])

    const onFinish = async (values)=>{
        const data = {
            ...values,
            invoice_id: parseInt(invoiceID),
        }
        await dispatch(createSaleInvoiceCredit(data));
        await dispatch(getSaleInvoice(invoiceID));
    }

    const handleDelete = async (recordID)=>{
        await dispatch(deleteSaleInvoiceCredit(recordID));
        await dispatch(getSaleInvoice(invoiceID));
    }

    const columns = [
        {
            title: t("saleInvoice.date"),
            dataIndex: "created_at",
            render:(created_at)=> getReadableDateDisplay(created_at),
        },
        {
            title: t("saleInvoice.amount"),
            dataIndex: "amount",
        },
        {
            title: t("component.actions"),
            dataIndex: "id",
            render: (id) => (
                <Popconfirm
                    title={t("component.sureDelete")}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {handleDelete(id)}}
                >
                    <IconButton icon={<DeleteOutlined />} bgColor="var(--red-color)"> </IconButton>
                </Popconfirm>

            ),
        },
    ];
   

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }} >
                <Space direction="vertical">
                    <Title level={3} style={{ marginBottom: "35px" }}>
                       {t("saleInvoice.createSaleCredit")}
                    </Title>
                    <Row gutter={[16, 16]} style={{marginBottom:"20px"}}>
                    <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <Space>
                                <Label text= {t("saleInvoice.invoiceCode")} />
                                <ShowText text={vouncherCode} />
                            </Space>

                        </Col>
                        <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <Space>
                                <Label text= {t("saleInvoice.creditAmount")} />
                                <ShowText text={creditTotal} />
                            </Space>

                        </Col>
                    </Row>
                    <Row >
                        <Col span={12} >
                            <Form
                                colon={false}
                                labelCol={{
                                    xl: {
                                        span: 8,
                                    },
                                }}
                                wrapperCol={{
                                    xl: {
                                        span: 24,
                                    },
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                form={form}
                                layout={"vertical"}
                            >
                                <Form.Item
                                    name="amount"
                                    label={t(saleInvoice.amount)}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("saleInvoice.pleaseEnterAmount"),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t("saleInvoice.enterAmount")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />

                                </Form.Item>

                                <Form.Item style={{ textAlign: "right" }}>
                                    <LargeButton icon={<SaveOutlined />} text={t("component.save")} />
                                </Form.Item>
                            </Form>
                        </Col>


                        <Col span={12}>

                        </Col>
                    </Row>
                    <Divider />
                    <Title level={4} style={{ marginBottom: "20px" }}>
                        {t("saleInvoice.payRecord")}
                    </Title>
                    <Table
                        bordered
                        columns={columns}
                        pagination={{ defaultPageSize: 10 }}
                        dataSource={payCreditRecords}
                    />

                </Space>


            </Layout>
        </Spin>
    );
};

export default CreateSaleCredit;
