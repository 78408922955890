import React from "react";
import {
    Typography,
    Space,
    Row,
    Col,
    Table,
    Spin,
    DatePicker,
    Select
} from "antd";
import Layout from "antd/lib/layout/layout";
import Label from "../../components/Label";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { useEffect } from "react";
import { getPurchaseFocs, getSaleFocs } from "../../store/actions/foc";
import { getReadableDateDisplay } from "../../utils/convertToHumanReadableTime";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ShowFOCs = () => {
    const [IsPurchaseFocs, setIsPurchaseFocs] = useState(true);
    const status = useSelector((state) => state.status);
    const purchaseFocs = useSelector((state) => state.foc.purchaseFocs);
    const saleFocs = useSelector((state) => state.foc.saleFocs);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getPurchaseFocs(searchParams));
        dispatch(getSaleFocs(searchParams));
    }, [dispatch, searchParams]);

    const onChange = (value) => {
        if (value === "purchase_focs") {
            setIsPurchaseFocs(true);
        }
        else {
            setIsPurchaseFocs(false);
        }
    }
    //for excel export
    const fileName = IsPurchaseFocs ? "PurchaseFOCs" : "SaleFOCs";
    const transformedPurchaseFocs = purchaseFocs?.map((purchaseFoc) => {
        return {
            Date: getReadableDateDisplay(purchaseFoc?.created_at),
            Item: purchaseFoc?.product.name,
            Quantity: purchaseFoc?.quantity,
        };
    });

    const transformedSaleFocs = saleFocs?.map((saleFoc) => {
        return {
            Date: getReadableDateDisplay(saleFoc?.created_at),
            Item: saleFoc?.stock?.product?.name,
            Quantity: saleFoc?.quantity,
        };
    });

    const transformedData = IsPurchaseFocs ? transformedPurchaseFocs : transformedSaleFocs;

    const purchaseFocColumns = [
        {
            title: t("foc.date"),
            dataIndex: "created_at",
            render: (created_at) => getReadableDateDisplay(created_at)
        },
        {
            title: t("foc.itemName"),
            dataIndex: "product",
            render: (product) => product?.name
        },
        {
            title: t("foc.qty"),
            dataIndex: "quantity",
            sorter: (a, b) => a.quantity - b.quantity,
        },
    ];
    const saleFocColumns = [
        {
            title: t("foc.date"),
            dataIndex: "created_at",
            render: (created_at) => getReadableDateDisplay(created_at)
        },
        {
            title: t("foc.itemName"),
            dataIndex: "stock",
            render: (stock) => stock?.product?.name
        },
        {
            title: t("foc.qty"),
            dataIndex: "quantity",
            sorter: (a, b) => a.quantity - b.quantity,
        },
    ];

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }}>
                <Space direction="vertical" size="middle">
                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 21 }} md={{ span: 18 }}>
                            <Title level={3}>{t("foc.focList")}</Title>
                        </Col>
                        <Col xl={{ span: 3 }} md={{ span: 6 }}>
                            <ExportToExcel apiData={transformedData} fileName={fileName} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <RangePicker
                                onChange={(val) => {
                                    if (val === null) {
                                        navigate(`/admin/show-focs`)
                                    }
                                    else {
                                        navigate(`/admin/show-focs?start_date=${dayjs(
                                            val[0]
                                        ).format("YYYY-MM-DD")}&end_date=${dayjs(val[1]).format(
                                            "YYYY-MM-DD"
                                        )}`)
                                    }
                                }}
                            />
                        </Col>
                        <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <Label text={t("foc.type")} />
                            <Select
                                showSearch
                                placeholder={t("foc.selectType")}
                                optionFilterProp="children"
                                allowClear={true}
                                size="medium"
                                style={{ borderRadius: "10px" }}
                                onChange={onChange}
                            >

                                <Option value="purchase_focs" key="1">
                                    {t("foc.purchaseFocList")}
                                </Option>
                                <Option value="sale_focs" key="2">
                                    {t("foc.saleFocList")}
                                </Option>

                            </Select>
                        </Col>
                        <Col></Col>
                    </Row>

                    {IsPurchaseFocs &&
                        <Space direction="vertical" style={{ width: "100%", justifyContent: "right", marginTop: "20px" }}>
                            <Title level={4}> {t("foc.purchaseFocList")} </Title>
                            <Table
                                bordered
                                columns={purchaseFocColumns}
                                pagination={{ position: ["none", "none"] }}
                                dataSource={purchaseFocs}
                            />
                        </Space>

                    }
                    {!IsPurchaseFocs &&
                        <Space direction="vertical" style={{ width: "100%", justifyContent: "right", marginTop: "20px" }}>
                            <Title level={4}> {t("foc.saleFocList")} </Title>
                            <Table
                                bordered
                                columns={saleFocColumns}
                                pagination={{ position: ["none", "none"] }}
                                dataSource={saleFocs}
                            />
                        </Space>

                    }
                </Space>
            </Layout>
        </Spin>
    );
};

export default ShowFOCs;
