import { DELETE_SALE_INVOICE, SET_SALE_INVOICE, SET_SALE_INVOICES } from "../type";

  const initialState = {
    saleInvoices: [],
    saleInvoice: {},
  };
  

  const saleInvoice = (state = initialState, action) => {
    switch (action.type) {
      case SET_SALE_INVOICE:
        return {
          ...state,
          saleInvoice: action.payload,
        };
      case SET_SALE_INVOICES:
        return {
          ...state,
          saleInvoices: action.payload,
        };
      case DELETE_SALE_INVOICE:
        return {
          ...state,
          saleInvoices: state.saleInvoices.filter((saleInvoice) => saleInvoice.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default saleInvoice;
  