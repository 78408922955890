import React, { useEffect } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Table,
  Spin,
  Popconfirm,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import { PlusSquareOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import MediumButton from "../../components/MediumButton";
import IconButton from "../../components/IconButton";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteUsedItem, getUsedItems } from "../../store/actions";
import { getReadableDateDisplay } from "../../utils/convertToHumanReadableTime";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const ShowUsedItems = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usedItems = useSelector((state) => state.usedItem.usedItems);
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getUsedItems());
  }, [dispatch])

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  const handleDelete = async (id) => {
    await dispatch(deleteUsedItem(id));
  }

  const columns = [
    {
      title: t("usedItem.date"),
      dataIndex: "created_at",
      render: (created_at) => getReadableDateDisplay(created_at),
    },
    {
      title: t("usedItem.itemCode"),
      dataIndex: "stock",
      render: (stock) => stock.product.code,
    },
    {
      title: t("usedItem.itemName"),
      dataIndex: "stock",
      render: (stock) => stock.product.name,
    },
    {
      title: t("usedItem.qty"),
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: t("component.actions"),
      dataIndex: "id",
      render: (id) => (
        <Space direction="horizontal">
          <IconButton icon={<EditOutlined />} onClick={() => { navigate(`/admin/edit-used-items/${id}`) }} bgColor="var(--green-color)"> </IconButton>
          <Popconfirm
            title={t("component.sureDelete")}
            okText="Yes"
            cancelText="No"
            onConfirm={() => { handleDelete(id) }}
          >
            <IconButton icon={<DeleteOutlined />} onClick={() => { }} bgColor="var(--red-color)"> </IconButton>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 21 }} md={{ span: 18 }}>
              <Title level={3}>{t("usedItem.usedItemList")}</Title>
            </Col>

            <Col xl={{ span: 3 }} md={{ span: 6 }}>
              <MediumButton icon={<PlusSquareOutlined />} text={t("component.addNew")} bgColor="var(--primary-color)" onClick={() => navigate("/admin/create-used-item")} />
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={usedItems}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

export default ShowUsedItems;
