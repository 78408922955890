import React, { useEffect } from "react";
import {
    Typography,
    Space,
    Row,
    Col,
    Table,
    Spin,
    Select,
    message
} from "antd";
import Layout from "antd/lib/layout/layout";
import Label from "../../components/Label";
import { useSelector, useDispatch } from "react-redux";
import { getItems, getOutOfStocks } from "../../store/actions";
import { useState } from "react";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from "react-i18next";
const { Title } = Typography;
const { Option } = Select;

const ShowOutOfStocks = () => {
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const items = useSelector((state) => state.item.items);
    const outOfStocks = useSelector((state) => state.stock.outOfStocks);
    const dispatch = useDispatch();

    const [filteredOutOfStocks, setFilteredOutOfStocks] = useState([]);
    const { t } = useTranslation();


    useEffect(() => {
        error.message !== null && message.error(error.message);
        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        dispatch(getItems());
        dispatch(getOutOfStocks());
    }, [dispatch]);

    useEffect(() => {
        setFilteredOutOfStocks(outOfStocks);
    }, [outOfStocks]);

    const onChange = (value) => {
        if (value === undefined) {
            setFilteredOutOfStocks(outOfStocks);
        }
        else {
            const filteredResult = outOfStocks.filter((outOfStock) => outOfStock.product.name === value);
            setFilteredOutOfStocks(filteredResult);
        }
    }

    //for excel export
    const fileName = "OutOfStocks";
    const transformedData = filteredOutOfStocks.map((outOfStock) => {
        return {
            Code: outOfStock.product.code,
            Name: outOfStock.product.name,
        };
    });

    const columns = [
        {
            title: t("item.image"),
            dataIndex: "product",
            render: (product) => (
                <img src={product.image} alt="ပစ္စည်းပုံ" width={100} height={100} />
            )
        },
        {
            title: t("item.code"),
            dataIndex: "product",
            render: (product) => product.code,
        },
        {
            title: t("item.name"),
            dataIndex: "product",
            render: (product) => product.name,
        }
    ];

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }}>
                <Space direction="vertical" size="middle">
                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 21 }} md={{ span: 18 }}>
                            <Title level={3}>{t("item.outOfStockList")}</Title>
                        </Col>
                        <Col xl={{ span: 3 }} md={{ span: 6 }}>
                            <ExportToExcel apiData={transformedData} fileName={fileName} />
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <Label text={t("item.itemName")} />
                            <Select
                                showSearch
                                placeholder={t("item.selectItemName")}
                                optionFilterProp="children"
                                allowClear={true}
                                size="medium"
                                style={{ borderRadius: "10px" }}
                                onChange={onChange}
                            >
                                {items.map((item) => (
                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                ))}

                            </Select>
                        </Col>
                    </Row>
                    <Table
                        bordered
                        columns={columns}
                        pagination={{ defaultPageSize: 10 }}
                        dataSource={filteredOutOfStocks}
                    />
                </Space>
            </Layout>
        </Spin>
    );
};

export default ShowOutOfStocks;
