import React from "react";
import {
    Typography,
    Space,
    Row,
    Col,
    Table,
    Spin,
    DatePicker,
    Select
} from "antd";
import Layout from "antd/lib/layout/layout";
import Label from "../../components/Label";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getMerchants, getPurchaseReports } from "../../store/actions";
import { useSearchParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useState } from "react";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ShowPurchaseRecords = () => {

    const status = useSelector((state) => state.status);
    const purchaseReports = useSelector((state) => state.purchase.purchaseReports);
    const merchants = useSelector((state) => state.merchant.merchants);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [filteredResult, setFilteredResult] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = () => {
            dispatch(getPurchaseReports(searchParams));
            dispatch(getMerchants());
        }
        fetchData();
        return () => fetchData();
    }, [dispatch, searchParams]);


    useEffect(() => {
        setFilteredResult(purchaseReports);
    }, [dispatch, purchaseReports]);

    const onChangeMerchant = (value) => {
        if (value === undefined) {
            setFilteredResult(purchaseReports);
        } else {
            const result = purchaseReports?.filter((report) => report.merchant.id === value);
            setFilteredResult(result);
        }
    }

    //for excel export
    const fileName = "PurchaseRecords";
    const transformedData = filteredResult?.map((record) => {
        return {
            CompanyName: record.merchant.company_name,
            Total: record.whole_total,
            PaidAmount: record.paid,
            CreditAmount: record.credit,
            Type: record.type,
        };
    });

    const columns = [
        {
            title: t("purchaseRecord.companyName"),
            dataIndex: 'merchant',
            render: (merchant) => merchant.company_name
        },
        {
            title: t("purchaseRecord.totalAmount"),
            dataIndex: 'whole_total',
            sorter: {
                compare: (a, b) => a.whole_total - b.whole_total,
                multiple: 3,
            },

        },
        {
            title: t("purchaseRecord.paidAmount"),
            dataIndex: 'paid',
            sorter: {
                compare: (a, b) => a.paid - b.paid,
                multiple: 2,
            },

        },
        {
            title: t("purchaseRecord.creditAmount"),
            dataIndex: 'credit',
            sorter: {
                compare: (a, b) => a.credit - b.credit,
                multiple: 1,
            },

        },
        {
            title: t("purchaseRecord.type"),
            dataIndex: 'type',

        },
    ];

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }}>
                <Space direction="vertical" size="middle">
                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 21 }} md={{ span: 18 }}>
                            <Title level={3}>{t("purchaseRecord.purchaseRecord")}</Title>
                        </Col>
                        <Col xl={{ span: 3 }} md={{ span: 6 }}>
                            <ExportToExcel apiData={transformedData} fileName={fileName} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <RangePicker
                                onChange={(val) => {
                                    if (val === null) {
                                        navigate(`/admin/show-purchase-records`)
                                    }
                                    else {
                                        navigate(`/admin/show-purchase-records?start_date=${dayjs(
                                            val[0]
                                        ).format("YYYY-MM-DD")}&end_date=${dayjs(val[1]).format(
                                            "YYYY-MM-DD"
                                        )}`)
                                    }
                                }}
                            />
                        </Col>
                        <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <Label text={t("purchaseRecord.companyName")} />
                            <Select
                                showSearch
                                placeholder={t("purchaseRecord.selectCompanyName")}
                                optionFilterProp="children"
                                allowClear={true}
                                size="medium"
                                style={{ borderRadius: "10px" }}
                                onChange={onChangeMerchant}
                            >
                                {merchants.map((merchant) => (
                                    <Option key={merchant.id} value={merchant.id}>{merchant.company_name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Table
                        bordered
                        columns={columns}
                        pagination={{ defaultPageSize: 10 }}
                        dataSource={filteredResult}
                    />
                </Space>
            </Layout>
        </Spin>
    );
};

export default ShowPurchaseRecords;
