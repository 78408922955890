import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Admin from './layouts/Admin';
import CreateSaleInvoice from './pages/sale_invoice/CreateSaleInvoice';
import Login from './pages/Login';
import PrivateRoute from './routers/PrivateRoute';
import AuthRoute from './routers/AuthRoute';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin/*" element={<AuthRoute> <Admin /></AuthRoute>} />
        <Route
          path="/admin/create-sale-invoice"
          element={
            <CreateSaleInvoice />
          }
        />
        <Route
          path="/auth/login"
          element={
            <PrivateRoute>
              <Login />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/auth/login" />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
