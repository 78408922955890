import { DELETE_CUSTOMER_EXCHANGE, SET_CUSTOMER_EXCHANGE, SET_CUSTOMER_EXCHANGES } from "../type";

  const initialState = {
    customerExchanges: [],
    customerExchange: {},
  };
  
  const customerExchange = (state = initialState, action) => {
    switch (action.type) {
      case SET_CUSTOMER_EXCHANGE:
        return {
          ...state,
          customerExchange: action.payload,
        };
      case SET_CUSTOMER_EXCHANGES:
        return {
          ...state,
          customerExchanges: action.payload,
        };
      case DELETE_CUSTOMER_EXCHANGE:
        return {
          ...state,
          customerExchanges: state.customerExchanges.filter((exchange) => exchange.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default customerExchange;
  