import React, { useEffect, useState } from "react";
import {
    Form,
    Typography,
    Space,
    Spin,
    Row,
    Col,
    Input,
    message,
    DatePicker,
    InputNumber,
    Select,
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
    SaveOutlined,
} from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import InputUpload from "../../components/InputUpload";
import { createStaff } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { owner, manager, saleman, cashier } from "../../utils/position";

const { Title, Text } = Typography;
const { Option } = Select;

const CreateStaff = () => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [dob, setDob] = useState(null);
    const [start_job, setStartJob] = useState(null);
    const [end_job, setEndJob] = useState(null);

    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const dispatch = useDispatch();
    const { t } = useTranslation();


    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        if (status.success) {
            form.resetFields();
            message.success(t("message.successCreateMessage"));
        }

        return () => status.success;
    }, [form, status.success, t]);

    const onFinish = async (values) => {
        const formData = new FormData();
        if (fileList.length === 0) {
            message.error("Image field is required");
            return;
        }
        formData.append(`image`, fileList[0].originFileObj);
        formData.append("code", values.code);
        formData.append("name", values.name);
        formData.append("dob", dob);
        formData.append("nrc", values.nrc);
        formData.append("start_job", start_job);
        if (end_job) {
            formData.append("end_job", end_job);
        }
        formData.append("phone", values.phone);
        formData.append("address", values.address);
        formData.append("position", values.position);
        formData.append("salary", values.salary);

        await dispatch(createStaff(formData));
    };


    const onChangeOfDob = (_, dateString) => {
        setDob(dateString);
    };
    const onChangeofStartJob = (_, dateString) => {
        setStartJob(dateString);
    };
    const onChangeOfEndJob = (_, dateString) => {
        setEndJob(dateString)
    };

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }} >
                <Space direction="vertical">
                    <Title level={3}>
                        {t("staff.createStaff")}
                    </Title>
                    <Row >

                        <Col span={12} >
                            <Form
                                colon={false}
                                labelCol={{
                                    xl: {
                                        span: 8,
                                    },
                                }}
                                wrapperCol={{
                                    xl: {
                                        span: 24,
                                    },
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                form={form}
                                layout={"vertical"}
                            >
                                <Space
                                    direction="vertical"
                                    style={{
                                        width: "100%",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <InputUpload fileList={fileList} setFileList={setFileList} />
                                    <Text type="secondary">  {t("staff.pleaseInsertImage")}</Text>
                                </Space>
                                <Form.Item
                                    name="code"
                                    label={t("staff.code")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.enterCode"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("staff.enterCode")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="name"
                                    label={t("staff.name")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterName"),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t("staff.enterName")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="dob"
                                    label={t("staff.dob")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterDob"),
                                        },
                                    ]}
                                >

                                    <DatePicker onChange={onChangeOfDob} style={{ width: "100%", borderRadius: "10px" }} size="large" />
                                </Form.Item>
                                <Form.Item
                                    name="nrc"
                                    label={t("staff.nrc")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterNrc"),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t("staff.enterNrc")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="start_job"
                                    label={t("staff.startDate")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterStartDate"),
                                        },
                                    ]}
                                >

                                    <DatePicker onChange={onChangeofStartJob} style={{ width: "100%", borderRadius: "10px" }} size="large" />
                                </Form.Item>
                                <Form.Item
                                    name="end_job"
                                    label={t("staff.endDate")}
                                >

                                    <DatePicker onChange={onChangeOfEndJob} style={{ width: "100%", borderRadius: "10px" }} size="large" />
                                </Form.Item>
                                <Form.Item
                                    name="phone"
                                    label={t("staff.phone")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterPhone"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("staff.enterPhone")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="address"
                                    label={t("staff.address")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterAddress"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("staff.enterAddress")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="position"
                                    label={t("staff.position")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterPosition"),
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder={t("staff.enterPosition")}
                                        optionFilterProp="children"
                                        allowClear={true}
                                        size="large"
                                        style={{ borderRadius: "10px" }}
                                    // onChange={onChange}
                                    >
                                        <Option key={owner} value={owner}>
                                            {t("account.owner")}
                                        </Option>
                                        <Option key={manager} value={manager}>
                                            {t("account.manager")}
                                        </Option>
                                        <Option key={cashier} value={cashier}>
                                            {t("account.cashier")}
                                        </Option>
                                        <Option key={saleman} value={saleman}>
                                            {t("account.saleman")}
                                        </Option>

                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="salary"
                                    label={t("staff.salary")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterSalary"),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        placeholder={t("staff.enterSalary")}
                                        style={{ borderRadius: "10px", width: "100%" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item style={{ textAlign: "right" }}>
                                    <LargeButton icon={<SaveOutlined />} bgColor="var(--primary-color)" text={t("component.save")} />
                                </Form.Item>
                            </Form>
                        </Col>

                        <Col span={12}>
                        </Col>
                    </Row>

                </Space>

            </Layout>
        </Spin>
    );
};


export default CreateStaff;
