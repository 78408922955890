import { call } from "../../services/api";
import { serverErrorMessage } from "../../utils/messages";
import { ADD_ERROR, REMOVE_ERROR, SET_DAILY_REPORTS, SET_ITEM_GROSS_PROFIT, SET_LOADING, SET_REPORT } from "../type";

export const getReport = (searchParams) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("get", `report/sale?${searchParams}`);
      dispatch({
        type: SET_REPORT,
        payload: response,
      });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: serverErrorMessage,
        });
      }
    }
    dispatch({ type: SET_LOADING });
  };
};


export const getDailyReports = ({ start_date, end_date }) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("get", `daily?start_date=${start_date}&end_date=${end_date}`);
      const result = response.data;

      const transformResult = result.map((data, index) => {
        return {
          ...data,
          key: index,
        };
      });
      dispatch({
        type: SET_DAILY_REPORTS,
        payload: transformResult,
      });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: serverErrorMessage,
        });
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getItemGrossProfit = ({ start_date, end_date, type }) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("get", `products-gross-profit?start_date=${start_date}&end_date=${end_date}&type=${type}`);
      const result = response.data;

      const transformResult = result.map((data, index) => {
        return {
          ...data,
          key: index,
        };
      });
      dispatch({
        type: SET_ITEM_GROSS_PROFIT,
        payload: transformResult,
      });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: serverErrorMessage,
        });
      }
    }
    dispatch({ type: SET_LOADING });
  };
}

