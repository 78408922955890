import React from "react";
import {
    Form,
    Typography,
    Space,
    Table,
    Spin,
    Row,
    Col,
    Divider,
    Checkbox,
    Card,
    Input,
    Select

} from "antd";
import Layout from "antd/lib/layout/layout";
import {
    SaveOutlined,
    PlusSquareOutlined,
    DeleteOutlined
} from "@ant-design/icons";

import MediumButton from "../../components/MediumButton";
import LargeButton from "../../components/LargeButton";
import Label from "../../components/Label";
import ShowText from "../../components/ShowText";
import IconButton from "../../components/IconButton";
import {useTranslation} from "react-i18next";

const { Title } = Typography;
const { Option } = Select;
const EditCustomerExchange = () => {
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const purchases = [
        {

        },
        {

        },
    ];
    const columns = [
        {
            title: t("exchange.itemName"),
            dataIndex: "item_name",
        },
        {
            title: t("exchange.qty"),
            dataIndex: "quantity",
        },
        {
            title: t("exchange.subtotal"),
            dataIndex: "amount",
        },
        {
            title: t("component.actions"),
            dataIndex: "id",
            render: (id) => (
                <Space direction="horizontal">
                    <IconButton icon={<DeleteOutlined />} onClick={() => { }} bgColor="var(--red-color)"> </IconButton>
                </Space>
            ),
        }

    ];
    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };
    return (
        <Spin spinning={false}>
            <Layout style={{ margin: "20px 40px" }} >
                <Space direction="vertical" >
                    <Title level={3} style={{ marginBottom: "35px" }}>
                    {t("exchange.editCustomerExchange")}
                    </Title>

                    {/* COD , Credit checkbox card */}
                    <Row style={{ marginBottom: "20px" }} >
                        <Col xl={{ span: 4 }} md={{ span: 10 }} align="center">
                            <Card
                                align="center"
                                style={{ borderRadius: 8, backgroundColor: "var(--white-color)" }}
                                hoverable
                            >
                                <Space split={<Divider />}>
                                <Checkbox onChange={onChange} >{t("exchange.give")}</Checkbox>
                                    <Checkbox onChange={onChange}>{t("exchange.take")}</Checkbox>
                                </Space>
                            </Card>
                        </Col>
                        <Col>
                        </Col>
                    </Row>

                    {/* Form data */}
                    <Row >
                        <Col xl={{ span: 12 }} >
                        <Form
                                colon={false}
                                labelCol={{
                                    xl: {
                                        span: 8,
                                    },
                                    md: {
                                        span: 12,
                                    }
                                }}
                                wrapperCol={{
                                    xl: {
                                        span: 24,
                                    },
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={() => { }}
                                form={form}
                                layout={"vertical"}
                            >

                                <Form.Item
                                    name="item_code"
                                    label={t("exchange.itemNameCode")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("exchange.pleaseSelectItem"),
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder={t("exchange.selectItem")}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        allowClear={true}
                                        size="large"
                                        style={{ borderRadius: "10px" }}
                                    >

                                        <Option value="owner" key="1">
                                            Owner
                                        </Option>
                                        <Option value="manager" key="2">
                                            Manager
                                        </Option>
                                        <Option value="cashier" key="3">
                                            Cashier
                                        </Option>

                                    </Select>

                                </Form.Item>
                                <Form.Item
                                    name="quantity"
                                    label={t("exchange.qty")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("exchange.pleaseEnterQty"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("exchange.enterQty")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />


                                </Form.Item>

                                <Form.Item style={{ textAlign: "right" }}>
                                    <MediumButton icon={<PlusSquareOutlined />} onClick={() => { }} bgColor="var(--primary-color)" text={t("component.add")} />
                                </Form.Item>
                            </Form>
                        </Col>

                        <Col>
                        </Col>
                    </Row>
                    <Row >
                        <Col xl={{ span: 12 }} md={{ span: 24 }} style={{ padding: "10px" }}>
                            <Card style={{ borderRadius: "8px" }}>
                                <Space direction="vertical" style={{ width: "100%" }}>
                                    <Title level={5} align="center" style={{ margin: "20px" }}>{t("exchange.itemToCustomer")}</Title>
                                    <Table
                                        bordered
                                        columns={columns}
                                        pagination={{ position: ["none", "none"] }}
                                        dataSource={purchases}
                                    />
                                    <Space
                                        direction="horizontal"
                                        style={{ width: "93%", justifyContent: "end", margin: "20px" }}
                                    >
                                        <Label text={t("exchange.total")} />
                                        <ShowText text="123900" />
                                    </Space>
                                </Space>
                            </Card>
                        </Col>
                        <Col xl={{ span: 12 }} md={{ span: 24 }} style={{ padding: "10px" }}>
                            <Card style={{ borderRadius: "8px" }}>
                                <Space direction="vertical" style={{ width: "100%" }}>
                                    <Title level={5} align="center" style={{ margin: "20px" }}>{t("exchange.itemFromCustomer")}  </Title>
                                    <Table
                                        bordered
                                        columns={columns}
                                        pagination={{ position: ["none", "none"] }}
                                        dataSource={purchases}
                                    />
                                    <Space
                                        direction="horizontal"
                                        style={{ width: "93%", justifyContent: "end", margin: "20px" }}
                                    >
                                        <Label text={t("exchange.total")} />
                                        <ShowText text="123900" />
                                    </Space>
                                </Space>
                            </Card>
                        </Col>
                    </Row>


                    {/* Total status row */}
                    <Row align="end">
                        <Col></Col>
                        <Col xl={{ span: 7 }}>
                            <Card style={{ borderRadius: "10px", padding: "20px" }}>
                                <Space
                                    direction="horizontal"
                                    style={{ width: "100%", justifyContent: "center" }}
                                >
                                    <Label text={t("exchange.difference")} />
                                    <ShowText text="123900" />
                                </Space>
                            </Card>

                        </Col>
                    </Row>

                    <Space
                        direction="horizontal"
                        style={{ width: "100%", justifyContent: "right" }}
                    >
                        <LargeButton icon={<SaveOutlined />} text={t("component.save")} />
                    </Space>

                </Space>

            </Layout>
        </Spin>
    );
};


export default EditCustomerExchange;
