import { DELETE_USED_ITEM, SET_USED_ITEM, SET_USED_ITEMS } from "../type";

const initialState = {
    usedItems: [],
    usedItem: {},
};

const usedItem = (state = initialState, action) => {
    switch (action.type) {
        case SET_USED_ITEM:
            return {
                ...state,
                usedItem: action.payload,
            };
        case SET_USED_ITEMS:
            return {
                ...state,
                usedItems: action.payload,
            };
        case DELETE_USED_ITEM:
            return {
                ...state,
                usedItems: state.usedItems.filter((usedItem) => usedItem.id !== action.payload),
            };
        default:
            return state;
    }
};

export default usedItem;
