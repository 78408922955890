import { call } from "../../services/api";
import { serverErrorMessage } from "../../utils/messages";
import { ADD_ERROR, DELETE_CATEGORY, REMOVE_ERROR, SET_CATEGORIES, SET_CATEGORY, SET_LOADING, SET_SUCCESS } from "../type";

export const getCategories = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("get", "categories");
      const result = response.data;

      const transformResult = result.map((data) => {
        return {
          ...data,
          key: data.id,
        };
      });
      dispatch({
        type: SET_CATEGORIES,
        payload: transformResult,
      });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: serverErrorMessage,
        });
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const createCategory = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    dispatch({ type: SET_LOADING });
    try {
      await call("post", "categories", data);

      dispatch({ type: SET_SUCCESS, payload: true });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: serverErrorMessage,
        });
      }
    }
    setTimeout(() => {
      dispatch({ type: SET_SUCCESS, payload: false });
    }, 1);
    dispatch({ type: SET_LOADING });
  };
};


export const getCategory = (id) => {
  return async (dispatch) => {
      dispatch({ type: SET_LOADING });
      try {
          const response = await call("get", `categories/${id}`);
          const result = response.data;

          dispatch({
              type: SET_CATEGORY,
              payload: result,
          });
          dispatch({
              type: REMOVE_ERROR,
          });
      } catch (error) {
          const { status, data } = error.response;

          if (status === 401) {
              localStorage.removeItem("jwtToken");
              dispatch({
                  type: ADD_ERROR,
                  payload: data.message,
              });
          }

          if (status === 500) {
              dispatch({
                  type: ADD_ERROR,
                  payload: serverErrorMessage,
              });
          }
      }
      dispatch({ type: SET_LOADING });
  };
};

export const editCategory = (id, data) => {
  return async (dispatch) => {
      dispatch({ type: SET_SUCCESS, payload: false });
      dispatch({ type: SET_LOADING });
      try {
          await call("post", `categories/${id}?_method=PUT`, data);

          dispatch({ type: SET_SUCCESS, payload: true });
          dispatch({
              type: REMOVE_ERROR,
          });
      } catch (error) {
          const { status, data } = error.response;

          if (status === 401) {
              localStorage.removeItem("jwtToken");
              dispatch({
                  type: ADD_ERROR,
                  payload: data.message,
              });
          }

          if (status === 500) {
              dispatch({
                  type: ADD_ERROR,
                  payload: serverErrorMessage,
              });
          }
      }
      setTimeout(() => {
          dispatch({ type: SET_SUCCESS, payload: false });
      }, 1);
      dispatch({ type: SET_LOADING });
  };
};

export const deleteCategory = (id) => {
  return async (dispatch) => {
      dispatch({ type: SET_SUCCESS, payload: false });
      dispatch({ type: SET_LOADING });
      try {
          await call("delete", `categories/${id}`);

          dispatch({ type: SET_SUCCESS, payload: true });
          dispatch({ type: DELETE_CATEGORY, payload: id });
          dispatch({
              type: REMOVE_ERROR,
          });
      } catch (error) {
          const { status, data } = error.response;

          if (status === 401) {
              localStorage.removeItem("jwtToken");
              dispatch({
                  type: ADD_ERROR,
                  payload: data.message,
              });
          }

          if (status === 500) {
              dispatch({
                  type: ADD_ERROR,
                  payload: serverErrorMessage,
              });
          }
      }
      setTimeout(() => {
          dispatch({ type: SET_SUCCESS, payload: false });
      }, 1);
      dispatch({ type: SET_LOADING });
  };
};
