import React, { useEffect } from "react";
import {
    Form,
    Typography,
    Space,
    Table,
    Spin,
    Row,
    Col,
    Select,
    InputNumber,
    message,
    Button
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
    SaveOutlined,
    PlusSquareOutlined,
    DeleteOutlined
} from "@ant-design/icons";
import IconButton from "../../components/IconButton";
import { useSelector, useDispatch } from "react-redux";
import { createStocks, getItems } from "../../store/actions";
import { useTranslation } from "react-i18next";
import { useState } from "react";


const { Title } = Typography;
const { Option } = Select;
const CreateStock = () => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const items = useSelector((state) => state.item.items);
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const { t } = useTranslation();
    const [stocks, setStocks] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getItems());
        };
        fetchData();
        return () => {
            fetchData();
        };
    }, [dispatch]);

    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        setStocks([]);
        if (status.success) {
            message.success(t("message.successCreateMessage"));
        }
        return () => status.success;
    }, [status.success, t]);

    const onFinish = (values) => {
        const selectedItem = items.filter((item) => item.id === values.product_id);
        setStocks([
            ...stocks,
            {
                ...values,
                item_name: selectedItem[0].name,
                key: stocks.length + 1,
            }
        ])
        form.resetFields();
    }

    const handleDelete = (record) => {
        const filteredStocks = stocks.filter((stock) => stock !== record);
        const transformedStocks = filteredStocks.map((stock, index) => {
            return {
                ...stock,
                key: index + 1,

            }
        })
        setStocks(transformedStocks);
    }

    const handleSave = async () => {
        await dispatch(createStocks(stocks));
    }

    const columns = [

        {
            title: t("item.name"),
            dataIndex: "item_name",
        },

        {
            title: t("stock.qty"),
            dataIndex: "quantity",
        },
        {
            title: t("component.actions"),
            dataIndex: "action",
            render: (_, record) => (
                <IconButton icon={<DeleteOutlined />} bgColor="var(--red-color)" onClick={() => handleDelete(record)} />
            ),
        },
    ];

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }} >
                <Space direction="vertical">
                    <Title level={3}>
                        {t("stock.createStock")}
                    </Title>
                    <Row >

                        <Col span={12}>
                            <Form
                                colon={false}
                                labelCol={{
                                    xl: {
                                        span: 8,
                                    },
                                }}
                                wrapperCol={{
                                    xl: {
                                        span: 24,
                                    },
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                form={form}
                                layout={"vertical"}
                            >

                                <Form.Item
                                    name="product_id"
                                    label={t("item.name")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("item.pleaseSelectItem"),
                                        },
                                    ]}
                                >

                                    <Select
                                        showSearch
                                        placeholder={t("item.selectItem")}
                                        optionFilterProp="children"
                                        allowClear={true}
                                        size="large"
                                        style={{ borderRadius: "10px" }}
                                    >

                                        {items.map((item) => (
                                            <Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="quantity"
                                    label={t("stock.qty")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("stock.pleaseEnterQty"),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        placeholder={t("stock.enterQty")}
                                        style={{ borderRadius: "10px", width: "100%" }}
                                        size="large"
                                    />
                                </Form.Item>

                                <Form.Item style={{ textAlign: "right" }}>
                                    <Button
                                        style={{
                                            backgroundColor: "var(--primary-color)",
                                            color: "var(--white-color)",
                                            borderRadius: "10px",
                                        }}
                                        size="medium"
                                        htmlType="submit"
                                    >
                                        <PlusSquareOutlined />
                                        {t("component.add")}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>

                        <Col span={12}>
                        </Col>
                    </Row>
                    <Table
                        bordered
                        columns={columns}
                        pagination={{ position: ["none", "none"] }}
                        dataSource={stocks}

                    />
                    <Space
                        direction="horizontal"
                        style={{ width: "100%", justifyContent: "right" }}
                    >
                        <Button
                            style={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white-color)",
                                borderRadius: "10px",
                            }}
                            size="large"
                            onClick={handleSave}
                        >
                            <SaveOutlined />
                            {t("component.save")}
                        </Button>
                    </Space>

                </Space>

            </Layout>
        </Spin>
    );
};


export default CreateStock;
