import React, { useEffect } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Table,
  Spin,
  Popconfirm,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import { PlusSquareOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import MediumButton from "../../components/MediumButton";
import IconButton from "../../components/IconButton";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteCustomer, getCustomers } from "../../store/actions/customer";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from "react-i18next";
import { cashier, manager, owner } from "../../utils/position";

const { Title } = Typography;

const ShowCustomers = () => {
  const customers = useSelector((state) => state.customer.customers);
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const user = useSelector((state) => state.auth.user);
  const isManager = user.position === manager;
  const isCashier = user.position === cashier;
  const isOwner = user.position === owner;



  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    error.message !== null && message.error(error.message);
    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(t("message.successDeleteMessage"));
    }
    return () => status.success;
  }, [status.success, t]);


  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  const navigate = useNavigate();

  const handleDelete = async (id) => {
    await dispatch(deleteCustomer(id));
  }

  //for excel export
  const fileName = "Customers";
  const transformedData = customers.map((customer) => {
    return {
      Code: customer.code,
      Name: customer.name,
      Phone: customer.phone,
      Address: customer.address,
    };
  });
  const columns = [
    {
      title: t("customer.code"),
      dataIndex: "code",
    },
    {
      title: t("customer.name"),
      dataIndex: "name",
    },
    {
      title: t("customer.phone"),
      dataIndex: "phone",
    },
    {
      title: t("customer.address"),
      dataIndex: "address",
    },
    {
      title: t("component.actions"),
      dataIndex: "id",
      render: (id) => (
        <Space direction="horizontal">
          <IconButton icon={<EditOutlined />} onClick={() => { navigate(`/admin/edit-customers/${id}`) }} bgColor="var(--green-color)"> </IconButton>
          <Popconfirm
            title={t("component.sureDelete")}
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDelete(id)}
          >
            {
              (isManager || isCashier || isOwner) &&
              <IconButton icon={<DeleteOutlined />} onClick={() => { }} bgColor="var(--red-color)"> </IconButton>
            }
          </Popconfirm>
        </Space>
      ),
    },
  ];


  return (
    <Spin spinning={false}>
      <Layout style={{ margin: "20px 40px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 18 }} md={{ span: 12 }}>
              <Title level={3}> {t("customer.customerList")}</Title>
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 6 }}>
              <MediumButton icon={<PlusSquareOutlined />} text={t("component.addNew")} bgColor="var(--primary-color)" onClick={() => { navigate("/admin/create-customer") }} />
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 6 }}>
              <ExportToExcel apiData={transformedData} fileName={fileName} />
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={customers}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

export default ShowCustomers;
