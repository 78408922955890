import React,{useEffect} from "react";
import { Form, Input, Typography, Space, Spin, Row, Col, message } from "antd";
import Layout from "antd/lib/layout/layout";
import { SaveOutlined } from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import { useSelector, useDispatch } from "react-redux";
import { createMerchant } from "../../store/actions";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { TextArea } = Input;
const CreateMerchants = () => {
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [form] = Form.useForm();
    const onFinish = async (values) => {
        if(values.remark === undefined){
           values = {
            ...values,
            remark : ""
           }
        }
        await dispatch(createMerchant(values));
    }
    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        if (status.success) {
            form.resetFields();
            message.success(t("message.successCreateMessage"));
        }

        return () => status.success;
    }, [form, status.success,t]);

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }} >
                <Space direction="vertical">
                    <Title level={3}>
                        {t("merchant.createMerchant")}
                    </Title>
                    <Row >
                        <Col span={12}>
                            <Form
                                colon={false}
                                labelCol={{
                                    xl: {
                                        span: 8,
                                    },
                                }}
                                wrapperCol={{
                                    xl: {
                                        span: 24,
                                    },
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                form={form}
                                layout={"vertical"}
                            >
                                <Form.Item
                                    name="code"
                                    label={t("merchant.code")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("merchant.pleaseEnterCode"),
                                        },
                                    ]}
                                >


                                    <Input
                                        placeholder={t("merchant.enterCode")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="name"
                                    label={t("merchant.name")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("merchant.pleaseEnterName"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("merchant.enterName")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="company_name"
                                    label={t("merchant.comName")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("merchant.pleaseEnterComName"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("merchant.enterCode")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="company_phone"
                                    label={t("merchant.comPhone")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("merchant.pleaseEnterComPhone"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("merchant.enterComPhone")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="company_email"
                                    label={t("merchant.comEmail")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("merchant.pleaseEnterComEmail"),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t("merchant.enterComEmail")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="company_address"
                                    label={t("merchant.comAddress")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("merchant.pleaseEnterComAddress"),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t("merchant.enterComAddress")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="remark"
                                    label={t("merchant.remark")}
                                >
                                    <TextArea maxLength={100} style={{ height: 120 }} onChange={() => { }} />
                                </Form.Item>
                                <Form.Item style={{ textAlign: "right" }}>
                                    <LargeButton icon={<SaveOutlined />} text={t("component.save")}/>
                                </Form.Item>
                            </Form>
                        </Col>


                        <Col xl={{ span: 12 }}>

                        </Col>
                    </Row>
                </Space>
            </Layout>


        </Spin>
    );
};

export default CreateMerchants;
