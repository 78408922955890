export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USERS = "SET_USERS";
export const DELETE_USER = "DELETE_USER";

export const SET_LOADING = "SET_LOADING";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_DELETE = "SET_DELETE";
export const SET_DELETE_SUCCESS = "SET_DELETE_SUCCESS";

export const ADD_ERROR = "ADD_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";

export const SET_MERCHANT = "SET_MERCHANT";
export const SET_MERCHANTS = "SET_MERCHANTS";
export const DELETE_MERCHANT = "DELETE_MERCHANT";

export const SET_ITEM = "SET_ITEM";
export const SET_ITEMS = "SET_ITEMS";
export const DELETE_ITEM = "DELETE_ITEM";
export const SET_BEST_SELLER_ITEMS = "SET_BEST_SELLER_ITEMS";

export const SET_CATEGORY = "SET_CATEGORY";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const DELETE_CATEGORY = "DELETE_CATEGORY";


export const SET_STOCK = "SET_STOCK";
export const SET_STOCKS = "SET_STOCKS";
export const DELETE_STOCK = "DELETE_STOCK";
export const SET_OUT_OF_STOCKS = "SET_OUT_OF_STOCKS";
export const SET_LESS_STOCKS = "SET_LESS_STOCKS";

export const SET_USED_ITEM = "SET_USED_ITEM";
export const SET_USED_ITEMS = "SET_USED_ITEMS";
export const DELETE_USED_ITEM = "DELETE_USED_ITEM";

export const SET_SUPPLIER_EXCHANGE = "SET_SUPPLIER_EXCHANGE";
export const SET_SUPPLIER_EXCHANGES = "SET_SUPPLIER_EXCHANGES";
export const DELETE_SUPPLIER_EXCHANGE = "DELETE_SUPPLIER_EXCHANGE";

export const SET_CUSTOMER_EXCHANGE = "SET_CUSTOMER_EXCHANGE";
export const SET_CUSTOMER_EXCHANGES = "SET_CUSTOMER_EXCHANGES";
export const DELETE_CUSTOMER_EXCHANGE = "DELETE_CUSTOMER_EXCHANGE";

export const SET_STAFF = "SET_STAFF";
export const SET_STAFFS = "SET_STAFFS";
export const DELETE_STAFF = "DELETE_STAFF";

export const SET_SALARY_AND_BENEFIT = "SET_SALARY_AND_BENEFIT";
export const SET_SALARY_AND_BENEFITS = "SET_SALARY_AND_BENEFITS";
export const DELETE_SALARY_AND_BENEFIT = "DELETE_SALARY_AND_BENEFIT";

export const SET_COMMISSION = "SET_COMMISSION";
export const SET_COMMISSIONS = "SET_COMMISSIONS";
export const DELETE_COMMISSION = "DELETE_COMMISSION";

export const SET_EXPENSE = "SET_EXPENSE";
export const SET_EXPENSES = "SET_EXPENSES";
export const DELETE_EXPENSE = "DELETE_EXPENSE";

export const SET_EXPENSE_NAME = "SET_EXPENSE_NAME";
export const SET_EXPENSES_NAMES = "SET_EXPENSES_NAMES";
export const DELETE_EXPENSE_NAME = "DELETE_EXPENSE_NAME";

export const SET_PRICE_HISTORY = "SET_PRICE_HISTORY";
export const SET_PRICE_HISTORIES = "SET_PRICE_HISTORIES";
export const DELETE_PRICE_HISTORY = "DELETE_PRICE_HISTORY";

export const SET_SALE_INVOICE = "SET_SALE_INVOICE";
export const SET_SALE_INVOICES = "SET_SALE_INVOICES";
export const DELETE_SALE_INVOICE = "DELETE_SALE_INVOICE";

export const SET_REPORT = "SET_REPORT";
export const SET_DAILY_REPORTS = "SET_DAILY_REPORTS";
export const SET_ITEM_GROSS_PROFIT = "SET_ITEM_GROSS_PROFIT";

export const SET_PURCHASE_FOCS = "SET_PURCHASE_FOCS";
export const SET_SALE_FOCS = "SET_SALE_FOCS";

export const SET_SALE_ITEMS = "SET_SALE_ITEMS";

export const SET_PURCHASE_INVOICES = "SET_PURCHASE_INVOICES";
export const SET_PURCHASE_INVOICE = "SET_PURCHASE_INVOICE";
export const DELETE_PURCHASE_INVOICE = "DELETE_PURCHASE_INVOICE";
export const SET_PURCHASE_REPORTS = "SET_PURCHASE_REPORTS";

export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";



