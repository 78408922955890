import { DELETE_CUSTOMER, SET_CUSTOMER, SET_CUSTOMERS } from "../type";

  const initialState = {
    customers: [],
    customer: {},
  };
  
  const customer = (state = initialState, action) => {
    switch (action.type) {
      case SET_CUSTOMER:
        return {
          ...state,
         customer : action.payload,
        };
      case SET_CUSTOMERS:
        return {
          ...state,
          customers: action.payload,
        };
      case DELETE_CUSTOMER:
        return {
          ...state,
          customers: state.customers.filter((customer) => customer.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default customer;
  