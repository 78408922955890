import { Space, Typography, Row, Col, Spin, Layout, List, Avatar, Card } from "antd";
import React, {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getStaff } from "../../store/actions";
import {useTranslation} from "react-i18next";


const ShowStaffDetails = () => {
    const param = useParams();
    const id = param.id;

    const dispatch= useDispatch();
    const staff = useSelector((state)=> state.staff.staff);
    const status = useSelector((state)=> state.status);
    const {t} = useTranslation();

    useEffect(()=>{
        dispatch(getStaff(id))
    },[dispatch,id])
   
    const { Title } = Typography;
    const data = [
        {
            title: t("staff.code"),
            value: staff.code
        },
        {
            title: t("staff.name"),
            value: staff.name
        },
        {
            title: t("staff.dob"),
            value: staff.dob
        },
        {
            title: t("staff.nrc"),
            value: staff.nrc
        },
        {
            title: t("staff.startDate"),
            value: staff.start_job
        },
        {
            title: t("staff.endDate"),
            value: staff.end_job 
        },
        {
            title: t("staff.phone"),
            value: staff.phone
        },
        {
            title: t("staff.address"),
            value: staff.address
        },
        {
            title: t("staff.position"),
            value: staff.position
        },
        {
            title: t("staff.salary"),
            value: staff.salary
        },
    ];

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }} >
                <Space direction="vertical">
                    <Title level={3} align="center">
                    {t("staff.staffDetails")}
                    </Title>
                    <Row justify="center"> 
                        <Col xl={{ span: 10 }} md={{span:20}} align="center">
                            <Card style={{ borderRadius: "10px" }}>
                                    <Avatar size={100} icon={<img src={staff.image} alt="staff_image" style={{borderRadius:"50px"}}/>} />  
                                <List
                                    size="large"
                                    itemLayout="horizontal"
                                    dataSource={data}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={item.title}
                                                description={item.value}
                                                style={{direction:"horizontal"}}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Card>


                        </Col>
                    </Row>

                </Space>

            </Layout>
        </Spin>
    );
}
export default ShowStaffDetails;