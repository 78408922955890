import { DELETE_SALARY_AND_BENEFIT, SET_SALARY_AND_BENEFIT, SET_SALARY_AND_BENEFITS } from "../type";

  const initialState = {
    salaryAndBenefits: [],
    salaryAndBenefit: {},
  };
  
  const salaryAndBenefit = (state = initialState, action) => {
    switch (action.type) {
      case SET_SALARY_AND_BENEFIT:
        return {
          ...state,
         salaryAndBenefit : action.payload,
        };
      case SET_SALARY_AND_BENEFITS:
        return {
          ...state,
          salaryAndBenefits: action.payload,
        };
      case DELETE_SALARY_AND_BENEFIT:
        return {
          ...state,
          salaryAndBenefits: state.salaryAndBenefits.filter((salaryAndBenefit) => salaryAndBenefit.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default salaryAndBenefit;
  