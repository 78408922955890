import { call } from "../../services/api";
import { serverErrorMessage } from "../../utils/messages";
import { ADD_ERROR, REMOVE_ERROR, SET_LESS_STOCKS, SET_LOADING, SET_OUT_OF_STOCKS, SET_STOCKS, SET_SUCCESS } from "../type";

export const getStocks = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("get", "stocks");
      const result = response.data;
      console.log("Stocks:", result)

      const transformResult = result.map((data) => {
        return {
          ...data,
          key: data.id,
        };
      });
      dispatch({
        type: SET_STOCKS,
        payload: transformResult,
      });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: serverErrorMessage,
        });
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getOutOfStocks = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("get", "outof-stocks");
      const result = response.data;

      const transformResult = result.map((data) => {
        return {
          ...data,
          key: data.id,
        };
      });
      dispatch({
        type: SET_OUT_OF_STOCKS,
        payload: transformResult,
      });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: serverErrorMessage,
        });
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getLessStocks = (qty) => {  
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("get", `outof-stocks?less_stocks=${qty}`);
      const result = response.data;

      const transformResult = result.map((data) => {
        return {
          ...data,
          key: data.id,
        };
      });
      dispatch({
        type: SET_LESS_STOCKS,
        payload: transformResult,
      });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: serverErrorMessage,
        });
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const createStocks = (data) => {
  //data = [ ]
  data = { stocks: data };   //coz api need { stocks:[ ] } , 
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    dispatch({ type: SET_LOADING });
    try {
      await call("post", "stocks/batchInsert", data);

      dispatch({ type: SET_SUCCESS, payload: true });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: serverErrorMessage,
        });
      }

    }
    setTimeout(() => {
      dispatch({ type: SET_SUCCESS, payload: false });
    }, 1);
    dispatch({ type: SET_LOADING });
  };
};


// export const deleteStock = (id) => {
//   return async (dispatch) => {
//       dispatch({ type: SET_SUCCESS, payload: false });
//       dispatch({ type: SET_LOADING });
//       try {
//           await call("delete", `salary-and-benefits/${id}`);

//           dispatch({ type: SET_SUCCESS, payload: true });
//           dispatch({ type: DELETE_SALARY_AND_BENEFIT, payload: id });
//           dispatch({
//               type: REMOVE_ERROR,
//           });
//       } catch (error) {
//           const { status, data } = error.response;

//           if (status === 401) {
//               localStorage.removeItem("jwtToken");
//               dispatch({
//                   type: ADD_ERROR,
//                   payload: data.message,
//               });
//           }

//           if (status === 500) {
//               dispatch({
//                   type: ADD_ERROR,
//                   payload: serverErrorMessage,
//               });
//           }
//       }
//       setTimeout(() => {
//           dispatch({ type: SET_SUCCESS, payload: false });
//       }, 1);
//       dispatch({ type: SET_LOADING });
//   };
// };





