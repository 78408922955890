import React, { useEffect } from "react";
import {
    Form,
    Typography,
    Space,
    Table,
    Spin,
    Row,
    Col,
    Divider,
    Checkbox,
    Card,
    Select,
    Input,
    Button,
    DatePicker,
    message
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
    SaveOutlined,
    PlusSquareOutlined,
    DeleteOutlined
} from "@ant-design/icons";
import Label from "../../components/Label";
import ShowText from "../../components/ShowText";
import IconButton from "../../components/IconButton";
import { useSelector, useDispatch } from "react-redux";
import { createCustomerExchange, getStocks } from "../../store/actions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { cod, credit } from "../../utils/invoiceType";

const { Title } = Typography;
const { Option } = Select;

const CreateCustomerExchange = () => {
    //for Take and Give checkbox
    const [takeChecked, setTakeChecked] = useState(false);
    const [giveChecked, setGiveChecked] = useState(true);
    const [is_take, setIstake] = useState(0);

    const [takenItems, setTakenItems] = useState([]);
    const [givenItems, setGivenItems] = useState([]);
    const [givenTotal, setGivenTotal] = useState(0);
    const [takenTotal, setTakenTotal] = useState(0);
    const [differentTotal, setDifferentTotal] = useState(0);

    const current = new Date();
    const currentDate = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
    const [date, setDate] = useState(currentDate);

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const stocks = useSelector((state) => state.stock.stocks);
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const { t } = useTranslation();

    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        if (status.success) {
            message.success(t("message.successCreateMessage"));
        }

        return () => status.success;
    }, [form, status.success, t]);


    useEffect(() => {
        dispatch(getStocks());
    }, [dispatch]);


    useEffect(() => {
        setDifferentTotal(takenTotal - givenTotal);
    }, [givenTotal, takenTotal]);

    //when select date
    const onChange = (_, dateString) => {
        setDate(dateString);
    }

    //when change give checkbox
    const giveOnChange = (e) => {
        if (e.target.checked) {
            setGiveChecked(true);
            setTakeChecked(false);
            setIstake(0);
        } else {
            setGiveChecked(false);
            setTakeChecked(true);
            setIstake(1);
        }

    }
    //when change take checkbox
    const takeOnChange = (e) => {
        if (e.target.checked) {
            setTakeChecked(true);
            setGiveChecked(false);
            setIstake(1);
        } else {
            setTakeChecked(false);
            setGiveChecked(true);
            setIstake(0);
        }

    }

    const onFinish = (values) => {
        //to get selected stock 's info (price, stock_quantity)
        const index = stocks.findIndex(
            (stock) => stock.id === values.stock_id
        );
        const selectedStock = stocks[index];
        console.log("Selected Stock:", selectedStock);

        const price = values.type === "cod" ? selectedStock.product.cod_sale_price : selectedStock.product.credit_sale_price;
        const item_name = selectedStock.product.name;
        const stock_qty = selectedStock.quantity;
        if (values.quantity > stock_qty && is_take === 0) {  // if give = true, check stock , if not, not need to check stock
            message.error(t("message.stockErrorMessage"));
        }
        else {
            const sub_total = price * (values.quantity);
            if (is_take) {
                setTakenItems([
                    ...takenItems,
                    { ...values, is_take, price, sub_total, item_name, key: takenItems.length + 1 }  //sub_total, item_name, and key are included coz needed in table dispaly
                ])
                setTakenTotal(takenTotal + sub_total);
            } else {
                setGivenItems([
                    ...givenItems,
                    { ...values, is_take, price, sub_total, item_name, key: givenItems.length + 1 }
                ]);
                setGivenTotal(givenTotal + sub_total);
            }
            form.resetFields();
        }
    }

    const handleSave = async () => {
        const data = {
            "date": date.toString(),
            "take_total": takenTotal,
            "give_total": givenTotal,
            "customer_change_items": [
                ...takenItems,
                ...givenItems,
            ],
            "difference": differentTotal,
        }
        await dispatch(createCustomerExchange(data));
        setTakenItems([]);
        setGivenItems([]);
        setTakenTotal(0);
        setGivenTotal(0);
        setDifferentTotal(0);
    }

    const handleDelete = (record) => {
        //if record.is_take is true, filter record form takenItems
        if (record.is_take) {
            const filteredTakenItems = takenItems.filter((item) => item !== record);
            const transformedTakenItems = filteredTakenItems.map((item, index) => {
                return {
                    ...item,
                    key: index + 1
                }
            })
            setTakenItems(transformedTakenItems);
            setTakenTotal(takenTotal - record.sub_total);
        }
        else {
            const filteredGivenItems = givenItems.filter((item) => item !== record);
            const transformedGivenItems = filteredGivenItems.map((item, index) => {
                return {
                    ...item,
                    key: index + 1
                }
            })
            setGivenItems(transformedGivenItems);
            setGivenTotal(givenTotal - record.sub_total);
        }
    }

    const columns = [
        {
            title: t("exchange.itemName"),
            dataIndex: "item_name",

        },
        {
            title: t("exchange.qty"),
            dataIndex: "quantity",
        },
        {
            title: t("exchange.price"),
            dataIndex: "price",
        },
        // {
        //     title: t("exchange.type"),
        //     dataIndex: "type",
        // },
        {
            title: t("exchange.subtotal"),
            dataIndex: "sub_total",
        },
        {
            title: t("component.actions"),
            render: (record) => (
                <Space direction="horizontal">
                    <IconButton icon={<DeleteOutlined />} onClick={() => { handleDelete(record) }} bgColor="var(--red-color)"> </IconButton>
                </Space>
            ),
        }

    ];




    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }} >
                <Space direction="vertical" >
                    <Title style={{ marginBottom: "35px" }} level={3}>
                        {t("exchange.createCustomerExchange")}
                    </Title>
                    <DatePicker onChange={onChange} />
                    {/* COD , Credit checkbox card */}
                    <Row style={{ marginBottom: "20px" }}>
                        <Col xl={{ span: 4 }} md={{ span: 10 }} align="center">
                            <Card
                                align="center"
                                style={{ borderRadius: 8, backgroundColor: "var(--white-color)" }}
                                hoverable
                            >
                                <Space split={<Divider />}>
                                    <Checkbox onChange={giveOnChange} checked={giveChecked}>{t("exchange.give")}</Checkbox>
                                    <Checkbox checked={takeChecked} onChange={takeOnChange}>{t("exchange.take")}</Checkbox>
                                </Space>
                            </Card>
                        </Col>
                        <Col >
                        </Col>
                    </Row>

                    {/* Form data */}
                    <Row >
                        <Col xl={{ span: 12 }} >
                            <Form
                                colon={false}
                                labelCol={{
                                    xl: {
                                        span: 8,
                                    },
                                    md: {
                                        span: 12,
                                    }
                                }}
                                wrapperCol={{
                                    xl: {
                                        span: 24,
                                    },
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                form={form}
                                layout={"vertical"}
                            >

                                <Form.Item
                                    name="stock_id"
                                    label={t("exchange.itemNameCode")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("exchange.pleaseSelectItem"),
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder={t("exchange.selectItem")}
                                        optionFilterProp="children"
                                        allowClear={true}
                                        size="large"
                                        style={{ borderRadius: "10px" }}
                                    >

                                        {stocks.map((stock) => (
                                            <Option key={stock.id} value={stock.id}>
                                                {stock.product.name} ( {stock.product.code} )
                                            </Option>
                                        ))}

                                    </Select>

                                </Form.Item>

                                <Form.Item
                                    name="quantity"
                                    label={t("exchange.qty")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("exchange.pleaseEnterQty"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("exchange.enterQty")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />


                                </Form.Item>
                                <Form.Item
                                    name="type"
                                    label={t("exchange.type")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("exchange.pleaseSelectType"),
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder={t("exchange.selectType")}
                                        optionFilterProp="children"
                                        allowClear={true}
                                        size="large"
                                        style={{ borderRadius: "10px" }}
                                    >

                                        <Option value={cod} key="cod">COD</Option>
                                        <Option value={credit} key="credit">Credit</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item style={{ textAlign: "right" }}>
                                    <Button
                                        style={{
                                            backgroundColor: "var(--primary-color)",
                                            color: "var(--white-color)",
                                            borderRadius: "10px",
                                        }}
                                        size="medium"
                                        htmlType="submit"
                                    >
                                        <PlusSquareOutlined />
                                        {t("component.add")}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>

                        <Col>
                        </Col>
                    </Row>
                    <Row >
                        <Col xl={{ span: 12 }} md={{ span: 24 }} style={{ padding: "10px" }}>
                            <Card style={{ borderRadius: "8px" }}>
                                <Space direction="vertical" style={{ width: "100%" }}>
                                    <Title level={5} align="center" style={{ margin: "20px" }}>{t("exchange.itemToCustomer")}</Title>
                                    <Table
                                        bordered
                                        columns={columns}
                                        pagination={{ position: ["none", "none"] }}
                                        dataSource={givenItems}
                                    />
                                    <Space
                                        direction="horizontal"
                                        style={{ width: "93%", justifyContent: "end", margin: "20px" }}
                                    >
                                        <Label text={t("exchange.total")} />
                                        <ShowText text={givenTotal} />
                                    </Space>
                                </Space>
                            </Card>
                        </Col>
                        <Col xl={{ span: 12 }} md={{ span: 24 }} style={{ padding: "10px" }}>
                            <Card style={{ borderRadius: "8px" }}>
                                <Space direction="vertical" style={{ width: "100%" }}>
                                    <Title level={5} align="center" style={{ margin: "20px" }}>{t("exchange.itemFromCustomer")}</Title>
                                    <Table
                                        bordered
                                        columns={columns}
                                        pagination={{ position: ["none", "none"] }}
                                        dataSource={takenItems}
                                    />
                                    <Space
                                        direction="horizontal"
                                        style={{ width: "93%", justifyContent: "end", margin: "20px" }}
                                    >
                                        <Label text={t("exchange.total")} />
                                        <ShowText text={takenTotal} />
                                    </Space>
                                </Space>
                            </Card>
                        </Col>
                    </Row>


                    {/* Total status row */}
                    <Row align="end">
                        <Col></Col>
                        <Col xl={{ span: 7 }}>
                            <Card style={{ borderRadius: "10px", padding: "20px" }}>
                                <Space
                                    direction="horizontal"
                                    style={{ width: "100%", justifyContent: "center" }}
                                >
                                    <Label text={t("exchange.difference")} />
                                    <ShowText text={differentTotal} />
                                </Space>
                            </Card>

                        </Col>
                    </Row>

                    <Space
                        direction="horizontal"
                        style={{ width: "100%", justifyContent: "right" }}
                    >
                        <Button
                            style={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white-color)",
                                borderRadius: "10px",
                            }}
                            size="large"
                            onClick={handleSave}
                        >
                            <SaveOutlined />
                            {t("component.save")}
                        </Button>
                    </Space>

                </Space>

            </Layout>
        </Spin>
    );
};


export default CreateCustomerExchange;
