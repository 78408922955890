import React from "react";
import {
    Form,
    Typography,
    Space,
    Spin,
    Row,
    Col,
    Select,
    InputNumber
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
    SaveOutlined,
} from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";

const { Title } = Typography;
const { Option } = Select;
const EditStock = () => {
    const [form] = Form.useForm();

    return (
        <Spin spinning={false}>
            <Layout style={{ margin: "20px 40px" }} >
                <Space direction="vertical">
                    <Title level={3}>
                        Stock စာရင်း ပြင်ဆင်ရန်စာမျက်နှာ
                    </Title>
                    <Row >

                        <Col span={12} >
                            <Form
                                colon={false}
                                labelCol={{
                                    xl: {
                                        span: 8,
                                    },
                                }}
                                wrapperCol={{
                                    xl: {
                                        span: 24,
                                    },
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={() => { }}
                                form={form}
                                layout={"vertical"}
                            >

                                <Form.Item
                                    name="item_name"
                                    label="ပစ္စည်းအမည်"
                                >

                                    <Select
                                        showSearch
                                        placeholder="ကျေးဇူးပြု၍ ပစ္စည်းအမည်ရွေးပါ"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        allowClear={true}
                                        size="large"
                                        style={{ borderRadius: "10px" }}
                                    >

                                        <Option value="owner" key="1">
                                            Owner
                                        </Option>
                                        <Option value="manager" key="2">
                                            Manager
                                        </Option>
                                        <Option value="cashier" key="3">
                                            Cashier
                                        </Option>

                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="quantity"
                                    label="အရေအတွက်"
                                >
                                    <InputNumber
                                        placeholder="အရေအတွက်ထည့်ပါ"
                                        style={{ borderRadius: "10px", width: "100%" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="cod_buy_price"
                                    label="COD ဝယ်စျေး"
                                >
                                    <InputNumber
                                        placeholder="CODဝယ်စျေးထည့်ပါ"
                                        style={{ borderRadius: "10px", width: "100%" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="cod_sell_price"
                                    label="COD ရောင်းစျေး"
                                >

                                    <InputNumber
                                        placeholder="CODရောင်းစျေးထည့်ပါ"
                                        style={{ borderRadius: "10px", width: "100%" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="credit_buy_price"
                                    label="Credit ၀ယ်စျေး"
                                >

                                    <InputNumber
                                        placeholder="Credit ၀ယ်စျေးထည့်ပါ"
                                        style={{ borderRadius: "10px", width: "100%" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="credit_sell_price"
                                    label="Credit ရောင်းစျေး"
                                >

                                    <InputNumber
                                        placeholder="Credit ရောင်းစျေးထည့်ပါ"
                                        style={{ borderRadius: "10px", width: "100%" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item style={{ textAlign: "right" }}>
                                    <LargeButton icon={<SaveOutlined />} text="သိမ်းမည်" /> </Form.Item>
                            </Form>
                        </Col>

                        <Col span={12}>
                        </Col>
                    </Row>

                </Space>

            </Layout>
        </Spin>
    );
};


export default EditStock;
