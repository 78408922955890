import React, { useEffect } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Table,
  Spin,
  Popconfirm,
  message,
  DatePicker,
  Select,
  Tag
} from "antd";
import Layout from "antd/lib/layout/layout";
import { DeleteOutlined, AlignLeftOutlined } from "@ant-design/icons";
import IconButton from "../../components/IconButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteCustomerExchange, getCustomerExchanges } from "../../store/actions";
import { successDeleteMessage } from "../../utils/messages";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs";
import { useState } from "react";
import Label from "../../components/Label";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const ShowCustomerExchanges = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  let customerExchanges = useSelector((state) => state.customerExchange.customerExchanges);
  let status = useSelector((state) => state.status);
  let error = useSelector((state) => state.error);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState('');
  const [transformedResult, setTransformedResult] = useState([]);

  const [searchParams] = useSearchParams();


  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(t("message.successDeleteMessage"));
    }
    return () => status.success;
  }, [status.success, t]);

  useEffect(() => {
    dispatch(getCustomerExchanges(searchParams));
  }, [dispatch, searchParams])


  useEffect(() => {
    navigate(`/admin/show-customer-exchanges?start_date=${startDate}&end_date=${endDate}&type=${type}`)
  }, [startDate, endDate, type, navigate]);

  useEffect(() => {
    transformData(customerExchanges);
  }, [customerExchanges])

  let taken_qty;
  let given_qty;
  let difference_amount;
  var taken_items = [];
  var given_items = [];
  const transformData = (customerExchanges) => {
    let transformedExchanges = customerExchanges.map((exchange) => {
      //calculate taken and given qty of each exchange
      taken_qty = 0;
      given_qty = 0;

      taken_items = exchange.customer_change_take_items;
      given_items = exchange.customer_change_give_items;

      taken_items.map((item) => {
        taken_qty += parseInt(item.quantity);
        return null;
      })

      given_items.map((item) => {
        given_qty += parseInt(item.quantity);
        return null;
      })

      difference_amount = exchange.take_total - exchange.give_total;

      exchange = {
        ...exchange,
        taken_qty,
        given_qty,
        difference_amount
      }
      return exchange;
    })

    setTransformedResult(transformedExchanges);
  }




  const handleDelete = async (id) => {
    await dispatch(deleteCustomerExchange(id));
  }

  const onChangeType = (value) => {
    if (value === undefined) { //clear input
      setType("");
    }
    else {
      setType(value);
    }
  }


  //for excel export
  const fileName = "Customer Exchanges";
  const transformedData = transformedResult.map((exchange) => {
    return {
      Date: exchange.date,
      GivenQuantity: exchange.given_qty,
      TakenQuantity: exchange.taken_qty,
      GivenAmount: exchange.give_total,
      TakenAmount: exchange.take_total,
      DifferentAmount: exchange.difference_amount,
    };
  });

  const columns = [
    {
      title: t("exchange.date"),
      dataIndex: "date",
    },
    {
      title: t("exchange.givenQty"),
      dataIndex: "given_qty",
    },
    {
      title: t("exchange.takenQty"),
      dataIndex: "taken_qty",
    },
    {
      title: t("exchange.givenAmount"),
      dataIndex: "give_total",
    },
    {
      title: t("exchange.takenAmount"),
      dataIndex: "take_total",
    },
    {
      title: t("exchange.difference"),
      dataIndex: "difference_amount",
    },
    {
      title: t("exchange.status"),
      dataIndex: "is_confirm",
      render: (is_confirm) => (
        (is_confirm) ? <Tag color="green" style={{ borderRadius: "5px" }}>{t("exchange.confirmed")}</Tag> : <Tag color="gold" style={{ borderRadius: "5px" }}>{t("exchange.unconfirmed")}</Tag>
      )
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (id) => (
        <Space direction="horizontal">
          <IconButton icon={<AlignLeftOutlined />} onClick={() => { navigate(`/admin/show-customer-exchanges/${id}`) }} bgColor="var(--primary-color)"> </IconButton>
          {/* <IconButton icon={<EditOutlined />} onClick={() => {navigate(`/admin/edit-customer-exchanges/${id}`) }} bgColor="var(--green-color)"> </IconButton> */}
          <Popconfirm
            title={t("component.sureDelete")}
            okText="Yes"
            cancelText="No"
            onConfirm={() => { handleDelete(id) }}
          >
            <IconButton icon={<DeleteOutlined />} bgColor="var(--red-color)"> </IconButton>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 21 }} md={{ span: 18 }}>
              <Title level={3}> {t("exchange.customerExchangeList")}</Title>
            </Col>

            <Col xl={{ span: 3 }} md={{ span: 6 }}>
              <ExportToExcel apiData={transformedData} fileName={fileName} />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 6 }} md={{ span: 12 }}>
              <RangePicker
                onChange={(val) => {
                  if (val === null) {  //clear input
                    setStartDate("");
                    setEndDate("");
                  }
                  else {
                    setStartDate(dayjs(val[0]).format("YYYY-MM-DD"));
                    setEndDate(dayjs(val[1]).format("YYYY-MM-DD"));
                  }
                }
                }
              />
            </Col>
            <Col xl={{ span: 6 }} md={{ span: 12 }}>
              <Label text={t("exchange.listType")} />
              <Select
                showSearch
                placeholder={t("exchange.selectType")}
                optionFilterProp="children"
                allowClear={true}
                size="medium"
                style={{ borderRadius: "10px", width: "50%" }}
                onChange={onChangeType}
              >
                <Option value='1'>{t("exchange.confirmedList")}</Option>
                <Option value='0'>{t("exchange.unconfirmedList")}</Option>
              </Select>
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={transformedResult}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

export default ShowCustomerExchanges;
