import { DELETE_CATEGORY, SET_CATEGORIES, SET_CATEGORY } from "../type";

const initialState = {
    category: {},
    categories: [],
};
const category = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORY:
            return {
                ...state,
                category: action.payload,
            };
        case SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            };
        case DELETE_CATEGORY:
            return {
                ...state,
                categories: state.categories.filter((category) => category.id !== action.payload),
            };
        default:
            return state;
    }
};
export default category;