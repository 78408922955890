import React, { useState, useEffect } from "react";
import {
    Form,
    Typography,
    Space,
    Spin,
    Row,
    Col,
    Input,
    DatePicker,
    message,
    Select
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
    SaveOutlined,
} from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import InputUpload from "../../components/InputUpload";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { editStaff, getStaff } from "../../store/actions";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { cashier, manager, owner, saleman } from "../../utils/position";

const { Title, Text } = Typography;
const { Option } = Select;

const EditStaff = () => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);

    const params = useParams();
    const id = params.id;

    const dispatch = useDispatch();
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const staff = useSelector((state) => state.staff.staff);

    const [dob, setDob] = useState();
    const [start_job, setStartJob] = useState();
    const [end_job, setEndJob] = useState();

    useEffect(() => {
        if (staff) {
            setStartJob(staff.start_job);
            setEndJob(staff.end_job);
            setDob(staff.dob);
        }
    }, [staff])

    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getStaff(id));
        }
        fetchData();
    }, [dispatch, id])

    useEffect(() => {

        //to display image:
        setFileList([
            {
                uid: staff.id,
                name: staff.name?.name,
                status: "done",
                url: staff.image,
            },
        ]);

        form.setFieldsValue({ code: staff.code });
        form.setFieldsValue({ name: staff.name });
        form.setFieldsValue({ nrc: staff.nrc });
        form.setFieldsValue({ phone: staff.phone });
        form.setFieldsValue({ dob: moment(staff.dob, 'YYYY-MM-DD') });
        form.setFieldsValue({ start_job: moment(staff.start_job, 'YYYY-MM-DD') });
        if (staff.end_job) {
            form.setFieldsValue({ end_job: moment(staff.end_job, 'YYYY-MM-DD') });
        }

        form.setFieldsValue({ address: staff.address });
        form.setFieldsValue({ position: staff.position });
        form.setFieldsValue({ salary: staff.salary });

    }, [form, staff]);

    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        if (status.success) {
            message.success(t("message.successEditMessage"));
        }

        return () => status.success;
    }, [form, status.success, t]);


    const onChangeOfDob = (_, dateString) => {
        setDob(dateString);

    };

    const onChangeofStartJob = (_, dateString) => {
        setStartJob(dateString);
    };
    const onChangeOfEndJob = (_, dateString) => {
        setEndJob(dateString)
    };

    const onFinish = async (values) => {
        const formData = new FormData();

        if (fileList[0].status !== "done") {
            formData.append("image", fileList[0].originFileObj);
        }

        formData.append("code", values.code);
        formData.append("name", values.name);
        formData.append("dob", dob);
        formData.append("nrc", values.nrc);
        formData.append("start_job", start_job);
        if (end_job) {
            formData.append("end_job", end_job);
        }
        formData.append("phone", values.phone);
        formData.append("address", values.address);
        formData.append("position", values.position);
        formData.append("salary", values.salary);

        await dispatch(editStaff(id, formData));
    };

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }} >
                <Space direction="vertical">
                    <Title level={3}>
                        {t("staff.editStaff")}
                    </Title>
                    <Row >

                        <Col span={12} >
                            <Form
                                colon={false}
                                labelCol={{
                                    xl: {
                                        span: 8,
                                    },
                                }}
                                wrapperCol={{
                                    xl: {
                                        span: 24,
                                    },
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                form={form}
                                layout={"vertical"}
                            >
                                <Space
                                    direction="vertical"
                                    style={{
                                        width: "100%",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <InputUpload fileList={fileList} setFileList={setFileList} />
                                    <Text type="secondary">  {t("staff.pleaseInsertImage")}</Text>
                                </Space>
                                <Form.Item
                                    name="code"
                                    label={t("staff.code")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.enterCode"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("staff.enterCode")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="name"
                                    label={t("staff.name")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterName"),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t("staff.enterName")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="dob"
                                    label={t("staff.dob")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterDob"),
                                        },
                                    ]}
                                >

                                    <DatePicker onChange={onChangeOfDob} style={{ width: "100%", borderRadius: "10px" }} size="large" />
                                </Form.Item>
                                <Form.Item
                                    name="nrc"
                                    label={t("staff.nrc")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterNrc"),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t("staff.enterNrc")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="start_job"
                                    label={t("staff.startDate")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterStartDate"),
                                        },
                                    ]}

                                >

                                    <DatePicker onChange={onChangeofStartJob} style={{ width: "100%", borderRadius: "10px" }} size="large" />
                                </Form.Item>
                                <Form.Item
                                    name="end_job"
                                    label={t("staff.endDate")}

                                >

                                    <DatePicker onChange={onChangeOfEndJob} style={{ width: "100%", borderRadius: "10px" }} size="large" />
                                </Form.Item>
                                <Form.Item
                                    name="phone"
                                    label={t("staff.phone")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterPhone"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("staff.enterPhone")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="address"
                                    label={t("staff.address")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterAddress"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("staff.enterAddress")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="position"
                                    label={t("staff.position")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterPosition"),
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder={t("staff.enterPosition")}
                                        optionFilterProp="children"
                                        allowClear={true}
                                        size="large"
                                        style={{ borderRadius: "10px" }}
                                    // onChange={onChange}
                                    >
                                        <Option key={owner} value={owner}>
                                            {t("account.owner")}
                                        </Option>
                                        <Option key={manager} value={manager}>
                                            {t("account.manager")}
                                        </Option>
                                        <Option key={cashier} value={cashier}>
                                            {t("account.cashier")}
                                        </Option>
                                        <Option key={saleman} value={saleman}>
                                            {t("account.saleman")}
                                        </Option>
                                    </Select>
                                </Form.Item>


                                <Form.Item
                                    name="salary"
                                    label={t("staff.salary")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("staff.pleaseEnterSalary"),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t("staff.enterSalary")}
                                        style={{ borderRadius: "10px", width: "100%" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item style={{ textAlign: "right" }}>
                                    <LargeButton icon={<SaveOutlined />} bgColor="var(--primary-color)" text={t("component.save")} />
                                </Form.Item>
                            </Form>
                        </Col>

                        <Col xl={{ span: 12 }}>
                        </Col>
                    </Row>

                </Space>

            </Layout>
        </Spin>
    );
};


export default EditStaff;
