import React from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Table,
  Spin,
  Popconfirm,
  Select,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import { PlusSquareOutlined, DeleteOutlined, AlignLeftOutlined } from "@ant-design/icons";
import MediumButton from "../../components/MediumButton";
import IconButton from "../../components/IconButton";
import { useNavigate } from "react-router-dom";
import Label from "../../components/Label";
import ShowText from "../../components/ShowText";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { deletePurchaseInvoice, getPurchaseInvoices } from "../../store/actions/purchase";
import { getReadableDateDisplay } from "../../utils/convertToHumanReadableTime";
import { getMerchants } from "../../store/actions/merchant";
import { cod, credit } from "../../utils/invoiceType";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;

const ShowPurchaseInvoices = () => {
  const navigate = useNavigate();
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const merchants = useSelector((state) => state.merchant.merchants);
  const purchaseInvoices = useSelector((state) => state.purchase.purchaseInvoices);
  const dispatch = useDispatch();
  let credit_total = 0;
  const [merchantId, setMerchantId] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [type, setType] = useState("");
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();


  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(t("message.successDeleteMessage"));
    }
    return () => status.success;
  }, [status.success, t]);

  useEffect(() => {
    dispatch(getMerchants());
  }, [dispatch])

  useEffect(() => {
    dispatch(getPurchaseInvoices(searchParams));
  }, [dispatch, searchParams])


  useEffect(() => {
    navigate(`/admin/show-purchase-invoices?merchant_id=${merchantId}&invoice_code=${invoiceNo}&type=${type}`)
  }, [merchantId, invoiceNo, type, navigate])


  const handleDelete = (id) => {
    dispatch(deletePurchaseInvoice(id));
  }

  purchaseInvoices.map((invoice) => {
    credit_total += parseInt(invoice.credit);
    return null;
  })

  const onChangeMerchant = (value) => {
    if (value === undefined) {
      setMerchantId("");
    }
    else {
      setMerchantId(value);
    }

  }

  const onChangeInvoiceNo = (value) => {
    if (value === undefined) {
      setInvoiceNo("");
    }
    else {
      setInvoiceNo(value);
    }

  }

  const onChangeType = (value) => {
    if (value === undefined) {
      setType("");
    }
    else {
      setType(value);
    }

  }
  //for excel export
  const fileName = "PurchaseInvoices";
  const transformedData = purchaseInvoices.map((invoice) => {
    return {
      Date: getReadableDateDisplay(invoice.created_at),
      InvoceCode: invoice.invoice_code,
      CompanyName: invoice.merchant.company_name,
      Total: invoice.whole_total,
      PaidAmount: invoice.paid,
      CreditAmount: invoice.credit,
      Type: invoice.type,
    };
  });

  const columns = [
    {
      title: t("purchaseInvoice.date"),
      dataIndex: "created_at",
      render: (created_at) => getReadableDateDisplay(created_at)
    },
    {
      title: t("purchaseInvoice.invoiceNo"),
      dataIndex: "invoice_code",
    },
    {
      title: t("purchaseInvoice.companyName"),
      dataIndex: "merchant",
      render: (merchant) => merchant.company_name,
    },
    {
      title: t("purchaseInvoice.total"),
      dataIndex: "whole_total",
      sorter: {
        compare: (a, b) => a.whole_total - b.whole_total,
        multiple: 3,
      },
    },
    {
      title: t("purchaseInvoice.paidAmount"),
      dataIndex: "paid",
      sorter: {
        compare: (a, b) => a.paid - b.paid,
        multiple: 2,
      },
    },
    {
      title: t("purchaseInvoice.creditAmount"),
      dataIndex: "credit",
      sorter: {
        compare: (a, b) => a.credit - b.credit,
        multiple: 1,
      },
    },
    {
      title: t("purchaseInvoice.type"),
      dataIndex: "type",
    },
    {
      title: t("component.actions"),
      dataIndex: "id",
      render: (id) => (
        <Space direction="horizontal">
          <IconButton icon={<AlignLeftOutlined />} onClick={() => { navigate(`/admin/show-purchase-invoices/${id}`) }} bgColor="var(--primary-color)"> </IconButton>
          <Popconfirm
            title={t("component.sureDelete")}
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDelete(id)}
          >
            <IconButton icon={<DeleteOutlined />} bgColor="var(--red-color)"> </IconButton>
          </Popconfirm>
          <IconButton onClick={() => { navigate(`/admin/create-purchase-credits/${id}`) }} bgColor="var(--yellow-color)" text={t("purchaseInvoice.toPayCredit")}> </IconButton>
        </Space>
      ),
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 18 }} md={{ span: 12 }}>
              <Title level={3}> {t("purchaseInvoice.purchaseList")} </Title>
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 6 }}>
              <MediumButton icon={<PlusSquareOutlined />} text={t("component.addNew")} bgColor="var(--primary-color)" onClick={() => { navigate("/admin/create-purchase-invoice") }} />
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 6 }}>
              <ExportToExcel apiData={transformedData} fileName={fileName} />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xl={{ span: 6 }} md={{ span: 24 }}>
              <Label text={t("purchaseInvoice.companyName")} />
              <Select
                showSearch
                placeholder={t("purchaseInvoice.selectCompanyName")}
                optionFilterProp="children"
                allowClear={true}
                size="medium"
                style={{ borderRadius: "10px" }}
                onChange={onChangeMerchant}
              >
                {merchants.map((merchant) => (
                  <Option value={merchant.id} key={merchant.id}>{merchant.name}</Option>
                ))}

              </Select>
            </Col>
            <Col xl={{ span: 6 }} md={{ span: 24 }}>
              <Label text={t("purchaseInvoice.invoiceNo")} />
              <Select
                showSearch
                placeholder={t("purchaseInvoice.selectInvoiceNo")}
                optionFilterProp="children"
                allowClear={true}
                size="medium"
                style={{ borderRadius: "10px" }}
                onChange={onChangeInvoiceNo}
              >
                {purchaseInvoices.map((invoice) => (
                  <Option value={invoice.invoice_code} key={invoice.invoice_code}>{invoice.invoice_code}</Option>
                ))}
              </Select>
            </Col>
            <Col xl={{ span: 6 }} md={{ span: 24 }}>
              <Label text={t("purchaseInvoice.type")} />
              <Select
                showSearch
                placeholder={t("purchaseInvoice.selectType")}
                optionFilterProp="children"
                allowClear={true}
                size="medium"
                style={{ borderRadius: "10px" }}
                onChange={onChangeType}
              >

                <Option value={cod} key="1">
                  COD
                </Option>
                <Option value={credit} key="2">
                  Credit
                </Option>

              </Select>
            </Col>
            <Col xl={{ span: 6 }} md={{ span: 24 }}>
              <Space>
                <Label text={t("purchaseInvoice.creditAmount")} />
                <ShowText text={credit_total} />
              </Space>

            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={purchaseInvoices}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

export default ShowPurchaseInvoices;
