import React, { useEffect } from "react";

// ant design styles
import {
  Layout,
  Row,
  Col,
  Space,
  Typography,
  Input,
  Table,
  InputNumber,
  Card,
  Checkbox,
  Divider,
  Select,
  message,
  Button,
  Spin,
  Radio
} from "antd";

import {
  LeftOutlined,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import Sider from "antd/lib/layout/Sider";
import Meta from "antd/lib/card/Meta";
import MediumButton from "../../components/MediumButton";
import Label from "../../components/Label";
import ShowText from "../../components/ShowText";
import { useSelector, useDispatch } from "react-redux";
import { createSaleInvoice, getStaffs, getStocks } from "../../store/actions";
import { useState } from "react";
import IconButton from "../../components/IconButton";
import { useNavigate } from "react-router-dom";
import { getCustomers } from "../../store/actions/customer";
import { useTranslation } from "react-i18next";
import { cashier, saleman } from "../../utils/position";
import { cod, credit } from "../../utils/invoiceType";
const { Header, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const borderStyle = {
  border: "1px solid var(--border-color)",
  borderCollapse: "collapse",
  align: "right"
};
const CreateSaleInvoice = () => {
  const navigate = useNavigate();

  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const stocks = useSelector((state) => state.stock.stocks);
  const staffs = useSelector((state) => state.staff.staffs);
  const customers = useSelector((state) => state.customer.customers);
  const [saleItems, setSaleItems] = useState([]);
  const saleInvoice = useSelector((state) => state.saleInvoice.saleInvoice);

  const dispatch = useDispatch();

  const [codChecked, setCodChecked] = useState(true);
  const [creditChecked, setCreditChecked] = useState(false);
  const [type, setType] = useState(cod);
  const [subtotal] = useState(0);
  const [buyTotal, setBuyTotal] = useState(0);
  const [saleTotal, setSaleTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);

  const [tax, setTax] = useState(0);
  const [discount, setDiscount] = useState(0);

  const [wholeTotal, setWholeTotal] = useState(0);
  const [paidTotal, setPaidTotal] = useState(0);
  const [creditTotal, setCreditTotal] = useState(0);
  const [returnedTotal, setReturnedTotal] = useState(0);
  const [paymentType, setPaymentType] = useState("");  //if not select payment-type, default type = cash
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [remark, setRemark] = useState("");
  const [cashierID, setCashierID] = useState();
  const [salemanID, setSalemanID] = useState();
  const [customerOption, setCustomerOption] = useState("oldCustomer");
  const [saleMen, setSaleMen] = useState([]);
  const [cashiers, setCashiers] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getStocks());
      await dispatch(getStaffs());
      await dispatch(getCustomers());
    };
    fetchData();
    return () => fetchData();
  }, [dispatch]);

  useEffect(() => {
    const sellers = staffs.filter((staff) => staff.position === saleman);
    setSaleMen(sellers);

    const cashiers = staffs.filter((staff) => staff.position === cashier);
    setCashiers(cashiers);

  }, [staffs])

  useEffect(() => {
    const calculateSaleTotal = () => {
      let total = 0;
      saleItems?.map((saleItem) => {
        total += saleItem?.subtotal;
        return null;
      });
      setSaleTotal(total);
    }
    const calculateBuyTotal = () => {
      let total = 0;
      saleItems?.map((saleItem) => {
        total += saleItem?.buy_subtotal;
        return null;
      });
      setBuyTotal(total);
    }

    calculateSaleTotal();
    calculateBuyTotal();

  }, [saleItems])


  useEffect(() => {
    const calculateTaxTotal = () => {
      let total = saleTotal * tax / 100;
      setTaxTotal(total);
    }
    calculateTaxTotal();
    return calculateTaxTotal;
  }, [saleTotal, tax])

  useEffect(() => {
    const calculateDiscountTotal = () => {
      let total = saleTotal * discount / 100;
      setDiscountTotal(total);
    }
    calculateDiscountTotal();
    return calculateDiscountTotal;
  }, [saleTotal, discount])

  useEffect(() => {
    const calculateWholeTotal = () => {
      let total = saleTotal + taxTotal - discountTotal;
      total = (!Number.isInteger(total)) ? total.toFixed(2) : total;
      setWholeTotal(total);
    }
    calculateWholeTotal();
    return calculateWholeTotal();
  }, [saleTotal, taxTotal, discountTotal])


  useEffect(() => {
    const calculateCreditTotal = () => {
      let total = wholeTotal === 0 ? 0 : (wholeTotal > paidTotal) ? (wholeTotal - paidTotal) : 0;
      total = (!Number.isInteger(total)) ? total.toFixed(2) : total;
      setCreditTotal(total);
    }
    calculateCreditTotal();
    return calculateCreditTotal();
  }, [wholeTotal, paidTotal])

  useEffect(() => {
    const calculateReturnedTotal = () => {
      let total = paidTotal === 0 ? 0 : (paidTotal > wholeTotal) ? (paidTotal - wholeTotal) : 0;
      total = (!Number.isInteger(total)) ? total.toFixed(2) : total;
      setReturnedTotal(total);
    }
    calculateReturnedTotal();
    return calculateReturnedTotal();
  }, [paidTotal, wholeTotal])


  useEffect(() => {
    let clonedSaleItems;
    if (saleItems) {
      clonedSaleItems = saleItems.map((saleItem) => {
        const stock = stocks.filter((stock) => stock.id === saleItem.stock_id); //to get original product.cod/credit sale price
        return {
          ...saleItem,
          price: type === cod ? stock[0]?.product.cod_sale_price : stock[0]?.product.credit_sale_price
        }
      })
      setSaleItems(clonedSaleItems);
    }
    setSaleItems(clonedSaleItems);
  }, [type])


  useEffect(() => {
    error.message !== null && message.error(error.message);
    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(t("message.successCreateMessage"));
      navigate(`/admin/show-sale-invoices/${saleInvoice?.id}`);
    }

    return () => status.success;
  }, [status.success, t, navigate, saleInvoice?.id]);


  const onChangeCod = (e) => {
    if (e.target.checked) {
      setCodChecked(true);
      setCreditChecked(false);
      setType(cod);
    }
    else {
      setCodChecked(false);
      setCreditChecked(true);
      setType(credit);
    }
  }

  const onChangeCredit = (e) => {
    if (e.target.checked) {
      setCreditChecked(true);
      setCodChecked(false);
      setType(credit);
    }
    else {
      setCreditChecked(false);
      setCodChecked(true);
      setType(cod);
    }
  }

  const onChangeQuantity = (value, record) => {
    if (value <= record.stock_quantity) {
      const index = saleItems.findIndex((saleItem) => saleItem === record);
      let clonedSaleItems = [...saleItems];
      clonedSaleItems[index] = {
        ...clonedSaleItems[index],
        quantity: value,
        subtotal: clonedSaleItems[index].price * value,
      };
      setSaleItems(clonedSaleItems);
    }
    else {
      message.warning(t("saleInvoice.noStock"));
    }

  }

  const onChangePrice = (value, record) => {
    const index = saleItems.findIndex((saleItem) => saleItem === record);
    let clonedSaleItems = [...saleItems];
    clonedSaleItems[index] = {
      ...clonedSaleItems[index],
      price: value,
      subtotal: clonedSaleItems[index].quantity * value,
    };
    setSaleItems(clonedSaleItems);
  }


  const onChangeFoc = (e, record) => {
    if (e.target.checked) {
      changeData(record);
    }
    else {
      unChangeData(record);
    }
  };
  //change subtotal = 0 and is_foc = 1 if foc_checked
  const changeData = (record) => {
    const index = saleItems.findIndex((saleItem) => saleItem === record);
    const clonedSaleItems = [...saleItems];
    clonedSaleItems[index] = {
      ...clonedSaleItems[index],
      subtotal: subtotal,
      is_foc: 1,
    }
    setSaleItems(clonedSaleItems);
  }

  //undo (change original data of saleItem) 
  const unChangeData = (record) => {
    const index = saleItems.findIndex((saleItem) => saleItem === record);
    const clonedSaleItems = [...saleItems];
    clonedSaleItems[index] = {
      ...clonedSaleItems[index],
      subtotal: clonedSaleItems[index].price * (clonedSaleItems[index].quantity),
      is_foc: 0,
    }
    setSaleItems(clonedSaleItems);
  }

  const onChangeTax = (value) => {
    if (value === undefined || value === null) {
      setTax(0);
    } else {
      setTax(value);
    }

  }

  const onChangeDiscount = (value) => {
    if (value === undefined || value === null) {
      setDiscount(0);
    } else {
      setDiscount(value);
    }

  }




  const onChangePaidTotal = (value) => {
    if (value === undefined || value === null) {
      setPaidTotal(0);
    }
    else {
      setPaidTotal(value);
    }

  }

  const onChangePaymentType = (value) => {
    setPaymentType(value);
  }


  const onChangeCustomerName = (value) => {
    if (value === undefined) {
      setCustomerName("");
      setCustomerPhone("");
    }
    else {
      const index = customers.findIndex((customer) => customer.id === value);
      const selectedCustomer = customers[index];
      setCustomerName(selectedCustomer.name);
      setCustomerPhone(selectedCustomer.phone);
    }
  }

  const onChangeRemark = (e) => {
    setRemark(e.target.value);
  }

  const onChangeSaleMan = (value) => {
    setSalemanID(value);

  }
  const onChangeCashier = (value) => {
    setCashierID(value);
  }

  const onChangeRadio = (e) => {
    setCustomerOption(e.target.value);
    setCustomerName("");
    setCustomerPhone("");
    setRemark("");
  }

  const onChangeNewCusName = (e) => {
    setCustomerName(e.target.value);
  }

  const onChangeNewCusPhone = (e) => {
    setCustomerPhone(e.target.value);
  }

  const handleDelete = (record) => {
    const filteredSaleItems = saleItems.filter((saleItem) => saleItem !== record);
    const transformedSaleItems = filteredSaleItems.map((saleItem, index) => {
      return {
        ...saleItem,
        no: index + 1,
        key: index + 1,
      }
    });
    setSaleItems(transformedSaleItems);
  }

  const addSaleItem = (stock) => {

    const index = saleItems.findIndex(
      (saleItem) => saleItem.stock_id === stock.id
    );
    if (index === -1) { //if not in saleItems,
      if (stock.quantity > 0) {

        const item_sale_price = type === cod ? stock.product.cod_sale_price : stock.product.credit_sale_price;
        const item_buy_price = type === cod ? stock.product.cod_buy_price : stock.product.credit_buy_price;

        //In api, needed data in saleItems => {stock_id, price, quantity, and is_foc } , others data are needed in table
        const saleItem = {
          no: saleItems.length + 1,
          code: stock.product.code,
          name: stock.product.name,
          price: item_sale_price, //sale price
          buy_price: item_buy_price,
          quantity: 1,
          subtotal: item_sale_price * 1,
          buy_subtotal: item_buy_price * 1,
          key: saleItems.length + 1,
          is_foc: 0,                  //when adding sale-item, is_foc status is false
          stock_id: stock.id,
          stock_quantity: stock.quantity, //coz need to check if value <= stock.quantity in onChangeQuantity
        }
        setSaleItems([
          ...saleItems,
          saleItem
        ]);
      } else {
        message.warning(t("saleInvoice.noStock"));
      }
    }
    else {
      let clonedSaleItems = [...saleItems];
      if (clonedSaleItems[index].quantity + 1 <= stock.quantity) {
        clonedSaleItems[index] = {
          ...clonedSaleItems[index],
          quantity: clonedSaleItems[index].quantity + 1,
          subtotal: clonedSaleItems[index].price * (clonedSaleItems[index].quantity + 1),
          buy_subtotal: clonedSaleItems[index].buy_price * (clonedSaleItems[index].quantity + 1),
        }
        setSaleItems(clonedSaleItems);
      } else {
        message.warning(t("saleInvoice.noStock"));
      }
    }
  }

  const handleSave = async () => {
    const data = {
      items: [...saleItems],
      "buy_total": buyTotal,
      "total": saleTotal,
      "tax": tax,
      "discount": discount,
      "paid": paidTotal,
      "credit": creditTotal,
      "payment": paidTotal,
      "change": returnedTotal,
      "payment_method": paymentType,
      "type": type,
      "customer_name": customerName,
      "customer_phone_no": customerPhone,
      "cashier_id": cashierID,
      "sale_man_id": salemanID,
      "remark": remark
    }

    await dispatch(createSaleInvoice(data));
    //  navigate(`/admin/show-sale-invoices/${saleInvoice?.id}`);
  }

  //for barcode
  const [barcode, setBarcode] = useState('');

  function onChangeBarcode(e) {
    setBarcode(e.target.value)
  }

  function onKeyPressBarcode(e) {
    if (e.keyCode === 13) {
      setBarcode(e.target.value);
      const item = stocks.find((stock) => stock.product.code === e.target.value);
      if (item) {
        addSaleItem(item);
        setBarcode(""); //clear the barcode
      }
      else {
        message.error("Not Found");
        setBarcode(""); //clear the barcode
      }
    }
  }
  console.log("saleItems:", saleItems.length)



  const columns = [
    {
      title: t("saleInvoice.no"),
      dataIndex: "no",
    },
    {
      title: t("saleInvoice.itemCode"),
      dataIndex: "code",
    },
    {
      title: t("saleInvoice.itemName"),
      dataIndex: "name",
    },
    {
      title: t("saleInvoice.price"),
      dataIndex: "price",
      render: (_, record) => (
        <InputNumber
          style={{
            width: "100px",
            backgroundColor: "var(--white-color)",
            color: "var(--black-color)",
            border: "none"
          }}
          value={record.price}
          onChange={(value) => { onChangePrice(value, record) }}
        />
      ),
    },
    {
      title: t("saleInvoice.quantity"),
      dataIndex: "quantity",
      render: (_, record) => (
        <InputNumber
          style={{
            width: "100px",
            backgroundColor: "var(--white-color)",
            color: "var(--black-color)",
            border: "none"
          }}
          value={record.quantity}
          onChange={(value) => { onChangeQuantity(value, record) }}
          min={1}
        />
      ),
    },
    {
      title: t("saleInvoice.subTotal"),
      dataIndex: "subtotal",
      align: "right",
    },
    {
      title: "FOC",
      align: "right",
      render: (record) => (
        <Checkbox onChange={(e) => { onChangeFoc(e, record) }} />
      ),
    },
    {
      title: t("component.actions"),
      render: (record) => (
        <Space direction="horizontal">
          <IconButton icon={<DeleteOutlined />} onClick={() => { handleDelete(record) }} bgColor="var(--red-color)"> </IconButton>
        </Space>
      ),
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout>
        <Header style={{ backgroundColor: "var(--primary-color)" }}>
          <Title
            style={{
              color: "var(--white-color)",
              textAlign: "center",
              marginTop: "13px",
            }}
            level={3}
          >
            {t("saleInvoice.createSaleInvoice")}
          </Title>
        </Header>

        <Layout
          style={{ backgroundColor: "var(--white-color)", padding: "10px" }}
        >
          <Row justify="space-evenly">
            <Col
              xl={{
                span: 7,
              }}
            >
              <Space>
                <Label text={t("saleInvoice.barcode")} />
                <Input
                  autoFocus={true}
                  placeholder={t("saleInvoice.startScanning")}
                  value={barcode}
                  onChange={onChangeBarcode}
                  id='SearchbyScanning'
                  onKeyDown={onKeyPressBarcode}
                  size="medium"
                />
              </Space>
            </Col>
            <Col xl={{
              span: 5,
            }}>
              <Card
                align="center"
                style={{ borderRadius: 8, backgroundColor: "white" }}
                hoverable
              >
                <Space split={<Divider />}>
                  <Checkbox onChange={onChangeCod} checked={codChecked}>COD</Checkbox>
                  <Checkbox onChange={onChangeCredit} checked={creditChecked}>Credit</Checkbox>
                </Space>
              </Card>
            </Col>

            <Col xl={{ span: 7 }} align="right">
              <MediumButton icon={<LeftOutlined />} text={t("saleInvoice.goToDashboard")} bgColor="var(--primary-color)" onClick={() => { navigate("/admin/*") }} />
            </Col>
          </Row>

        </Layout>

        <Layout style={{ display: "flex", flexDirection: "row" }}>
          <Sider
            width="470px"
            style={{
              backgroundColor: "var(--muted-color)",
              padding: "20px",
              height: "auto",
              overflow: "auto",
            }}
          >
            <Space direction="vertical">
              {/* Sale Items */}
              <Row gutter={[8, 8]}>
                {stocks.map((stock) => (
                  <Col>
                    <Card
                      hoverable
                      style={{ width: 100 }}
                      cover={<img src={stock.product.image} alt="engine_oil" style={{ height: 100 }} />}
                      onClick={() => addSaleItem(stock)}
                    >
                      <Meta title={stock.product.name} description={stock.product.code} />
                    </Card>
                  </Col>
                ))}
              </Row>
            </Space>
          </Sider>

          <Content
            style={{
              minHeight: "520px",
              backgroundColor: "var(--muted-color)",
            }}
          >
            <Layout style={{ padding: 20 }}>
              {/* buyer, seller and payment type */}
              <Row gutter={[16, 16]}>
                <Col xxl={{ span: 8 }} xl={{ span: 12 }}>
                  <Space direction="vertical">
                    <Row justify="center" style={{ marginBottom: "10px" }}>
                      <Col>
                        <Radio.Group defaultValue="oldCustomer" buttonStyle="outline" onChange={onChangeRadio} >
                          <Radio.Button value="oldCustomer">{t("saleInvoice.oldCustomer")}</Radio.Button>
                          <Radio.Button value="newCustomer">{t("saleInvoice.newCustomer")} </Radio.Button>
                        </Radio.Group>
                      </Col>
                    </Row>

                    {/* old Customer's input */}
                    {customerOption === "oldCustomer" &&
                      <Space direction="vertical">
                        <Row>
                          <Col md={{ span: 12 }}  >
                            <ShowText text={t("saleInvoice.customerName")} color="var(--primary-color)" />
                          </Col>
                          <Col md={{ span: 12 }} >
                            <Select
                              showSearch
                              placeholder={t("saleInvoice.selectCustomer")}
                              optionFilterProp="children"
                              allowClear={true}
                              size="medium"
                              style={{ borderRadius: "10px" }}
                              onChange={onChangeCustomerName}
                            >
                              {customers.map((customer) => (
                                <Option key={customer.id} value={customer.id}>{customer.name}</Option>
                              ))}
                            </Select>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={{ span: 12 }} >
                            <ShowText text={t("saleInvoice.customerPhone")} color="var(--primary-color)" />
                          </Col>
                          <Col md={{ span: 12 }}>
                            <Input
                              size="medium"
                              value={customerPhone}
                              onChange={(e) => setCustomerPhone(e.target.value)}
                            />
                          </Col>
                        </Row>
                        <Row >
                          <Col md={{ span: 12 }} >
                            <ShowText text={t("saleInvoice.remark")} color="var(--primary-color)" />
                          </Col>
                          <Col md={{ span: 12 }}>
                            <TextArea maxLength={100} style={{ height: 50 }} onChange={onChangeRemark} />
                          </Col>
                        </Row>
                      </Space>
                    }

                    {/* new cusotmer's input */}
                    {customerOption === "newCustomer" &&
                      <Space direction="vertical">
                        <Row>
                          <Col md={{ span: 12 }}  >
                            <ShowText text={t("saleInvoice.customerName")} color="var(--primary-color)" />
                          </Col>
                          <Col md={{ span: 12 }} >
                            <Input
                              size="medium"
                              onChange={onChangeNewCusName}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={{ span: 12 }} >
                            <ShowText text={t("saleInvoice.customerPhone")} color="var(--primary-color)" />
                          </Col>
                          <Col md={{ span: 12 }}>
                            <Input
                              size="medium"
                              onChange={onChangeNewCusPhone}
                            />
                          </Col>
                        </Row>
                        <Row >
                          <Col md={{ span: 12 }} >
                            <ShowText text={t("saleInvoice.remark")} color="var(--primary-color)" />
                          </Col>
                          <Col md={{ span: 12 }}>
                            <TextArea maxLength={100} style={{ height: 50 }} onChange={onChangeRemark} />
                          </Col>
                        </Row>
                      </Space>
                    }
                  </Space>
                </Col>

                <Col xxl={{ span: 16 }} xl={{ span: 12 }}>
                  <Space wrap="true">
                    <Space >
                      <ShowText text={t("saleInvoice.sellerName")} color="var(--primary-color)" />
                      <Select
                        showSearch
                        placeholder={t("saleInvoice.selectSeller")}
                        optionFilterProp="children"
                        allowClear={true}
                        size="medium"
                        style={{ borderRadius: "10px" }}
                        onChange={onChangeSaleMan}
                      >
                        {saleMen.map((saleMan) => (
                          <Option key={saleMan.id} value={saleMan.id}>{saleMan.name}</Option>
                        ))}

                      </Select>
                    </Space>

                    <Space>
                      <ShowText text={t("saleInvoice.cashier")} color="var(--primary-color)" />
                      <Select
                        showSearch
                        placeholder={t("saleInvoice.selectCashier")}
                        optionFilterProp="children"
                        allowClear={true}
                        size="medium"
                        style={{ borderRadius: "10px" }}
                        onChange={onChangeCashier}
                      >
                        {cashiers.map((cashier) => (
                          <Option key={cashier.id} value={cashier.id}>{cashier.name}</Option>
                        ))}

                      </Select>
                    </Space>

                    <Space >
                      <ShowText text={t("saleInvoice.paymentMethod")} color="var(--primary-color)" />
                      <Select
                        showSearch
                        placeholder={t("saleInvoice.selectPayment")}
                        optionFilterProp="children"
                        allowClear={true}
                        size="medium"
                        style={{ borderRadius: "10px" }}
                        onChange={onChangePaymentType}
                      >
                        <Option value="kbz" key="1">
                          KBZ Pay
                        </Option>
                        <Option value="aya" key="2">
                          AYA Pay
                        </Option>
                        <Option value="cb" key="3">
                          CB Pay
                        </Option>
                        <Option value="cash" key="4">
                          cash
                        </Option>

                      </Select>
                    </Space>
                  </Space>

                </Col>



              </Row>
              <Divider />

              {/* Sale item table */}
              <Table
                bordered
                columns={columns}
                pagination={{ pageSize: 10 }}
                dataSource={saleItems}
              />
              {/* vouncher info table */}
              <Row style={{ backgroundColor: "var(--white-color)" }}>
                <Col md={{ span: 12 }}></Col>
                <Col md={{ span: 12 }}>
                  <table style={borderStyle} width="100%">
                    <tr style={borderStyle} height="35px">
                      <td style={borderStyle} align="center">{t("saleInvoice.total")}</td>
                      <td style={borderStyle} align="center" colSpan="2">{saleTotal}</td>
                    </tr>
                    <tr style={borderStyle} height="35px">
                      <td style={borderStyle} align="center">{t("saleInvoice.tax")}</td>
                      <td style={borderStyle} align="center"> <InputNumber addonAfter="%" onChange={onChangeTax} style={{ width: '100%', alignContent: "center" }} /></td>
                      <td style={borderStyle} align="center" width={200}>{taxTotal}</td>
                    </tr>
                    <tr style={borderStyle} height="35px">
                      <td style={borderStyle} align="center">{t("saleInvoice.discount")}</td>
                      <td style={borderStyle} align="center"> <InputNumber addonAfter="%" onChange={onChangeDiscount} style={{ width: '100%', alignContent: "center" }} /></td>
                      <td style={borderStyle} align="center" width={200}>{discountTotal}</td>
                    </tr>
                    <tr style={borderStyle} height="35px">
                      <td style={borderStyle} align="center">{t("saleInvoice.totalAmount")}</td>
                      <td style={borderStyle} align="center" colSpan="2">{wholeTotal}</td>
                    </tr>
                    <tr style={borderStyle} height="35px">
                      <td style={borderStyle} align="center">{t("saleInvoice.paidAmount")}</td>
                      <td style={borderStyle} align="center" colSpan="2"> <InputNumber onChange={onChangePaidTotal} style={{ width: '100%' }} /></td>
                    </tr>
                    <tr style={borderStyle} height="35px">
                      <td style={borderStyle} align="center">{t("saleInvoice.creditAmount")}</td>
                      <td style={borderStyle} align="center" colSpan="2"> {creditTotal}</td>
                    </tr>
                    <tr style={borderStyle} height="40px">
                      <td style={borderStyle} align="center">{t("saleInvoice.refund")}</td>
                      <td style={borderStyle} align="center" colSpan="2">{returnedTotal}</td>
                    </tr>
                  </table>
                </Col>
              </Row>

              {/* save and print button */}
              <Row gutter={[16, 16]} style={{ padding: "20px" }} align="space-evenly">
                <Col xl={{ span: 20 }} lg={{ span: 0 }}>
                </Col>
                <Col xl={{ span: 4 }} lg={{ span: 6 }}>
                  <Space align="center">
                    <Button
                      style={{
                        backgroundColor: "var(--primary-color)",
                        color: "var(--white-color)",
                        borderRadius: "10px",
                      }}
                      size="large"
                      onClick={handleSave}
                    >
                      <SaveOutlined />
                      {t("component.save")}
                    </Button>

                  </Space>
                </Col>
              </Row>
            </Layout>
          </Content>
        </Layout >
      </Layout >
    </Spin>


  );
};


export default CreateSaleInvoice;

