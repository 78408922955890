import React, { useEffect } from "react";
import {
    Typography,
    Space,
    Table,
    Spin,
    Row,
    Col,
    Card,
    Modal,
    message

} from "antd";
import Layout from "antd/lib/layout/layout";
import Label from "../../components/Label";
import ShowText from "../../components/ShowText";
import { useSelector, useDispatch } from "react-redux";
import { confirmSupplierExchange, getSupplierExchange } from "../../store/actions";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    CheckCircleOutlined
} from "@ant-design/icons";
import MediumButton from "../../components/MediumButton";
import { useState } from "react";
import TextArea from "antd/lib/input/TextArea";
const { Title } = Typography;

const ShowSupplierExchangeDetails = () => {
    const user = useSelector((state) => state.auth.user);
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const supplierExchange = useSelector((state) => state.supplierExchange.supplierExchange);

    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [remark, setRemark] = useState();

    const params = useParams();
    const id = params.id;
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getSupplierExchange(id))
    }, [dispatch, id])

    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        if (status.success) {
            message.success(t("message.successExchangeMessage"));
        }
        dispatch(getSupplierExchange(id));
        return () => status.success;
    }, [status.success, t, dispatch, id]);


    const givenItems = supplierExchange.supplier_change_give_items;
    const takenItems = supplierExchange.supplier_change_take_items;
    const differentTotal = supplierExchange.take_total - supplierExchange.give_total;

    const transformedGivenItems = givenItems?.map((item) => {
        return {
            ...item,
            key: item.id,
        }
    })

    const transformedTakenItems = takenItems?.map((item) => {
        return {
            ...item,
            key: item.id,
        }
    })

    const columns = [
        {
            title: t("exchange.itemName"),
            dataIndex: "stock",
            render: (stock) => stock.product.name
        },
        {
            title: t("exchange.qty"),
            dataIndex: "quantity",
        },
        {
            title: t("exchange.price"),
            dataIndex: "price",
        },
        {
            title: t("exchange.subtotal"),
            dataIndex: "subtotal",
        },

    ];

    //for confirm modal
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setIsModalOpen(false);
        const values = {
            remark: remark,
            user_name: user.name
        }

        await dispatch(confirmSupplierExchange(id, values));

    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }} >
                <Space direction="vertical" >
                    <Title level={3}>
                        {t("exchange.supplierExchangeDetails")}
                    </Title>
                    <Row>
                        <Col xl={{ span: 21 }} md={{ span: 18 }} style={{ padding: "0 20px" }} justifyContent="center">
                            <Title level={5}>{t("exchange.date")} : {supplierExchange.date} </Title>
                        </Col>
                        <Col xl={{ span: 3 }} md={{ span: 6 }}>
                            {
                                (supplierExchange.is_confirm === 0) && <MediumButton icon={<CheckCircleOutlined />} text={t("exchange.confirm")} bgColor="var(--green-color)" onClick={showModal} />
                            }
                        </Col>

                    </Row>

                    <Row >
                        <Col xl={{ span: 12 }} md={{ span: 24 }} style={{ padding: "10px" }}>
                            <Card style={{ borderRadius: "8px" }}>
                                <Space direction="vertical" style={{ width: "100%" }}>
                                    <Title level={5} align="center" style={{ margin: "20px" }}>{t("exchange.itemToMerchant")}</Title>
                                    <Table
                                        bordered
                                        columns={columns}
                                        pagination={{ position: ["none", "none"] }}
                                        dataSource={transformedGivenItems}
                                        style={{ minHeight: "380px" }}
                                    />
                                    <Space
                                        direction="horizontal"
                                        style={{ width: "93%", justifyContent: "end", margin: "20px" }}
                                    >
                                        <Label text={t("exchange.total")} />
                                        <ShowText text={supplierExchange.give_total} />
                                    </Space>
                                </Space>
                            </Card>
                        </Col>
                        <Col xl={{ span: 12 }} md={{ span: 24 }} style={{ padding: "10px" }}>
                            <Card style={{ borderRadius: "8px" }}>
                                <Space direction="vertical" style={{ width: "100%" }}>
                                    <Title level={5} align="center" style={{ margin: "20px" }}>{t("exchange.itemFromMerchant")} </Title>
                                    <Table
                                        bordered
                                        columns={columns}
                                        pagination={{ position: ["none", "none"] }}
                                        dataSource={transformedTakenItems}
                                        style={{ minHeight: "380px" }}

                                    />
                                    <Space
                                        direction="horizontal"
                                        style={{ width: "93%", justifyContent: "end", margin: "20px" }}
                                    >
                                        <Label text={t("exchange.total")} />
                                        <ShowText text={supplierExchange.take_total} />
                                    </Space>
                                </Space>
                            </Card>
                        </Col>
                    </Row>


                    {/* Total status row */}
                    <Row align="end" >
                        <Col xl={{ span: 17 }} style={{ padding: "10px 20px" }}>
                            <Title level={5}>{t("exchange.remark")} : {supplierExchange.remark} </Title>
                            <Title level={5}>{t("exchange.user")} : {supplierExchange.user_name} </Title>
                        </Col>
                        <Col xl={{ span: 7 }}>
                            <Card style={{ borderRadius: "10px", padding: "20px" }}>
                                <Space
                                    direction="horizontal"
                                    style={{ width: "100%", justifyContent: "center" }}
                                >
                                    <Label text={t("exchange.difference")} />
                                    <ShowText text={differentTotal} />
                                </Space>
                            </Card>
                        </Col>

                    </Row>
                </Space>

            </Layout>
            <Modal title={t('exchange.writeRemark')} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} centered>
                <TextArea maxLength={100} style={{ height: 120 }} onChange={(e) => setRemark(e.target.value)} />
            </Modal>
        </Spin>

    );
};


export default ShowSupplierExchangeDetails;
