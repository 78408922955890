import React, { useEffect } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Table,
  Spin,
  Popconfirm,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
  EditOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
  AlignLeftOutlined
} from "@ant-design/icons";
import IconButton from "../../components/IconButton";
import MediumButton from "../../components/MediumButton";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteStaff, getStaffs } from "../../store/actions";
import ExportToExcel from "../../components/ExportToExcel";
import {useTranslation} from "react-i18next";


const { Title } = Typography;

const ShowStaffs = () => {
  const navigate = useNavigate();
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const staffs = useSelector((state) => state.staff.staffs);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(getStaffs());
  }, [dispatch]);

  useEffect(() => {
    error.message !== null && message.error(error.message);
    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(t("message.successDeleteMessage"));
    }
    return () => status.success;
  }, [status.success, t]);


  const handleDelete = async (id) => {
    await dispatch(deleteStaff(id));
  }

  //for excel export
  const fileName = "Staffs";
  const transformedData = staffs.map((staff) => {
    return {
      Code: staff.code,
      Name: staff.name,
      DateOfBirth: staff.dob,
      NRC: staff.nrc,
      StartDate: staff.start_job,
      DepartureDate: staff.end_job,
      PhoneNumber: staff.phone,
      Address: staff.address,
      Position: staff.position,
      Salary: staff.salary,
    };
  });

  const columns = [
    {
      title: t("staff.image"),
      dataIndex: "image",
      render: (image) => (
        <img src={image} alt="ပစ္စည်းပုံ" width={100} height={100} />
      ),
    },
    {
      title: t("staff.code"),
      dataIndex: "code",
    },
    {
      title: t("staff.name"),
      dataIndex: "name",
    },
    {
      title: t("staff.startDate"),
      dataIndex: "start_job",
    },
    {
      title: t("staff.position"),
      dataIndex: "position",
    },
    {
      title: t("staff.salary"),
      dataIndex: "salary",
    },
    {
      title: t("component.actions"),
      dataIndex: "id",
      render: (id) => (
        <Space direction="horizontal">

          <IconButton icon={<AlignLeftOutlined />} onClick={() => { navigate(`/admin/show-staffs/${id}`) }} bgColor="var(--primary-color)"> </IconButton>
          <IconButton icon={<EditOutlined />} onClick={() => { navigate(`/admin/edit-staff/${id}`) }} bgColor="var(--green-color)"> </IconButton>
          <Popconfirm
            title={t("component.sureDelete")}
            okText="Yes"
            cancelText="No"
            onConfirm={() => { handleDelete(id) }}
          >
            <IconButton icon={<DeleteOutlined />} onClick={() => { }} bgColor="var(--red-color)"> </IconButton>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 18 }} md={{ span: 12 }}>
              <Title level={3}>{t("staff.staffList")}</Title>
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 6 }}>
              <MediumButton icon={<PlusSquareOutlined />} text={t("component.addNew")} bgColor="var(--primary-color)" onClick={() => { navigate("/admin/create-staff") }} />
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 6 }}>
              <ExportToExcel apiData={transformedData} fileName={fileName}/>
            </Col>
          </Row>

          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={staffs}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

export default ShowStaffs;
