import React, { useEffect } from "react";
import {
    Typography,
    Space,
    Row,
    Col,
    Table,
    Spin,
    Popconfirm,
    DatePicker,
    Select,
    message
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
    EditOutlined,
    DeleteOutlined,
    PlusSquareOutlined,
} from "@ant-design/icons";
import IconButton from "../../components/IconButton";
import MediumButton from "../../components/MediumButton";
import Label from "../../components/Label";
import ShowText from "../../components/ShowText";
import { useSelector, useDispatch } from "react-redux";
import { deleteSalaryAndBenefit, getSalaryAndBenefits } from "../../store/actions/salaryAndBenefit";
import { getReadableDateDisplay } from "../../utils/convertToHumanReadableTime";
import { successDeleteMessage } from "../../utils/messages";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getStaffs } from "../../store/actions";
import { useState } from "react";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ShowSalaries = () => {
    const navigate = useNavigate();
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const staffs = useSelector((state) => state.staff.staffs);

    const salaryAndBenefits = useSelector((state) => state.salaryAndBenefit.salaryAndBenefits);
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();  //get url's query parameters

    const [filteredSalaryAndBenefits, setFilteredSalaryAndBenefits] = useState([]);
    const [totalSalary, setTotalSalary] = useState(0);
    const { t } = useTranslation();


    useEffect(() => {
        dispatch(getSalaryAndBenefits(searchParams));
    }, [dispatch, searchParams])

    useEffect(() => {
        dispatch(getStaffs());
    }, [dispatch])

    useEffect(() => {
        let total = 0;
        salaryAndBenefits.map((salaryAndBenefit) => {
            total = total + salaryAndBenefit.total;
            return null;
        });
        setTotalSalary(total);
    }, [salaryAndBenefits])

    useEffect(() => {
        setFilteredSalaryAndBenefits(salaryAndBenefits);
    }, [salaryAndBenefits]);

    const handleDelete = async (id) => {
        await dispatch(deleteSalaryAndBenefit(id));
    }

    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        if (status.success) {
            message.success(successDeleteMessage);
            message.success(t("message.successDeleteMessage"));
        }
        return () => status.success;
    }, [status.success, t]);

    const onChange = (value) => {
        if (value === undefined) {
            setFilteredSalaryAndBenefits(salaryAndBenefits);
        } else {
            const data = salaryAndBenefits.filter((salary) => (salary.staff.id === value));
            setFilteredSalaryAndBenefits(data);
        }
    }
    //for excel export
    const fileName = "Salaries";
    const transformedData = filteredSalaryAndBenefits.map((salary) => {
        return {
            Date: getReadableDateDisplay(salary.created_at),
            Name: salary.staff.name,
            Positon: salary.staff.position,
            Salary: salary.staff.salary,
            TotalSalary: salary.total,
        };
    });

    const columns = [
        {
            title: t("salary.date"),
            render: (record) => {
                let payDate = `${record.year}-${record.month}`;
                return payDate;
            }
        },
        {
            title: t("salary.staffName"),
            dataIndex: "staff",
            render: (staff) => staff.name,
        },
        {
            title: t("salary.position"),
            dataIndex: "staff",
            render: (staff) => staff.position,
        },
        {
            title: t("salary.salary"),
            dataIndex: "staff",
            render: (staff) => staff.salary,
        },
        {
            title: t("salary.totalSalary"),
            dataIndex: "total",
        },

        {
            title: t("component.actions"),
            dataIndex: "id",
            render: (id) => (
                <Space direction="horizontal">
                    <IconButton icon={<EditOutlined />} onClick={() => { navigate(`/admin/edit-salaries/${id}`) }} bgColor="var(--green-color)"> </IconButton>
                    <Popconfirm
                        title={t("component.sureDelete")}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => { handleDelete(id) }}
                    >
                        <IconButton icon={<DeleteOutlined />} onClick={() => { }} bgColor="var(--red-color)"> </IconButton>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }}>
                <Space direction="vertical" size="middle">
                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 18 }} md={{ span: 12 }}>
                            <Title level={3}>{t("salary.salaryList")}</Title>
                        </Col>
                        <Col xl={{ span: 3 }} md={{ span: 6 }}>
                            <MediumButton icon={<PlusSquareOutlined />} text={t("component.addNew")} bgColor="var(--primary-color)" onClick={() => { navigate("/admin/create-salary") }} />
                        </Col>
                        <Col xl={{ span: 3 }} md={{ span: 6 }}>
                            <ExportToExcel apiData={transformedData} fileName={fileName} />
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 8 }} md={{ span: 24 }}>
                            <RangePicker
                                onChange={(val) => {
                                    if (val === null) {
                                        navigate(`/admin/show-salaries`)
                                    }
                                    else {
                                        navigate(`/admin/show-salaries?start_date=${dayjs(
                                            val[0]
                                        ).format("YYYY-MM-DD")}&end_date=${dayjs(val[1]).format(
                                            "YYYY-MM-DD"
                                        )}`)
                                    }
                                }}
                            />
                        </Col>
                        <Col xl={{ span: 8 }} md={{ span: 12 }}>
                            <Label text={t("salary.staffName")} />
                            <Select
                                showSearch
                                placeholder={t("salary.selectStaff")}
                                optionFilterProp="children"
                                allowClear={true}
                                size="medium"
                                style={{ borderRadius: "10px" }}
                                onChange={onChange}
                            >
                                {staffs.map((staff) => (
                                    <Option key={staff.id} value={staff.id}>{staff.name}</Option>
                                ))}

                            </Select>
                        </Col>
                        <Col xl={{ span: 8 }} md={{ span: 12 }}>
                            <Label text={t("salary.totalSalary")} />
                            <ShowText text={totalSalary} />
                        </Col>
                    </Row>
                    <Table
                        bordered
                        columns={columns}
                        pagination={{ defaultPageSize: 10 }}
                        dataSource={filteredSalaryAndBenefits}
                    />
                </Space>
            </Layout>
        </Spin>
    );
};

export default ShowSalaries;
