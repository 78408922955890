import { DELETE_SUPPLIER_EXCHANGE, SET_SUPPLIER_EXCHANGE, SET_SUPPLIER_EXCHANGES } from "../type";

  const initialState = {
    supplierExchanges: [],
    supplierExchange: {},
  };
  
  const supplierExchange = (state = initialState, action) => {
    switch (action.type) {
      case SET_SUPPLIER_EXCHANGE:
        return {
          ...state,
          supplierExchange: action.payload,
        };
      case SET_SUPPLIER_EXCHANGES:
        return {
          ...state,
          supplierExchanges: action.payload,
        };
      case DELETE_SUPPLIER_EXCHANGE:
        return {
          ...state,
          supplierExchanges: state.supplierExchanges.filter((exchange) => exchange.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default supplierExchange;
  