import React, { useEffect } from "react";
import {
    Typography,
    Space,
    Row,
    Col,
    Table,
    Spin,
    DatePicker,
    Select,
    Checkbox,
} from "antd";
import Layout from "antd/lib/layout/layout";
import Label from "../../components/Label";
import { useSelector, useDispatch } from "react-redux";
import { getBestSellerItems, getItems, getSaleItems } from "../../store/actions/item";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from "react-i18next";
import { getCategories } from "../../store/actions/category";

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ShowSaleItems = () => {
    const status = useSelector((state) => state.status);
    const saleItems = useSelector((state) => state.item.saleItems);
    const bestSellerItems = useSelector((state) => state.item.bestSellerItems);
    const items = useSelector((state) => state.item.items);
    const categories = useSelector((state) => state.category.categories);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [showBestSellerItems, setShowBestSellerItems] = useState(false);
    const [filteredSaleItems, setFilteredSaleItems] = useState([]);
    const [filteredBestSellerItems, setFilteredBestSellerItems] = useState([]);
    const { t } = useTranslation();

    const [item, setItem] = useState();
    const [category, setCategory] = useState();

    useEffect(() => {
        dispatch(getSaleItems(searchParams));
        dispatch(getBestSellerItems(searchParams));
        dispatch(getItems());
        dispatch(getCategories());

    }, [dispatch, searchParams])

    useEffect(() => {
        setFilteredSaleItems(saleItems);
        setFilteredBestSellerItems(bestSellerItems);
    }, [saleItems, bestSellerItems])

    const onChange = (e) => {
        setShowBestSellerItems(e.target.checked);
    };

    useEffect(() => {
        if (item === undefined && category === undefined) {
            setFilteredSaleItems(saleItems);
            setFilteredBestSellerItems(bestSellerItems);
        }
        else if (item === undefined) {
            const filteredSaleItems = saleItems.filter((saleItem) => saleItem.product.category.name === category);
            setFilteredSaleItems(filteredSaleItems);

            const filteredBestSellerItems = bestSellerItems.filter((bestItem) => bestItem.product.category.name === category);
            setFilteredBestSellerItems(filteredBestSellerItems);
        }
        else if (category === undefined) {
            const filteredSaleItems = saleItems.filter((saleItem) => saleItem.product.name === item);
            setFilteredSaleItems(filteredSaleItems);

            const filteredBestSellerItems = bestSellerItems.filter((bestItem) => bestItem.product.name === item);
            setFilteredBestSellerItems(filteredBestSellerItems);
        }
        else {
            const filteredSaleItems = saleItems.filter((saleItem) => saleItem.product.name === item && saleItem.product.category.name === category);
            setFilteredSaleItems(filteredSaleItems);

            const filteredBestSellerItems = bestSellerItems.filter((bestItem) => bestItem.product.name === item && bestItem.product.category.name === category);
            setFilteredBestSellerItems(filteredBestSellerItems);
        }

    }, [item, category, saleItems, bestSellerItems])


    const onChangeItemName = (value) => {
        if (value === undefined) {
            setItem(undefined);
        }
        else {
            setItem(value);
        }
    }

    const onChangeCategory = (value) => {
        if (value === undefined) {
            setCategory(undefined);
        }
        else {
            setCategory(value);
        }
    }
    //for excel export
    const fileName = showBestSellerItems ? "BestSellerItems" : "SaleItems";
    const transformedSaleItems = filteredSaleItems.map((saleItem) => {
        return {
            No: saleItem.no,
            Date: saleItem.day,
            ItemName: saleItem.product.name,
            Quantity: saleItem.quantity,
            SubTotal: saleItem.subtotal,
        };
    });

    const transformedBestSellerItems = filteredBestSellerItems.map((item) => {
        return {
            No: item.no,
            ItemName: item.product.name,
            Quantity: item.quantity,
            SubTotal: item.subtotal,
        };
    });

    const transformedData = showBestSellerItems ? transformedBestSellerItems : transformedSaleItems;

    const saleItemsColumns = [
        {
            title: t("saleItem.no"),
            dataIndex: "no",
        },
        {
            title: t("saleItem.date"),
            dataIndex: "day",
        },
        {
            title: t("saleItem.category"),
            dataIndex: "product",
            render: (product) => product.category.name
        },
        {
            title: t("saleItem.itemName"),
            dataIndex: "product",
            render: (product) => product.name
        },
        {
            title: t("saleItem.qty"),
            dataIndex: "quantity",
            sorter: {
                compare: (a, b) => a.quantity - b.quantity,
                multiple: 2,
            },
        },
        {
            title: t("saleItem.totalAmount"),
            dataIndex: "subtotal",
            sorter: {
                compare: (a, b) => a.subtotal - b.subtotal,
                multiple: 1,
            },
        },

    ];

    const bestSellerItemsColumns = [
        {
            title: t("saleItem.no"),
            dataIndex: "no",
        },
        {
            title: t("saleItem.itemName"),
            dataIndex: "product",
            render: (product) => product.name
        },
        {
            title: t("saleItem.category"),
            dataIndex: "product",
            render: (product) => product.category.name
        },
        {
            title: t("saleItem.qty"),
            dataIndex: "quantity",
            sorter: {
                compare: (a, b) => a.quantity - b.quantity,
                multiple: 2,
            },
        },
        {
            title: t("saleItem.totalAmount"),
            dataIndex: "subtotal",
            sorter: {
                compare: (a, b) => a.subtotal - b.subtotal,
                multiple: 1,
            },
        },
    ];

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }}>
                <Space direction="vertical" size="middle">
                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 21 }} md={{ span: 18 }}>
                            <Title level={3}>{t("saleItem.saleItemList")}</Title>
                        </Col>
                        <Col xl={{ span: 3 }} md={{ span: 6 }}>
                            <ExportToExcel apiData={transformedData} fileName={fileName} />
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 6 }} md={{ span: 24 }}>
                            <RangePicker
                                onChange={(val) => {
                                    if (val === null) {
                                        navigate(`/admin/show-sale-items`)
                                    }
                                    else {
                                        navigate(`/admin/show-sale-items?start_date=${dayjs(
                                            val[0]
                                        ).format("YYYY-MM-DD")}&end_date=${dayjs(val[1]).format(
                                            "YYYY-MM-DD"
                                        )}`)
                                    }
                                }}
                            />
                        </Col>
                        <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <Label text={t("saleItem.category")} />
                            <Select
                                showSearch
                                placeholder={t("saleItem.selectCategory")}
                                optionFilterProp="children"
                                allowClear={true}
                                size="medium"
                                style={{ borderRadius: "10px" }}
                                onChange={onChangeCategory}
                            >
                                {categories.map((category) => (
                                    <Option key={category.id} value={category.name}>{category.name}</Option>
                                ))}

                            </Select>
                        </Col>
                        <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <Label text={t("saleItem.itemName")} />
                            <Select
                                showSearch
                                placeholder={t("saleItem.selectItem")}
                                optionFilterProp="children"
                                allowClear={true}
                                size="medium"
                                style={{ borderRadius: "10px" }}
                                onChange={onChangeItemName}
                            >
                                {items.map((item) => (
                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                ))}

                            </Select>
                        </Col>
                        <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <Checkbox onChange={onChange} style={{ border: "1px solid var(--primary-color)", padding: "5px", borderRadius: "5px", background: "var(--primary-color)", color: "var(--white-color)" }}>{t("saleItem.showBestSellerItems")}</Checkbox>
                        </Col>
                    </Row>

                    {!showBestSellerItems &&
                        <Table
                            bordered
                            columns={saleItemsColumns}
                            pagination={{ defaultPageSize: 10 }}
                            dataSource={filteredSaleItems}
                        />
                    }
                    {showBestSellerItems &&
                        <Space direction="vertical" style={{ width: "100%", justifyContent: "right", marginTop: "20px" }}>
                            <Title level={4}>{t("saleItem.bestSellerItems")}</Title>
                            <Table
                                bordered
                                columns={bestSellerItemsColumns}
                                pagination={{ defaultPageSize: 10 }}
                                dataSource={filteredBestSellerItems}
                            />
                        </Space>
                    }
                </Space>
            </Layout>
        </Spin>
    );
};

export default ShowSaleItems;
