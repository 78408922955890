import { DELETE_PURCHASE_INVOICE, SET_PURCHASE_INVOICE, SET_PURCHASE_INVOICES, SET_PURCHASE_REPORTS } from "../type";

const initialState = {
    purchaseInvoices: [],
    purchaseInvoice: {},
    purchaseReports: []
};

const purchase = (state = initialState, action) => {
    switch (action.type) {
        case SET_PURCHASE_INVOICE:
            return {
                ...state,
                purchaseInvoice: action.payload,
            };
        case SET_PURCHASE_INVOICES:
            return {
                ...state,
                purchaseInvoices: action.payload,
            };
        case DELETE_PURCHASE_INVOICE:
            return {
                ...state,
                purchaseInvoices: state.purchaseInvoices.filter((purchaseInvoice) => purchaseInvoice.id !== action.payload),
            };
        case SET_PURCHASE_REPORTS:
            return {
                ...state,
                purchaseReports: action.payload,
            };
        default:
            return state;
    }
};

export default purchase;
