import { call } from "../../services/api";
import { serverErrorMessage } from "../../utils/messages";
import { ADD_ERROR, DELETE_SALARY_AND_BENEFIT, REMOVE_ERROR, SET_LOADING, SET_SALARY_AND_BENEFIT, SET_SALARY_AND_BENEFITS, SET_SUCCESS } from "../type";

export const getSalaryAndBenefits = (searchParams) => {
   
    return async (dispatch) => {
        dispatch({ type: SET_LOADING });
        try {
            const response = await call("get",`salary-and-benefits?${searchParams}`);
            const result = response.data;
            
            const transformResult = result.map((data) => {
                return {
                    ...data,
                    key: data.id,
                };
            });
            dispatch({
                type: SET_SALARY_AND_BENEFITS,
                payload: transformResult,
            });
            dispatch({
                type: REMOVE_ERROR,
            });
        } catch (error) {
            const { status, data } = error.response;

            if (status === 401) {
                localStorage.removeItem("jwtToken");
                dispatch({
                    type: ADD_ERROR,
                    payload: data.message,
                });
            }

            if (status === 500) {
                dispatch({
                    type: ADD_ERROR,
                    payload: serverErrorMessage,
                });
            }
        }
        dispatch({ type: SET_LOADING });
    };
};

export const deleteSalaryAndBenefit = (id) => {
    return async (dispatch) => {
        dispatch({ type: SET_SUCCESS, payload: false });
        dispatch({ type: SET_LOADING });
        try {
            await call("delete", `salary-and-benefits/${id}`);

            dispatch({ type: SET_SUCCESS, payload: true });
            dispatch({ type: DELETE_SALARY_AND_BENEFIT, payload: id });
            dispatch({
                type: REMOVE_ERROR,
            });
        } catch (error) {
            const { status, data } = error.response;

            if (status === 401) {
                localStorage.removeItem("jwtToken");
                dispatch({
                    type: ADD_ERROR,
                    payload: data.message,
                });
            }

            if (status === 500) {
                dispatch({
                    type: ADD_ERROR,
                    payload: serverErrorMessage,
                });
            }
        }
        setTimeout(() => {
            dispatch({ type: SET_SUCCESS, payload: false });
        }, 1);
        dispatch({ type: SET_LOADING });
    };
};


export const createSalaryAndBenefit = (data) => {
    return async (dispatch) => {
      dispatch({ type: SET_SUCCESS, payload: false });
      dispatch({ type: SET_LOADING });
      try {
        await call("post", "salary-and-benefits", data);
  
        dispatch({ type: SET_SUCCESS, payload: true });
        dispatch({
          type: REMOVE_ERROR,
        });
      } catch (error) {
        const { status, data } = error.response;
  
        if (status === 401) {
          localStorage.removeItem("jwtToken");
          dispatch({
            type: ADD_ERROR,
            payload: data.message,
          });
        }
  
        if (status === 500) {
          dispatch({
            type: ADD_ERROR,
            payload: serverErrorMessage,
          });
        }
      }
      setTimeout(() => {
        dispatch({ type: SET_SUCCESS, payload: false });
      }, 1);
      dispatch({ type: SET_LOADING });
    };
  };

  export const getSalaryAndBenefit = (id) => {
    return async (dispatch) => {
      dispatch({ type: SET_LOADING });
      try {
        const response = await call("get", `salary-and-benefits/${id}`);
        const result = response.data;
  
        dispatch({
          type: SET_SALARY_AND_BENEFIT,
          payload: result,
        });
        dispatch({
          type: REMOVE_ERROR,
        });
      } catch (error) {
        const { status, data } = error.response;
  
        if (status === 401) {
          localStorage.removeItem("jwtToken");
          dispatch({
            type: ADD_ERROR,
            payload: data.message,
          });
        }
  
        if (status === 500) {
          dispatch({
            type: ADD_ERROR,
            payload: serverErrorMessage,
          });
        }
      }
      dispatch({ type: SET_LOADING });
    };
  };

  
export const editSalaryAndBenefit = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    dispatch({ type: SET_LOADING });
    try {
      await call("post", `salary-and-benefits/${id}?_method=PUT`, data);

      dispatch({ type: SET_SUCCESS, payload: true });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: serverErrorMessage,
        });
      }

     else{
      dispatch({
        type: ADD_ERROR,
        payload: data.message,
      });
     }
    }
    setTimeout(() => {
      dispatch({ type: SET_SUCCESS, payload: false });
    }, 1);
    dispatch({ type: SET_LOADING });
  };
};
  
