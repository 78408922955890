import React from "react";
import {
    Typography,
    Space,
    Row,
    Col,
    Table,
    Image,
} from "antd";

import ShowText from "../../components/ShowText";
import { getReadableDateDisplay } from "../../utils/convertToHumanReadableTime";
import logo from "../../assets/images/logo.jpg";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const InvoiceTemplate = ({ saleInvoice, saleItems, isLast }) => {

    const { t } = useTranslation();
    const columns = [
        {
            title: t("saleInvoice.no"),
            dataIndex: "no",
        },
        {
            title: t("saleInvoice.itemCode"),
            dataIndex: "stock",
            render: (stock) => stock.product.code
        },
        {
            title: t("saleInvoice.itemName"),
            dataIndex: "stock",
            render: (stock) => stock.product.name
        },
        {
            title: t("saleInvoice.price"),
            dataIndex: "price",
            align: "right",
        },
        {
            title: t("saleInvoice.quantity"),
            dataIndex: "quantity",
            align: "right",
        },
        {
            title: t("saleInvoice.subTotal"),
            dataIndex: "subtotal",
            align: "right",
        },

    ];


    return (

        <Space direction="vertical" style={{ width: "98%", margin: "1%" }}>
            <Row style={{ justifyContent: "center", alignItems: "center" }}>
                <Col xl={{ span: 4 }} xs={{ span: 6 }} align="center">
                    <Image width={150} src={logo} />
                </Col>
                <Col xl={{ span: 12 }} xs={{ span: 18 }} style={{ padding: "15px" }}>
                    <Space direction="vertical" >
                        <Title level={3}>{t("saleInvoice.doctorGyi")}</Title>
                        <Row>
                            <Col xs={{ span: 12 }}>
                                <Text style={{ fontSize: "16px" }}>{t("saleInvoice.doctor1")}</Text>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <Text style={{ fontSize: "16px" }}>{t("saleInvoice.doctor1Phone")}</Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 12 }}>
                                <Text style={{ fontSize: "16px" }}>{t("saleInvoice.doctor2")}</Text>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <Text style={{ fontSize: "16px" }}>{t("saleInvoice.doctor2Phone")}</Text>
                            </Col>
                        </Row>

                        <Text style={{ fontSize: "16px" }}>{t("saleInvoice.address")}</Text>
                    </Space>
                </Col>
            </Row>

            <Space direction="vertical" style={{ border: "0px solid var(--info-color)", width: "100%" }} >
                <Row style={{ backgroundColor: "var(--label-color)" }} align="center">
                    <Col >
                        <Title level={3} style={{ color: "var(--white-color)" }} >{t("saleInvoice.invoice")}</Title>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col xxl={{ span: 7 }} xl={{ span: 10 }}>
                        <Row justify="center">
                            <Col xl={{ span: 10 }}>
                                <Space direction="vertical">
                                    <ShowText text={t("saleInvoice.date")} />
                                    <ShowText text={t("saleInvoice.invoiceCode")} />
                                    <ShowText text={t("saleInvoice.cashier")} />
                                </Space>
                            </Col>
                            <Col xl={{ span: 10 }}>
                                <Space direction="vertical">
                                    <ShowText text={getReadableDateDisplay(saleInvoice?.created_at)} />
                                    <ShowText text={saleInvoice?.voucher_code} />
                                    <ShowText text={saleInvoice?.cashier?.name} />
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl={{ span: 7 }} xl={{ span: 0 }}></Col>
                    <Col xxl={{ span: 7 }} xl={{ span: 10 }}>
                        <Row justify="center">
                            <Col xl={{ span: 10 }}>
                                <Space direction="vertical">
                                    <ShowText text={t("saleInvoice.customerName")} />
                                    <ShowText text={t("saleInvoice.customerPhone")} />
                                    <ShowText text={t("saleInvoice.paymentMethod")} />
                                </Space>
                            </Col>
                            <Col xl={{ span: 10 }}>
                                <Space direction="vertical">
                                    <ShowText text={saleInvoice?.customer_name} />
                                    <ShowText text={saleInvoice?.customer_phone_no} />
                                    <ShowText text={saleInvoice?.payment_method} />
                                </Space>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Table
                    bordered
                    columns={columns}
                    pagination={{ position: ["none", "none"] }}
                    dataSource={saleItems}
                    style={{ margin: "5px" }}
                    size="small"
                />
                <Row justify="end">
                    <Col xl={{ span: 12 }} md={{ span: 12 }}>
                        <Space direction="vertical">
                            <Row style={{ margin: "50px 20px 20px 20px", width: "400px" }}>
                                <Col xl={{ span: 12 }} >
                                    <Space direction="vertical">
                                        <ShowText text={t("saleInvoice.signature")} />
                                        <ShowText text={t("saleInvoice.name")} />
                                        <ShowText text={t("saleInvoice.phone")} />
                                    </Space>
                                </Col>
                                <Col xl={{ span: 12 }}>
                                    <Space direction="vertical">
                                        <ShowText text="----------------------------" />
                                        <ShowText text="----------------------------" />
                                        <ShowText text="----------------------------" />
                                    </Space>
                                </Col>
                            </Row>
                            <Row style={{ margin: "20px", width: "400px" }}>
                                <Col xl={{ span: 12 }}>
                                    <Space direction="vertical">
                                        <ShowText text="Signature" />
                                        <ShowText text={t("saleInvoice.name")} />
                                        <ShowText text={t("saleInvoice.phone")} />
                                    </Space>
                                </Col>
                                <Col xl={{ span: 12 }}>
                                    <Space direction="vertical">
                                        <ShowText text="----------------------------" />
                                        <ShowText text="----------------------------" />
                                        <ShowText text="----------------------------" />
                                    </Space>
                                </Col>
                            </Row>
                        </Space>
                    </Col>


                    <Col xl={{ span: 4 }} md={{ span: 6 }} >
                        <Space direction="vertical">
                            <ShowText text={t("saleInvoice.total")} />
                            {saleInvoice?.tax !== "0" && <ShowText text={t("saleInvoice.tax")} />}
                            {saleInvoice?.discount !== "0" && <ShowText text={t("saleInvoice.discount")} />}
                            <ShowText text={t("saleInvoice.totalAmount")} />
                            <ShowText text={t("saleInvoice.paidAmount")} />
                            <ShowText text={t("saleInvoice.creditAmount")} />
                            {saleInvoice?.payment_method !== "" && <ShowText text={t("saleInvoice.paymentMethod")} />}
                            <ShowText text={t("saleInvoice.refund")} />
                            <ShowText text={t("saleInvoice.sellerName")} />
                            <ShowText text={t("saleInvoice.cashier")} />
                            <ShowText text={t("saleInvoice.type")} />
                        </Space>
                    </Col>
                    {
                        isLast &&
                        <Col xl={{ span: 4 }} md={{ span: 6 }} align="right" style={{ paddingRight: "15px" }}>
                            <Space direction="vertical">
                                <ShowText text={saleInvoice?.total} />
                                {saleInvoice?.tax !== "0" && <ShowText text={`${saleInvoice?.tax} %`} />}
                                {saleInvoice?.discount !== "0" && <ShowText text={`${saleInvoice?.discount} %`} />}
                                <ShowText text={saleInvoice?.final_total} />
                                <ShowText text={saleInvoice?.paid} />
                                <ShowText text={saleInvoice?.credit} />
                                {saleInvoice?.payment_method !== "" && <ShowText text={saleInvoice?.payment_method} />}
                                <ShowText text={saleInvoice?.change} />
                                <ShowText text={saleInvoice?.sale_man?.name} />
                                <ShowText text={saleInvoice?.cashier?.name} />
                                <ShowText text={saleInvoice?.type} />
                            </Space>
                        </Col>
                    }
                    {
                        !isLast &&
                        <Col xl={{ span: 4 }} md={{ span: 6 }} align="right" style={{ paddingRight: "15px" }}>
                            <Space direction="vertical">
                                <ShowText text="-" />
                                {saleInvoice?.tax !== "0" && <ShowText text="-" />}
                                {saleInvoice?.discount !== "0" && <ShowText text="-" />}
                                <ShowText text="-" />
                                <ShowText text="-" />
                                <ShowText text="-" />
                                {saleInvoice?.payment_method !== "" && <ShowText text="-" />}
                                <ShowText text="-" />
                                <ShowText text="-" />
                                <ShowText text="-" />
                                <ShowText text="-" />
                            </Space>
                        </Col>
                    }


                </Row>
            </Space>
        </Space>
    );
};

export default InvoiceTemplate;
