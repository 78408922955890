import React, { useEffect } from "react";
import {
    Form,
    Typography,
    Space,
    Spin,
    Row,
    Col,
    Input,
    message
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
    SaveOutlined,
} from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { editExpense, getExpense } from "../../store/actions";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const EditExpense = () => {
    const [form] = Form.useForm();
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const expense = useSelector((state) => state.expense.expense);
    const dispatch = useDispatch();
    const params = useParams();
    const id = params.id;
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getExpense(id));
    }, [dispatch, id])

    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        if (status.success) {
            message.success(t("message.successEditMessage"));
        }

        return () => status.success;
    }, [form, status.success, t]);

    useEffect(() => {
        form.setFieldsValue({ name: expense.name });
        form.setFieldsValue({ amount: expense.amount });
    }, [form, expense])

    const onFinish = async (values) => {
        await dispatch(editExpense(id, values));
    }
    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }} >
                <Space direction="vertical" >
                    <Title level={3}>{t("expense.editExpense")}</Title>
                    {/* Form data */}
                    <Row >
                        <Col xl={{ span: 12 }} >
                            <Form
                                colon={false}
                                labelCol={{
                                    xl: {
                                        span: 8,
                                    },
                                }}
                                wrapperCol={{
                                    xl: {
                                        span: 24,
                                    },
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                form={form}
                                layout={"vertical"}
                            >
                                <Form.Item
                                    name="name"
                                    label={t("expense.expenseName")}
                                >

                                    <Input
                                        placeholder={t("expense.selectExpenseName")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                        disabled={true}
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="amount"
                                    label={t("expense.amount")}
                                    rules={[
                                        {
                                          required: true,
                                          message: t("expense.pleaseEnterAmount"),
                                        },
                                      ]}
                                >

                                    <Input
                                        placeholder={t("expense.enterAmount")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />


                                </Form.Item>

                                <Form.Item style={{ textAlign: "right" }}>
                                    <LargeButton icon={<SaveOutlined />} text={t("component.save")} />
                                </Form.Item>
                            </Form>
                        </Col>

                        <Col>
                        </Col>
                    </Row>
                </Space>

            </Layout>
        </Spin>
    );
};


export default EditExpense;
