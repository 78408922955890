import React, { useEffect } from "react";
import {
    Typography,
    Space,
    Row,
    Col,
    Table,
    Spin,
    DatePicker,
    Popconfirm,
    Select,
    message,
    Radio
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
    DeleteOutlined,
    AlignLeftOutlined
} from "@ant-design/icons";
import Label from "../../components/Label";
import IconButton from "../../components/IconButton";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteSaleInvoice, getSaleInvoices, getStaffs } from "../../store/actions";
import { getReadableDateDisplay } from "../../utils/convertToHumanReadableTime";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { cod, credit } from "../../utils/invoiceType";
import { useState } from "react";
import { getCustomers } from "../../store/actions/customer";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from "react-i18next";
import { cashier, manager, owner, saleman } from "../../utils/position";
import ShowText from "../../components/ShowText";

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ShowSaleInvoices = () => {
    const navigate = useNavigate();
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const saleInvoices = useSelector((state) => state.saleInvoice.saleInvoices);
    const customers = useSelector((state) => state.customer.customers);
    const staffs = useSelector((state) => state.staff.staffs);
    const user = useSelector((state) => state.auth.user);

    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const [filterOption, setFilterOption] = useState("customerName");
    const [type, setType] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [salemen, setSalemen] = useState([]);
    const [salemanId, setSalemanId] = useState('');
    const [totalSaleAmount, setTotalSaleAmount] = useState(0);
    const { t } = useTranslation();

    const isManager = user.positon === manager;
    const isCashier = user.positon === cashier;
    const isOwner = user.positon === owner;


    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        if (status.success) {
            message.success(t("message.successDeleteMessage"));
        }
        return () => status.success;
    }, [status.success, t]);

    useEffect(() => {
        dispatch(getSaleInvoices(searchParams));
        dispatch(getCustomers());
        dispatch(getStaffs());
    }, [dispatch, searchParams])

    useEffect(() => {
        const salemen = staffs.filter((staff) => staff.position === saleman);
        setSalemen(salemen);
    }, [staffs])

    useEffect(() => {
        let total = 0;
        saleInvoices.map((saleInvoice) => {
            total += parseInt(saleInvoice.final_total);
            return null;
        });
        (salemanId === '') ? setTotalSaleAmount(0) : setTotalSaleAmount(total);
    }, [salemanId])

    useEffect(() => {
        navigate(`/admin/show-sale-invoices?start_date=${startDate}&end_date=${endDate}&customer_name=${customerName}&type=${type}&customer_phone_no=${customerPhone}&sale_man_id=${salemanId}`)
    }, [type, customerName, customerPhone, startDate, endDate, navigate, salemanId]);

    const handleDelete = async (id) => {
        await dispatch(deleteSaleInvoice(id));
    }

    const onChangeRadio = (e) => {
        setFilterOption(e.target.value);
    }

    console.log("SaleInvoices:", saleInvoices);
    console.log("Sale men:", salemen);
    console.log("Sale man 's id:", salemanId);

    //for customer's name dropdown
    const savedCustomers = customers.map((customer) => customer.name);   //customer's names stored in database
    const invoiceCustomers = saleInvoices.map((invoice) => invoice.customer_name);  //customer's names not stored in db
    const allCustomers = [...savedCustomers, ...invoiceCustomers];
    const uniqueCustomers = allCustomers.filter((v, i, a) => a.indexOf(v) === i);   //to get unique customers 


    //for customer's phone dropdown 
    const savedCustomerPhones = customers.map((customer) => customer.phone);   //customer's phones stored in db
    const invoiceCustomerPhones = saleInvoices.map((invoice) => invoice.customer_phone_no); // customer's phones not stored in db
    const allCustomerPhones = [...savedCustomerPhones, ...invoiceCustomerPhones];
    const uniqueCustomerPhones = allCustomerPhones.filter((v, i, a) => a.indexOf(v) === i);  //to get unique phones

    const onChangeType = (value) => {
        if (value === undefined) { //clear input
            setType("");
        }
        else {
            setType(value);
        }

    }

    const onChangeCustomer = (value) => {
        if (value === undefined) {  //clear input
            setCustomerName("");
        }
        else {
            setCustomerName(value);
            setCustomerPhone("");
        }
    }

    const onChangeCustomerPhone = (value) => {
        if (value === undefined) { //clear input
            setCustomerPhone("");
        }
        else {
            setCustomerPhone(value);
            setCustomerName("");
        }
    }

    const onChangeSaleman = (value) => {
        if (value === undefined) {  //clear input
            setSalemanId('');
        }
        else {
            setSalemanId(value);
        }
    }

    //for excel export
    const fileName = "SaleInvoices";
    const transformedData = saleInvoices.map((invoice) => {
        return {
            Date: getReadableDateDisplay(invoice.created_at),
            InvoceCode: invoice.voucher_code,
            CustomerName: invoice.customer_name,
            CustomerPhone: invoice.customer_phone_no,
            Total: invoice.final_total,
            PaidAmount: invoice.paid,
            CreditAmount: invoice.credit,
            Type: invoice.type,
        };
    });

    const columns = [
        {
            title: t("saleInvoice.date"),
            dataIndex: "created_at",
            render: (created_at) => getReadableDateDisplay(created_at),
        },
        {
            title: t("saleInvoice.invoiceCode"),
            dataIndex: "voucher_code",
        },
        {
            title: t("saleInvoice.customerName"),
            dataIndex: "customer_name",
        },
        {
            title: t("saleInvoice.customerPhone"),
            dataIndex: "customer_phone_no",
        },
        {
            title: t("saleInvoice.sellerName"),
            dataIndex: "sale_man",
            render: (sale_man) => sale_man.name,
        },
        {
            title: t("saleInvoice.totalAmount"),
            dataIndex: "final_total",
            sorter: {
                compare: (a, b) => a.final_total - b.final_total,
                multiple: 3,
            },
        },
        {
            title: t("saleInvoice.paidAmount"),
            dataIndex: "paid",
            sorter: {
                compare: (a, b) => a.paid - b.paid,
                multiple: 2,
            },
        },
        {
            title: t("saleInvoice.creditAmount"),
            dataIndex: "credit",
            sorter: {
                compare: (a, b) => a.credit - b.credit,
                multiple: 1,
            },
        },
        {
            title: t("saleInvoice.type"),
            dataIndex: "type",
        },
        {
            title: t("component.actions"),
            dataIndex: "id",
            render: (id) => (
                <Space direction="horizontal">
                    <IconButton icon={<AlignLeftOutlined />} onClick={() => { navigate(`/admin/show-sale-invoices/${id}`) }} bgColor="var(--primary-color)"> </IconButton>
                    <Popconfirm
                        title={t("component.sureDelete")}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => { handleDelete(id) }}
                    >
                        {isManager && isCashier && isOwner &&
                            <IconButton icon={<DeleteOutlined />} bgColor="var(--red-color)"> </IconButton>
                        }

                    </Popconfirm>
                    <IconButton onClick={() => { navigate(`/admin/create-sale-credits/${id}`) }} bgColor="var(--yellow-color)" text={t("saleInvoice.toPayCredit")}> </IconButton>
                </Space>
            ),
        },
    ];

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }}>
                <Space direction="vertical" size="middle">
                    <Row gutter={[16, 16]}>
                        <Col xxl={{ span: 21 }} md={{ span: 18 }}>
                            <Title level={3}>{t("saleInvoice.saleInvoiceList")}</Title>
                        </Col>
                        <Col xxl={{ span: 3 }} md={{ span: 6 }}>
                            <ExportToExcel apiData={transformedData} fileName={fileName} />
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xxl={{ span: 6 }} lg={{ span: 12 }}>
                            <RangePicker
                                onChange={(val) => {
                                    if (val === null) {  //clear input
                                        setStartDate("");
                                        setEndDate("");
                                    }
                                    else {
                                        setStartDate(dayjs(val[0]).format("YYYY-MM-DD"));
                                        setEndDate(dayjs(val[1]).format("YYYY-MM-DD"));
                                    }
                                }
                                }
                            />
                        </Col>
                        <Col xxl={{ span: 6 }} lg={{ span: 12 }}>
                            <Label text={t("saleInvoice.type")} />
                            <Select
                                showSearch
                                placeholder={t("saleInvoice.selectType")}
                                optionFilterProp="children"
                                allowClear={true}
                                size="medium"
                                style={{ borderRadius: "10px" }}
                                onChange={onChangeType}
                            >

                                <Option value={cod} key="1">
                                    COD
                                </Option>
                                <Option value={credit} key="2">
                                    Credit
                                </Option>

                            </Select>
                        </Col>
                        <Col xxl={{ span: 6 }} lg={{ span: 12 }}>
                            <Radio.Group defaultValue="customerName" buttonStyle="outline" onChange={onChangeRadio} >
                                <Radio.Button value="customerName" >{t("saleInvoice.findByCustomerName")}</Radio.Button>
                                <Radio.Button value="customerPhone" >{t("saleInvoice.findByCustomerPhone")}</Radio.Button>
                            </Radio.Group>
                        </Col>
                        {filterOption === "customerName" &&
                            <Col xxl={{ span: 6 }} lg={{ span: 12 }}>
                                <Label text={t("saleInvoice.customerName")} />
                                <Select
                                    showSearch
                                    placeholder={t("saleInvoice.selectCustomer")}
                                    optionFilterProp="children"
                                    allowClear={true}
                                    size="medium"
                                    style={{ borderRadius: "10px" }}
                                    onChange={onChangeCustomer}
                                >
                                    {uniqueCustomers.map((customer) => (
                                        <Option key={customer} value={customer}>{customer}</Option>
                                    ))}

                                </Select>
                            </Col>
                        }
                        {filterOption === "customerPhone" &&
                            <Col xxl={{ span: 6 }} lg={{ span: 12 }}>
                                <Label text={t("saleInvoice.customerPhone")} />
                                <Select
                                    showSearch
                                    placeholder={t("saleInvoice.selectCustomerPhone")}
                                    optionFilterProp="children"
                                    allowClear={true}
                                    size="medium"
                                    style={{ borderRadius: "10px" }}
                                    onChange={onChangeCustomerPhone}
                                >

                                    {uniqueCustomerPhones.map((phone) => (
                                        <Option key={phone} value={phone}> {phone}</Option>
                                    ))}

                                </Select>
                            </Col>
                        }
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xxl={{ span: 6 }} lg={{ span: 12 }}>
                            <Label text={t("saleInvoice.sellerName")} />
                            <Select
                                showSearch
                                placeholder={t("saleInvoice.selectSeller")}
                                optionFilterProp="children"
                                allowClear={true}
                                size="medium"
                                style={{ borderRadius: "10px" }}
                                onChange={onChangeSaleman}
                            >
                                {salemen.map((saleman) => (
                                    <Option key={saleman.id} value={saleman.id}>{saleman.name}</Option>
                                ))}

                            </Select>
                        </Col>
                        <Col xxl={{ span: 6 }} lg={{ span: 12 }}>
                            <Label text={t("saleInvoice.totalSaleAmount")} />
                            <ShowText text={totalSaleAmount} />
                        </Col>
                    </Row>

                    <Table
                        bordered
                        columns={columns}
                        pagination={{ defaultPageSize: 10 }}
                        dataSource={saleInvoices}
                    />
                </Space>
            </Layout>
        </Spin>
    );
};

export default ShowSaleInvoices;
