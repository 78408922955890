import admin from "./admin.json";
import dashboard from "./dashboard.json";
import saleInvoice from "./saleInvoice.json";
import changePassword from "./changePassword.json";
import component from "./component.json";
import account from "./account.json";
import merchant from "./merchant.json";
import customer from "./customer.json";
import item from "./item.json";
import category from "./category.json";
import purchaseInvoice from "./purchaseInvoice.json";
import usedItem from "./usedItem.json";
import exchange from "./exchange.json";
import staff from "./staff.json";
import salary from "./salary.json";
import commission from "./commission.json";
import expense from "./expense.json";
import expenseName from "./expenseName.json";
import saleItem from "./saleItem.json";
import purchaseRecord from "./purchaseRecord.json";
import foc from "./foc.json";
import report from "./report.json";
import stock from "./stock.json";
import message from "./message.json";

export const mm = {
    admin,
    dashboard,
    saleInvoice,
    changePassword,
    component,
    account,
    merchant,
    customer,
    item,
    category,
    purchaseInvoice,
    usedItem,
    exchange,
    staff,
    salary,
    commission,
    expense,
    expenseName,
    saleItem,
    purchaseRecord,
    foc,
    report,
    stock,
    message
}