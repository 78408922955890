import React, { useEffect } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Table,
  Spin,
  Popconfirm,
  Select,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
  EditOutlined,
  DeleteOutlined,
  PlusSquareOutlined
} from "@ant-design/icons";
import IconButton from "../../components/IconButton";
import MediumButton from "../../components/MediumButton";
import { useNavigate } from "react-router-dom";
import Label from "../../components/Label";
import { useSelector, useDispatch } from "react-redux";
import { deleteItem, getItems } from "../../store/actions";
import { useState } from "react";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;

const ShowItems = () => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.item.items);
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);

  const [filteredItems, setFilteredItems] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(t("message.successDeleteMessage"));
    }
    return () => status.success;
  }, [status.success, t]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getItems());
    };
    fetchData();
    return () => {
      fetchData();
    };
  }, [dispatch]);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  const onChange = (value) => {
    if (value === undefined) {
      setFilteredItems(items);
    }
    else {
      const data = items.filter(
        (item) => item.id === value
      );
      setFilteredItems(data);
    }
  }

  const handleDelete = async (id) => {
    await dispatch(deleteItem(id));
  }

  const navigate = useNavigate();

  //for excel export
  const fileName = "Items";
  const transformedData = filteredItems.map((item) => {
    return {
      Code: item.code,
      Name: item.name,
      Category: item.category,
      CodBuyPrice: item.cod_buy_price,
      CodSalePrice: item.cod_sale_price,
      CreditBuyPrice: item.credit_buy_price,
      CreditSalePrice: item.credit_sale_price,
    };
  });

  const columns = [
    {
      title: t("item.image"),
      dataIndex: "image",
      render: (image) => (
        <img src={image} alt="ပစ္စည်းပုံ" width={100} height={100} />
      ),
    },
    {
      title: t("item.code"),
      dataIndex: "code",
    },
    {
      title: t("item.name"),
      dataIndex: "name",
    },
    {
      title: t("item.category"),
      dataIndex: "category",
      render: (category) => category.name,
    },
    {
      title: t("item.codBuyPrice"),
      dataIndex: "cod_buy_price",
    },
    {
      title: t("item.codSalePrice"),
      dataIndex: "cod_sale_price",
    },
    {
      title: t("item.creditBuyPrice"),
      dataIndex: "credit_buy_price",
    },
    {
      title: t("item.creditSalePrice"),
      dataIndex: "credit_sale_price",
    },
    {
      title: t("component.actions"),
      dataIndex: "id",
      render: (id) => (
        <Space direction="horizontal">
          <IconButton icon={<EditOutlined />} onClick={() => { navigate(`/admin/edit-items/${id}`) }} bgColor="var(--green-color)"> </IconButton>
          <Popconfirm
            title={t("component.sureDelete")}
            okText="Yes"
            cancelText="No"
            onConfirm={() => { handleDelete(id) }}
          >
            <IconButton icon={<DeleteOutlined />} onClick={() => { }} bgColor="var(--red-color)"> </IconButton>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 18 }} md={{ span: 12 }}>
              <Title level={3}>{t("item.itemList")}</Title>
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 6 }}>
              <MediumButton icon={<PlusSquareOutlined />} text={t("component.addNew")} bgColor="var(--primary-color)" onClick={() => { navigate("/admin/create-items") }} />
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 6 }}>
              <ExportToExcel apiData={transformedData} fileName={fileName} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label text={t("item.itemNameCode")} />
              <Select
                showSearch
                placeholder={t("item.selectItem")}
                optionFilterProp="children"
                allowClear={true}
                size="medium"
                style={{ borderRadius: "10px" }}
                onChange={onChange}
              >

                {items.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name} ( {item.code} )
                  </Option>
                ))}

              </Select>
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={filteredItems}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

export default ShowItems;
