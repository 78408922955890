import React, {useEffect} from "react";
import {
  Form,
  Typography,
  Space,
  Spin,
  Row,
  Col,
  Input,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import { SaveOutlined } from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import { useSelector, useDispatch } from "react-redux";
import { createCategory } from "../../store/actions";
import { useTranslation} from "react-i18next";

const { Title } = Typography;

const CreateCategory = () => {
  const [form] = Form.useForm();
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);

  const dispatch = useDispatch();
  const {t}= useTranslation();

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      form.resetFields();
      message.success(t("message.successCreateMessage"));

    }
    return () => status.success;
  }, [form, status.success, t]);

  const onFinish = async (values) => {
    await dispatch(createCategory(values));
  }

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }} >
        <Space direction="vertical">
          <Title level={3}>
          {t("category.createCategory")}
          </Title>

          <Row >
            <Col span={12} >
              <Form
                colon={false}
                labelCol={{
                  xl: {
                    span: 8,
                  },
                }}
                wrapperCol={{
                  xl: {
                    span: 24,
                  },
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                form={form}
                layout={"vertical"}
              >
                <Form.Item
                  name="name"
                  label={t("category.category")}
                  rules={[
                    {
                      required: true,
                      message: t("category.pleaseEnterCategory"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("category.enterCategory")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>

                <Form.Item style={{ textAlign: "right" }}>
                  <LargeButton icon={<SaveOutlined />} text={t("component.save")} />
                </Form.Item>
              </Form>
            </Col>


            <Col span={12}>

            </Col>
          </Row>


        </Space>


      </Layout>
    </Spin>
  );
};

export default CreateCategory;
