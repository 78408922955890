import React, { useEffect } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Table,
  Spin,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { PrinterOutlined, } from "@ant-design/icons";
import MediumButton from "../../components/MediumButton";
import ShowText from "../../components/ShowText";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getPurchaseInvoice } from "../../store/actions/purchase";
import { getReadableDateDisplay } from "../../utils/convertToHumanReadableTime";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const ShowPurchaseInvoiceDetails = () => {
  const status = useSelector((state) => state.status);
  const purchaseInvoice = useSelector((state) => state.purchase.purchaseInvoice);
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;
  const componentRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getPurchaseInvoice(id));
  }, [dispatch, id])

  // const purchaseItems = [...purchaseInvoice.purchase_items];
  // console.log("Purchase Items:", purchaseItems)
  const transformedPurchaseItems = purchaseInvoice?.purchase_items?.map((purchaseItem, index) => {
    return {
      ...purchaseItem,
      key: purchaseItem.id,
      no: index + 1,
    }
  })
  console.log("Transformed Purchase Items:", transformedPurchaseItems);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const columns = [
    {
      title: t("purchaseInvoice.no"),
      dataIndex: "no",
    },
    {
      title: t("purchaseInvoice.itemName"),
      dataIndex: "product",
      render: (product) => product.name
    },
    {
      title: t("purchaseInvoice.price"),
      dataIndex: "price",
      align: "right"
    },
    {
      title: t("purchaseInvoice.qty"),
      dataIndex: "quantity",
      align: "center"
    },
    {
      title: t("purchaseInvoice.subtotal"),
      dataIndex: "subtotal",
      align: "right"
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }}>
        <Space direction="vertical" size="middle">

          <Row gutter={[16, 16]}>
            <Col xl={{ span: 18 }} md={{ span: 12 }}>
              <Title level={3}> {t("purchaseInvoice.purchaseInvoiceDetails")}</Title>
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 6 }}>
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 6 }}>
              <MediumButton icon={<PrinterOutlined />} text={t("component.print")} bgColor="var(--green-color)" onClick={handlePrint} />
            </Col>
          </Row>
          <div ref={componentRef}>
            <Space direction="vertical" style={{ border: "0px solid var(--info-color)", width: "90%", margin: "5%" }} >
              <Row style={{ backgroundColor: "var(--label-color)" }} align="center">
                <Col>
                  <Title level={3} style={{ color: "var(--white-color)" }}>{t("purchaseInvoice.invoice")}</Title>
                </Col>
              </Row>
              <Row justify="space-around">
                <Col xxl={{ span: 7 }} xl={{ span: 10 }}>
                  <Row justify="center">
                    <Col xl={{ span: 12 }}>
                      <Space direction="vertical">
                        <ShowText text={t("purchaseInvoice.date")} />
                        <ShowText text={t("purchaseInvoice.invoiceNo")} />
                        <ShowText text={t("purchaseInvoice.type")} />
                      </Space>
                    </Col>
                    <Col xl={{ span: 10 }}>
                      <Space direction="vertical">
                        <ShowText text={getReadableDateDisplay(purchaseInvoice?.created_at)} />
                        <ShowText text={purchaseInvoice?.invoice_code} />
                        <ShowText text={purchaseInvoice.type} />
                      </Space>
                    </Col>
                  </Row>
                </Col>
                <Col xxl={{ span: 7 }} xl={{ span: 0 }}></Col>
                <Col xxl={{ span: 7 }} xl={{ span: 10 }}>
                  <Row justify="center">
                    <Col xl={{ span: 10 }}>
                      <Space direction="vertical">
                        <ShowText text={t("purchaseInvoice.merchantName")} />
                        <ShowText text={t("purchaseInvoice.companyName")} />
                      </Space>
                    </Col>
                    <Col xl={{ span: 10 }}>
                      <Space direction="vertical">
                        <ShowText text={purchaseInvoice?.merchant?.name} />
                        <ShowText text={purchaseInvoice?.merchant?.company_name} />
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Table
                bordered
                columns={columns}
                pagination={{ position: ["none", "none"] }}
                dataSource={transformedPurchaseItems}
                style={{ margin: "5px" }}
              />
              <Row justify="end" style={{ marginBottom: "30px" }}>
                <Col xl={{ span: 3 }} md={{ span: 5 }}>
                  <Space direction="vertical">
                    <ShowText text={t("purchaseInvoice.total")} />
                    <ShowText text={t("purchaseInvoice.paidAmount")} />
                    <ShowText text={t("purchaseInvoice.creditAmount")} />
                  </Space>
                </Col>
                <Col xl={{ span: 3 }} md={{ span: 4 }} align="right" style={{ paddingRight: "15px" }}>
                  <Space direction="vertical">
                    <ShowText text={purchaseInvoice?.whole_total} />
                    <ShowText text={purchaseInvoice?.paid} />
                    <ShowText text={purchaseInvoice?.credit} />
                  </Space>
                </Col>
              </Row>
            </Space>
          </div>

        </Space>
      </Layout>
    </Spin>
  );
};

export default ShowPurchaseInvoiceDetails;
