import React,{useEffect} from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Table,
  Spin,
  Popconfirm,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
  DeleteOutlined,
  PlusSquareOutlined,
  EditOutlined
} from "@ant-design/icons";
import IconButton from "../../components/IconButton";
import { useNavigate } from "react-router-dom";
import MediumButton from "../../components/MediumButton";
import { useSelector, useDispatch } from "react-redux";
import { deleteCommission, getCommissions } from "../../store/actions/commission";
import { getReadableDateDisplay } from "../../utils/convertToHumanReadableTime";
import {useTranslation} from "react-i18next";

const { Title } = Typography;


const ShowCommission = () => {
  const navigate = useNavigate();
  const status = useSelector((state)=> state.status);
  const error = useSelector((state)=> state.error);
  const commissions = useSelector((state)=> state.commission.commissions);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(()=>{
    dispatch(getCommissions())
  },[dispatch])

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(t("message.successDeleteMessage"));
    }
    return () => status.success;
  }, [status.success, t]);

  const handleDelete = async(id)=>{
     await dispatch(deleteCommission(id));
  }
 
  const columns = [
    
    {
      title: t("commission.date"),
      dataIndex: "created_at",
      render:(created_at)=> getReadableDateDisplay(created_at)
    },
    {
      title: t("commission.saleAmount"),
      dataIndex: "sale_rate_price",
    },
    {
      title:  t("commission.commissionRate"),
      dataIndex: "commercial_percent",
    },
    {
      title:  t("component.actions"),
      dataIndex: "id",
      render: (id) => (
        <Space direction="horizontal">
          <IconButton icon={<EditOutlined />} onClick={() => {navigate(`/admin/edit-commissions/${id}`)}} bgColor="var(--green-color)"> </IconButton>
          <Popconfirm
            title={t("component.sureDelete")}
            okText="Yes"
            cancelText="No"
            onConfirm={() => {handleDelete(id)}}
          >
            <IconButton icon={<DeleteOutlined />} onClick={() => { }} bgColor="var(--red-color)"> </IconButton>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px"}}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 21 }} md={{span: 18}}>
              <Title level={3}> {t("commission.commissionList") }</Title>
            </Col>

            <Col xl={{ span: 3 }} md={{span:6}}>
            <MediumButton icon={<PlusSquareOutlined/>} text={t("component.addNew") } bgColor="var(--primary-color)" onClick={()=>{navigate("/admin/create-commission")}}/>
            </Col>
          </Row>
         
          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={commissions}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

export default ShowCommission;
