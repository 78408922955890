import React, { useEffect } from "react";
import {
  Form,
  Typography,
  Space,
  Col,
  Row,
  Spin,
  Input,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import { SaveOutlined } from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import { changePassword } from "../../store/actions";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const ChangePassword = () => {

  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = Form.useForm();

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      form.resetFields();
      message.success(t("message.successEditMessage"));
    }

    return () => status.success;
  }, [form, status.success, t]);


  const onFinish = async (values) => {
    await dispatch(changePassword(values));
  };
  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: " 20px 40px" }} >
        <Space direction="vertical">
          <Title level={3}>
            {t("changePassword.changePwd")}
          </Title>
          <Row >
            <Col span={12} >
              <Form
                colon={false}
                labelCol={{
                  xl: {
                    span: 8,
                  },
                }}
                wrapperCol={{
                  xl: {
                    span: 24,
                  },
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                form={form}
                layout={"vertical"}
              >
                <Form.Item
                  name="current_password"
                  label={t("changePassword.currentPwd")}
                  rules={[
                    {
                      required: true,
                      message: t("changePassword.pleaseEnterCurrentPwd"),
                    },
                  ]}

                >
                  <Input
                    placeholder={t("changePassword.enterCurrentPwd")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="new_password"
                  label={t("changePassword.newPwd")}
                  rules={[
                    {
                      required: true,
                      message: t("changePassword.pleaseEnterNewPwd"),
                    },
                  ]}
                >

                  <Input
                    placeholder={t("changePassword.enterNewPwd")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="new_password_confirmation"
                  label={t("changePassword.confirmPwd")}
                  rules={[
                    {
                      required: true,
                      message: t("changePassword.pleaseEnterConfirmPwd"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("changePassword.enterConfirmPwd")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>

                <Form.Item style={{ textAlign: "right" }}>
                  <LargeButton icon={<SaveOutlined />} text={t("component.save")} />
                </Form.Item>
              </Form>
            </Col>


            <Col span={12}>

            </Col>
          </Row>


        </Space>


      </Layout>
    </Spin>


  );
};



export default ChangePassword;
