import React, { useEffect } from "react";
import {
    Typography,
    Space,
    Row,
    Col,
    Spin,
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
    PrinterOutlined,
    LeftOutlined
} from "@ant-design/icons";
import MediumButton from "../../components/MediumButton";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSaleInvoice } from "../../store/actions";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import InvoiceTemplate from "./InvoiceTemplate";

const { Title } = Typography;

const ShowSaleInvoiceDetails = () => {
    const navigate = useNavigate();
    const status = useSelector((state) => state.status);
    const saleInvoice = useSelector((state) => state.saleInvoice.saleInvoice);

    const dispatch = useDispatch();
    const params = useParams();
    const id = params.id;

    const componentRef = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getSaleInvoice(id))
    }, [dispatch, id])


    //transform sale-item obj with key and no
    const saleItems = saleInvoice.items?.map((saleItem, index) => {
        return {
            ...saleItem,
            key: index + 1,
            no: index + 1,
        }
    })

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    //for number of vouncher
    let count = ((saleItems?.length) % 10 === 0) ? ~~((saleItems?.length) / 10) : ~~((saleItems?.length) / 10) + 1;

    let index = 0;
    let batchSaleItems = [];
    for (let i = 1; i <= count; i++) {
        const data = saleItems?.slice(index, index + 10);
        batchSaleItems.push(data);
        index += 10;
    }


    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }}>
                <Space direction="vertical" size="middle">
                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 12 }} md={{ span: 24 }}>
                            <Title level={3}>{t("saleInvoice.saleInvoiceDetails")}</Title>
                        </Col>
                        <Col xl={{ span: 4 }} md={{ span: 18 }}>
                            <MediumButton icon={<LeftOutlined />} text={t("saleInvoice.toSaleInvoiceList")} bgColor="var(--primary-color)" onClick={() => { navigate("/admin/show-sale-invoices") }} />
                        </Col>
                        <Col xl={{ span: 4 }} md={{ span: 18 }}>
                            <MediumButton icon={<LeftOutlined />} text={t("saleInvoice.toSaleScreen")} bgColor="var(--primary-color)" onClick={() => { navigate("/admin/create-sale-invoice") }} />
                        </Col>
                        <Col xl={{ span: 4 }} md={{ span: 6 }}>
                            <MediumButton icon={<PrinterOutlined />} text={t("component.print")} bgColor="var(--green-color)" onClick={handlePrint} />
                        </Col>
                    </Row>
                    <div ref={componentRef}>
                        {
                            batchSaleItems.map((batchSaleItem, index) => {
                                if (index === (count - 1)) {
                                    return <InvoiceTemplate saleInvoice={saleInvoice} saleItems={batchSaleItem} isLast={true} />
                                } else {
                                    return <InvoiceTemplate saleInvoice={saleInvoice} saleItems={batchSaleItem} isLast={false} />
                                }
                            }
                            )
                        }
                    </div>

                </Space>
            </Layout>
        </Spin>
    );
};

export default ShowSaleInvoiceDetails;
