import { combineReducers } from "redux";
import auth from "./auth";
import status from "./status";
import error from "./error";
import merchant from "./merchant";
import item from "./item"
import category from "./category";
import stock from "./stock";
import usedItem from "./usedItem";
import supplierExchange from "./supplierExchange";
import customerExchange from "./customerExchange";
import staff from "./staff";
import salaryAndBenefit from "./salaryAndBenefit";
import commission from "./commission";
import expense from "./expense";
import expenseName from "./expenseName";
import priceHistory from "./priceHistory";
import saleInvoice from "./saleInvoice";
import report from "./report";
import foc from "./foc";
import purchase from "./purchase";
import customer from "./customer";

const reducers = combineReducers({
  auth,
  status,
  error,
  merchant,
  item,
  category,
  stock,
  usedItem,
  supplierExchange,
  customerExchange,
  staff,
  salaryAndBenefit,
  commission,
  expense,
  expenseName,
  priceHistory,
  saleInvoice,
  report,
  foc,
  purchase,
  customer,
});
export default reducers;