import React from "react";
import {
    Typography,
    Space,
    Row,
    Col,
    Table,
    Spin,
    InputNumber,
    Select,
    message,
    Radio,
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
    PlusSquareOutlined
} from "@ant-design/icons";

import MediumButton from "../../components/MediumButton";
import { useNavigate } from "react-router-dom";
import Label from "../../components/Label";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getStocks } from "../../store/actions";
import { useState } from "react";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;

const ShowStocks = () => {
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const stocks = useSelector((state) => state.stock.stocks);

    const dispatch = useDispatch();

    const [filteredStocks, setFilteredStocks] = useState([]);
    const [filterOption, setFilterOption] = useState("item");
    const { t } = useTranslation();

    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        dispatch(getStocks());
    }, [dispatch]);

    useEffect(() => {
        setFilteredStocks(stocks);
    }, [stocks]);

    const navigate = useNavigate();

    const onChange = (value) => {
        if (value === undefined) {
            setFilteredStocks(stocks);
        }
        else {
            const data = stocks.filter(
                (stock) => stock.product.id === value
            );
            setFilteredStocks(data);
        }
    }
    const onChangeQty = (value) => {
        if (value === null) {
            setFilteredStocks(stocks);
        }
        else {
            const data = stocks.filter(
                (stock) => parseInt(stock.quantity) <= value
            );
            setFilteredStocks(data);
        }
    }

    const onChangeRadio = (e) => {
        setFilterOption(e.target.value);
        setFilteredStocks(stocks);
    }

    //for excel export
    const fileName = "Stocks";
    const transformedData = filteredStocks.map((stock) => {
        return {
            Code: stock.product.code,
            Name: stock.product.name,
            Category: stock.product.category.name,
            CodBuyPrice: stock.product.cod_buy_price,
            CodSalePrice: stock.product.cod_sale_price,
            CreditBuyPrice: stock.product.credit_buy_price,
            CreditSalePrice: stock.product.credit_sale_price,
            quantity: stock.quantity,
        };
    });

    const columns = [
        {
            title: t("item.image"),
            dataIndex: "product",
            render: (product) => (
                <img src={product.image} alt="ပစ္စည်းပုံ" width={100} height={100} />
            ),
        },
        {
            title: t("item.code"),
            dataIndex: "product",
            render: (product) => product.code,
        },
        {
            title: t("item.name"),
            dataIndex: "product",
            render: (product) => product.name,
        },
        {
            title: t("item.category"),
            dataIndex: "product",
            render: (product) => product.category.name,
        },
        {
            title: t("item.codBuyPrice"),
            dataIndex: "product",
            render: (product) => product.cod_buy_price,
        },
        {
            title: t("item.codSalePrice"),
            dataIndex: "product",
            render: (product) => product.cod_sale_price,
        },
        {
            title: t("item.creditBuyPrice"),
            dataIndex: "product",
            render: (product) => product.credit_buy_price,
        },
        {
            title: t("item.creditSalePrice"),
            dataIndex: "product",
            render: (product) => product.credit_sale_price,
        },
        {
            title: t("stock.qty"),
            dataIndex: "quantity",
            sorter: (a, b) => a.quantity - b.quantity,
            // render: (quantity) => {
            // if (quantity <= 4) {
            //     return <span style={{ color: "var(--red-color)" }}>{quantity}</span>;
            // }
            // else if (quantity <= 8) {
            //     return <span style={{ color: "var(--yellow-color)" }}>{quantity}</span>;
            // }
            // else {
            //     return <span>{quantity}</span>;
            // }
            // }
        },
        // {
        //     title: t("component.actions"),
        //     dataIndex: "id",
        //     render: (id) => (
        //         <Space direction="horizontal">
        //             <IconButton icon={<EditOutlined />} onClick={() => { navigate(`/admin/edit-stocks/${id}`) }} bgColor="var(--green-color)"> </IconButton>
        //             <Popconfirm
        //                 title={t("component.sureDelete")}
        //                 okText="Yes"
        //                 cancelText="No"
        //                 onConfirm={() => { handleDelete(id)}}
        //             >
        //                 <IconButton icon={<DeleteOutlined />} onClick={() => { }} bgColor="var(--red-color)"> </IconButton>
        //             </Popconfirm>
        //         </Space>
        //     ),
        // },
    ];
    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }}>
                <Space direction="vertical" size="middle">
                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 18 }} md={{ span: 12 }}>
                            <Title level={3}>{t("stock.stockList")}</Title>
                        </Col>
                        <Col xl={{ span: 3 }} md={{ span: 6 }}>
                            <MediumButton icon={<PlusSquareOutlined />} text={t("component.addNew")} bgColor="var(--primary-color)" onClick={() => { navigate("/admin/create-stock") }} />
                        </Col>
                        <Col xl={{ span: 3 }} md={{ span: 6 }}>
                            <ExportToExcel apiData={transformedData} fileName={fileName} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Radio.Group defaultValue="item" buttonStyle="outline" onChange={onChangeRadio} >
                                <Radio.Button value="item" >{t("stock.toFindByItem")}</Radio.Button>
                                <Radio.Button value="lessItem" >{t("stock.toCheckLessStock")}</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        {filterOption === "item" &&
                            <Col xl={{ span: 8 }} md={{ span: 24 }}>
                                <Label text={t("item.itemNameCode")} />
                                <Select
                                    showSearch
                                    placeholder={t("item.selectItem")}
                                    optionFilterProp="children"
                                    allowClear={true}
                                    size="medium"
                                    style={{ borderRadius: "10px" }}
                                    onChange={onChange}
                                >
                                    {stocks?.map((stock) => (
                                        <Option key={stock.product.id} value={stock.product.id}>
                                            {stock.product.name} ( {stock.product.code} )
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        }

                        {filterOption === "lessItem" &&
                            <Col xl={{ span: 8 }} md={{ span: 24 }}>
                                <Label text={t("stock.qty")} />
                                <InputNumber min={1} onChange={onChangeQty} placeholder="" />
                            </Col>
                        }

                    </Row>
                    <Table
                        bordered
                        columns={columns}
                        pagination={{ defaultPageSize: 10 }}
                        dataSource={filteredStocks}
                        rowClassName={(record) => record.quantity <= 4 ? "red" : (record.quantity <= 8 ? "yellow" : null)} //background color: "red/yellow"
                    />

                </Space>
            </Layout>
        </Spin>
    );
};

export default ShowStocks;
