import { Layout, Space, DatePicker, Spin, Row, Col, Card, Table, Divider } from "antd";
import Title from "antd/lib/typography/Title";
import ShowText from "../components/ShowText";
import { DollarCircleOutlined, RiseOutlined, FallOutlined, BarChartOutlined } from "@ant-design/icons"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getReport } from "../store/actions/report";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getLessStocks } from "../store/actions/stock";
import { getBestSellerItems } from "../store/actions/item";
import { getPurchaseReports } from "../store/actions";
import { useTranslation } from "react-i18next";
import { owner } from "../utils/position";

const Dashboard = () => {
    const { RangePicker } = DatePicker;
    const user = useSelector((state) => state.auth.user);
    const status = useSelector((state) => state.status);
    const report = useSelector((state) => state.report.report);
    const lessStocks = useSelector((state) => state.stock.lessStocks)
    const bestSellerItems = useSelector((state) => state.item.bestSellerItems);
    const purchaseReports = useSelector((state) => state.purchase.purchaseReports);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = () => {
            dispatch(getReport(searchParams));
            dispatch(getLessStocks(4)); //show stocks less than 4 in dashboard
            dispatch(getBestSellerItems(searchParams));
            dispatch(getPurchaseReports(searchParams));
        }
        fetchData();
        return fetchData();
    }, [dispatch, searchParams])

    const profit = report.profit >= 0 ? report.profit : 0;
    const loss = report.profit < 0 ? Math.abs(report.profit) : 0;

    //css styling
    const iconStyle = { fontSize: "30px", color: "var(--white-color)" };
    const statusCardStyle = {
        width: "100%",
        border: "1px solid var(--border-color)",
        borderRadius: "8px",
        padding: "10px"
    };
    const tableCardStyle = {
        width: "100%",
        border: "1px solid var(--border-color)",
        borderRadius: "8px",
        padding: "0"
    };
    const headStyle = { backgroundColor: 'var(--label-color)', borderRadius: "5px", color: "var(--white-color)" };

    const purchaseReportsColumns = [
        {
            title: t("dashboard.companyName"),
            dataIndex: 'merchant',
            render: (merchant) => merchant.company_name
        },
        {
            title: t("dashboard.total"),
            dataIndex: 'whole_total',
            sorter: {
                compare: (a, b) => a.whole_total - b.whole_total,
                multiple: 3,
            },
        },
        {
            title: t("dashboard.paidAmount"),
            dataIndex: 'paid',
            sorter: {
                compare: (a, b) => a.paid - b.paid,
                multiple: 2,
            },

        },
        {
            title: t("dashboard.creditAmount"),
            dataIndex: 'credit',
            sorter: {
                compare: (a, b) => a.credit - b.credit,
                multiple: 1,
            },

        },
        {
            title: t("dashboard.type"),
            dataIndex: 'type',

        },
    ];
    const less_stocks_columns = [
        {
            title: t("dashboard.itemName"),
            dataIndex: 'product',
            render: (product) => product.name,

        },
        {
            title: t("dashboard.quantity"),
            dataIndex: 'quantity',
            sorter: (a, b) => a.quantity - b.quantity,
        },
    ];
    const bestseller_items_columns = [
        {
            title: t("dashboard.itemName"),
            dataIndex: 'product',
            render: (product) => product.name,
        },
        {
            title: t("dashboard.quantity"),
            dataIndex: 'quantity',
            sorter: (a, b) => a.quantity - b.quantity,
        },
    ];

    const isOwner = user.position === owner;

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }}>
                <Space direction="vertical" size="middle">
                    <Title level={3}>{t("dashboard.dashboard")}</Title>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <Card
                                title={t("dashboard.lessStockItems")}
                                bordered={true}
                                style={tableCardStyle}
                                headStyle={{ backgroundColor: 'var(--red-color)', borderRadius: "5px", color: "var(--white-color)" }}
                            >
                                <Table columns={less_stocks_columns}
                                    pagination={{ position: ["none", "none"] }}
                                    dataSource={lessStocks}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <Divider />
                    <RangePicker
                        onChange={(val) => {
                            if (val === null) {
                                navigate(`/admin/dashboard`)
                            }
                            else {
                                navigate(`/admin/dashboard?start_date=${dayjs(
                                    val[0]
                                ).format("YYYY-MM-DD")}&end_date=${dayjs(val[1]).format(
                                    "YYYY-MM-DD"
                                )}`)
                            }
                        }}
                    />

                    {/* Four status Row */}
                    {isOwner &&
                        <Row gutter={[5, 5]}>
                            <Col xl={{ span: 6 }}>
                                <Card
                                    title={t("dashboard.revenue")}
                                    bordered={true}
                                    style={statusCardStyle}
                                >
                                    <Space>
                                        <Card style={{ backgroundColor: "var(--primary-color)", borderRadius: "5px" }}><DollarCircleOutlined style={iconStyle} /></Card>
                                        <ShowText text={report.sale_total === null ? 0 : Number(report.sale_total) + Number(report.supplier_change) + Number(report.customer_change)} />
                                    </Space>
                                </Card>
                            </Col>
                            <Col xl={{ span: 6 }}>
                                <Card
                                    title={t("dashboard.totalExpense")}
                                    bordered={true}
                                    style={statusCardStyle}
                                >
                                    <Space>
                                        <Card style={{ backgroundColor: "var(--yellow-color)", borderRadius: "5px" }}><BarChartOutlined style={iconStyle} /></Card>
                                        <ShowText text={report.expense_total} />
                                    </Space>
                                </Card>
                            </Col>
                            <Col xl={{ span: 6 }}>
                                <Card
                                    title={t("dashboard.profit")}
                                    bordered={true}
                                    style={statusCardStyle}
                                >
                                    <Space>
                                        <Card style={{ backgroundColor: "var(--green-color)", borderRadius: "5px" }}><RiseOutlined style={iconStyle} /></Card>
                                        <ShowText text={profit} />
                                    </Space>
                                </Card>
                            </Col>
                            <Col xl={{ span: 6 }}>
                                <Card
                                    title={t("dashboard.loss")}
                                    bordered={true}

                                    style={{
                                        width: "100%",
                                        border: "1px solid var(--border-color)",
                                        borderRadius: "8px",
                                        padding: "10px"
                                    }}
                                >
                                    <Space>
                                        <Card style={{ backgroundColor: "var(--red-color)", borderRadius: "5px" }}><FallOutlined style={iconStyle} /></Card>
                                        <ShowText text={loss} />
                                    </Space>
                                </Card>
                            </Col>
                        </Row>
                    }


                    {/* Three table Row */}
                    <Row gutter={[20, 20]}>
                        <Col xl={{ span: 12 }} md={{ span: 24 }}>
                            <Card
                                title={t("dashboard.purchaseRecord")}
                                bordered={true}
                                style={tableCardStyle}
                                headStyle={headStyle}
                            >
                                <Table columns={purchaseReportsColumns}
                                    pagination={{ position: ["none", "none"] }}
                                    dataSource={purchaseReports}
                                />
                            </Card>
                        </Col>
                        <Col xl={{ span: 12 }} md={{ span: 24 }}>
                            <Card
                                title={t("dashboard.bestSellerItems")}
                                bordered={true}
                                style={tableCardStyle}
                                headStyle={headStyle}
                            >
                                <Table columns={bestseller_items_columns}
                                    pagination={{ position: ["none", "none"] }}
                                    dataSource={bestSellerItems}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Layout>
        </Spin>
    );
}
export default Dashboard;