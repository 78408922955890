export const successCreateMessage = "အသစ်ဖန်တီးခြင်း အောင်မြင်ပါသည်။";
export const successEditMessage = "ပြင်ဆင်ခြင်း အောင်မြင်ပါသည်။";
export const successDeleteMessage = "ဖျက်ခြင်း အောင်မြင်ပါသည်။";

export const serverErrorMessage = "Something was wrong!";

export const changedPwdErrorMessage = "There's an error while changing password";

export const pwdNotMatchErrorMessage = "The new password confirmation does not match.";

export const StockErrorMessage = "Quality is greater than stock.";

export const deleteExchangeErrorMessage = "There is no enough stock to return.";


