import React, { useState } from "react";
import {
  Form,
  Typography,
  Space,
  Table,
  Spin,
  Row,
  Col,
  Divider,
  Checkbox,
  Card,
  Input,
  InputNumber,
  Select,
  Button,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
  SaveOutlined,
  PlusSquareOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import IconButton from "../../components/IconButton";
import Label from "../../components/Label";
import ShowText from "../../components/ShowText";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createPurchaseInvoice, getItems, getMerchants } from "../../store/actions";
import { useTranslation } from "react-i18next";
import { cod, credit } from "../../utils/invoiceType";

const { Title } = Typography;
const { Option } = Select;
const CreatePurchaseInvoice = () => {

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const merchants = useSelector((state) => state.merchant.merchants);
  const items = useSelector((state) => state.item.items);

  const [codChecked, setCodChecked] = useState(true);
  const [creditChecked, setCreditChecked] = useState(false);
  const [type, setType] = useState("cod");
  const [companyName, setCompanyName] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [price, setPrice] = useState(0);
  const [qty, setQty] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [isFoc, setIsFoc] = useState(false);
  const [purchaseItems, setPurchaseItems] = useState([]);
  const [itemName, setItemName] = useState("");
  const [total, setTotal] = useState(0);
  const [creditTotal, setCreditTotal] = useState(0);
  const [paidTotal, setPaidTotal] = useState(0);
  const [merchantId, setMerchantId] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(t("message.successCreateMessage"));
    }

    return () => status.success;
  }, [status.success, t]);


  useEffect(() => {
    const fetchData = () => {
      dispatch(getMerchants());
      dispatch(getItems());
    }
    fetchData();
    return () => fetchData();
  }, [dispatch]);

  useEffect(() => {
    const total = qty * price;
    setSubTotal(total);
  }, [qty, price])

  useEffect(() => {
    form.setFieldsValue({ subtotal: subTotal });
  }, [subTotal, form])


  useEffect(() => {
    const price = (type === "cod") ? selectedItem?.cod_buy_price : selectedItem?.credit_buy_price;
    form.setFieldsValue({ price: parseInt(price) });
    setPrice(price);
    setItemName(selectedItem?.name);
  }, [selectedItem, type, form])

  useEffect(() => {
    let total = 0;
    purchaseItems.map((item) => {
      total += item.subtotal;
      return null;
    })
    setTotal(total);
  }, [purchaseItems])


  useEffect(() => {
    setCreditTotal(total - paidTotal);
  }, [paidTotal, total])


  const onChangeCod = (e) => {
    if (e.target.checked) {
      setCodChecked(true);
      setCreditChecked(false);
      setType(cod);
    }
    else {
      setCodChecked(false);
      setCreditChecked(true);
      setType(credit);
    }
  }

  const onChangeCredit = (e) => {
    if (e.target.checked) {
      setCreditChecked(true);
      setCodChecked(false);
      setType(credit);
    }
    else {
      setCreditChecked(false);
      setCodChecked(true);
      setType(cod);
    }
  }

  const onChangeMerchant = (value) => {
    if (value === undefined) {
      setCompanyName("");
      setMerchantId();
    }
    else {
      const index = merchants.findIndex((merchant) => merchant.id === value);
      const selectedMerchant = merchants[index];
      setCompanyName(selectedMerchant.company_name);
      setMerchantId(selectedMerchant.id);
    }
  }

  const onChangeInvoiceNo = (e) => {
    setInvoiceNo(e.target.value);
  }

  const onChangeItem = (value) => {
    if (value === undefined) {
      form.resetFields();
    } else {
      const index = items.findIndex((item) => item.id === value);
      setSelectedItem(items[index]);
    }
  }

  const onChangeQty = (value) => {
    setQty(value);
  }

  const onChangePrice = (value) => {
    setPrice(value);
  }

  const onChangeFoc = (e) => {
    if (e.target.checked) {
      setIsFoc(true);
      setSubTotal(0);
    }
    else {
      setIsFoc(false);
      setSubTotal(qty * price);
    }
  }
  const onChangePaidTotal = (value) => {
    setPaidTotal(value);
  }

  const onFinish = (values) => {
    const item = {
      ...values,
      is_foc: isFoc,
      item_name: itemName,   //coz needed in table , not in api
    }
    setPurchaseItems([
      ...purchaseItems,
      item
    ])
    form.resetFields();
    setSelectedItem();
    setQty(0);
    setPrice(0);
    setSubTotal(0);
    setIsFoc(false);
  }

  const handleDelete = (record) => {
    const result = purchaseItems.filter((item) => item !== record);
    setPurchaseItems(result);
  }

  const handleSave = () => {
    const data = {
      merchant_id: merchantId,
      whole_total: total,
      paid: paidTotal,
      credit: creditTotal,
      type: type,
      invoice_code: invoiceNo,
      purchase_items: [
        ...purchaseItems
      ],
    }
    dispatch(createPurchaseInvoice(data));
    setPurchaseItems([]);
    setInvoiceNo("");
    setCompanyName("");
  }


  const columns = [
    {
      title: t("purchaseInvoice.itemName"),
      dataIndex: "item_name",

    },
    {
      title: t("purchaseInvoice.qty"),
      dataIndex: "quantity",
    },
    {
      title: t("purchaseInvoice.price"),
      dataIndex: "price",
    },
    {
      title: t("purchaseInvoice.subtotal"),
      dataIndex: "subtotal",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <IconButton icon={<DeleteOutlined />} bgColor="var(--red-color)" onClick={() => { handleDelete(record) }} />
      ),
    },
  ];



  return (
    <Spin spinning={false}>
      <Layout style={{ margin: "20px 40px" }} >
        <Space direction="vertical" >
          <Title style={{ marginBottom: "35px" }} level={3}>
            {t("purchaseInvoice.createPurchaseInvoice")}
          </Title>

          {/* COD , Credit checkbox card and Invoice Number*/}
          <Row style={{ marginBottom: "20px" }} >
            <Col xl={{ span: 4 }} md={{ span: 8 }} align="center">
              <Card
                align="center"
                style={{ borderRadius: 8, backgroundColor: "var(--white-color)" }}
                hoverable
              >
                <Space split={<Divider />}>
                  <Checkbox onChange={onChangeCod} checked={codChecked}>COD</Checkbox>
                  <Checkbox onChange={onChangeCredit} checked={creditChecked}>Credit</Checkbox>
                </Space>
              </Card>
            </Col>
            <Col xl={{ span: 2 }} md={{ span: 4 }}></Col>

            <Col>
            </Col>
          </Row>

          {/* date , Invoice No , Merchant Name and company name row */}
          <Row gutter={[16, 16]}>
            {/* <Col xl={{ span: 6 }} md={{ span: 12 }}>
              <DatePicker onChange={() => { }} />
            </Col> */}
            <Col xl={{ span: 6 }} md={{ span: 12 }}>
              <Space>
                <Label text={t("purchaseInvoice.invoiceNo")} />
                <Input
                  placeholder={t("purchaseInvoice.enterInvoiceNo")}
                  style={{ borderRadius: "10px" }}
                  size="medium"
                  onChange={onChangeInvoiceNo}
                />

              </Space>
            </Col>
            <Col xl={{ span: 6 }} md={{ span: 12 }}>
              <Label text={t("purchaseInvoice.merchantName")} />
              <Select
                showSearch
                placeholder={t("purchaseInvoice.selectMerchantName")}
                optionFilterProp="children"
                allowClear={true}
                size="medium"
                style={{ borderRadius: "10px" }}
                onChange={onChangeMerchant}
              >
                {merchants.map((merchant) => (
                  <Option key={merchant.id} value={merchant.id}>{merchant.name}</Option>
                ))}
              </Select>
            </Col>
            <Col xl={{ span: 6 }} md={{ span: 12 }}>
              <Label text={t("purchaseInvoice.companyName")} />
              <ShowText text={companyName} />
            </Col>
          </Row>

          <Divider />

          {/* Form data */}
          <Row >
            <Col xl={{ span: 12 }} >
              <Form
                colon={false}
                labelCol={{
                  xl: {
                    span: 8,
                  },
                }}
                wrapperCol={{
                  xl: {
                    span: 24,
                  },
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                form={form}
                layout={"vertical"}
              >
                <Form.Item
                  name="product_id"
                  label={t("purchaseInvoice.itemName")}
                  rules={[
                    {
                      required: true,
                      message: t("purchaseInvoice.pleaseSelectItem"),
                    },
                  ]}
                >

                  <Select
                    showSearch
                    placeholder={t("purchaseInvoice.selectItemName")}
                    optionFilterProp="children"
                    allowClear={true}
                    size="large"
                    style={{ borderRadius: "10px" }}
                    onChange={onChangeItem}
                  >
                    {items.map((item) => (
                      <Option key={item.id} value={item.id}>{item.name}</Option>
                    ))}

                  </Select>
                </Form.Item>
                <Form.Item
                  name="quantity"
                  label={t("purchaseInvoice.qty")}
                  rules={[
                    {
                      required: true,
                      message: t("purchaseInvoice.pleaseEnterQty"),
                    },
                  ]}
                >

                  <InputNumber
                    placeholder={t("purchaseInvoice.enterQty")}
                    style={{ borderRadius: "10px", width: "100%" }}
                    size="large"
                    onChange={onChangeQty}

                  />

                </Form.Item>

                <Form.Item
                  name="price"
                  label={t("purchaseInvoice.price")}
                  rules={[
                    {
                      required: true,
                      message: t("purchaseInvoice.pleaseEnterPrice"),
                    },
                  ]}
                >

                  <InputNumber
                    placeholder={t("purchaseInvoice.enterPrice")}
                    style={{ borderRadius: "10px", width: "100%" }}
                    size="large"
                    onChange={onChangePrice}
                  />

                </Form.Item>


                <Form.Item
                  name="subtotal"
                  label={t("purchaseInvoice.subtotal")}
                  rules={[
                    {
                      required: true,
                      message: t("purchaseInvoice.pleaseEnterSubtotal"),
                    },
                  ]}
                >

                  <InputNumber
                    placeholder={t("purchaseInvoice.enterSubtotal")}
                    style={{ borderRadius: "10px", width: "100%" }}
                    size="large"

                  />

                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                  <Checkbox style={{ padding: "0 20px" }} onChange={onChangeFoc} checked={isFoc}>FOC</Checkbox>
                  <Button
                    style={{
                      backgroundColor: "var(--primary-color)",
                      color: "var(--white-color)",
                      borderRadius: "10px",
                    }}
                    size="medium"
                    htmlType="submit"
                  >
                    <PlusSquareOutlined />
                    {t("component.add")}
                  </Button>
                </Form.Item>
              </Form>
            </Col>

            <Col>
            </Col>
          </Row>

          <Table
            bordered
            columns={columns}
            pagination={{ position: ["none", "none"] }}
            dataSource={purchaseItems}
          />

          {/* Total status row */}
          <Row justify="space-evenly" style={{ border: "1px solid var(--info-color)" }}>
            <Col xl={{ span: 7 }} md={{ span: 12 }}>
              <Space
                direction="horizontal"
                style={{ width: "100%", justifyContent: "center", margin: "20px" }}
              >
                <Label text={t("purchaseInvoice.paidAmount")} />
                <InputNumber
                  placeholder={t("purchaseInvoice.enterPaidAmount")}
                  style={{ borderRadius: "10px", width: "100%" }}
                  size="medium"
                  onChange={onChangePaidTotal}
                />
              </Space>
            </Col>
            <Col xl={{ span: 7 }} md={{ span: 12 }}>
              <Space
                direction="horizontal"
                style={{ width: "100%", justifyContent: "center", margin: "20px" }}
              >
                <Label text={t("purchaseInvoice.creditAmount")} />
                <ShowText text={creditTotal} />
              </Space>
            </Col>
            <Col xl={{ span: 7 }} md={{ span: 12 }}>
              <Space
                direction="horizontal"
                style={{ width: "100%", justifyContent: "center", margin: "20px" }}
              >
                <Label text={t("purchaseInvoice.total")} />
                <ShowText text={total} />
              </Space>
            </Col>
            <Col></Col>
          </Row>

          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "right" }}
          >
            <Button
              style={{
                backgroundColor: "var(--primary-color)",
                color: "var(--white-color)",
                borderRadius: "10px",
              }}
              size="large"
              onClick={handleSave}
            >
              <SaveOutlined />
              {t("component.save")}
            </Button>
          </Space>

        </Space>

      </Layout>
    </Spin>
  );
};


export default CreatePurchaseInvoice;
