import { DELETE_EXPENSE, SET_EXPENSE, SET_EXPENSES } from "../type";
  const initialState = {
    expenses: [],
    expense: {},
  };
  
  const expense = (state = initialState, action) => {
    switch (action.type) {
      case SET_EXPENSE:
        return {
          ...state,
          expense: action.payload,
        };
      case SET_EXPENSES:
        return {
          ...state,
          expenses: action.payload,
        };
      case DELETE_EXPENSE:
        return {
          ...state,
          expenses: state.expenses.filter((expense) => expense.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default expense;
  