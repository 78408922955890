import { DELETE_PRICE_HISTORY, SET_PRICE_HISTORIES, SET_PRICE_HISTORY } from "../type";

const initialState = {
  priceHistories: [],
  priceHistory: {},
};

const priceHistory = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRICE_HISTORIES:
      return {
        ...state,
        priceHistories: action.payload,
      };
    case SET_PRICE_HISTORY:
      return {
        ...state,
        priceHistory: action.payload,
      };
    case DELETE_PRICE_HISTORY:
      return {
        ...state,
        priceHistories: state.priceHistories.filter(
          (priceHistory) => priceHistory.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default priceHistory;
