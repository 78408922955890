import React from "react";
import {
  Form,
  Typography,
  Space,
  Spin,
  Row,
  Col,
  InputNumber,
  Select,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import { SaveOutlined } from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getStocks } from "../../store/actions";
import { createUsedItem } from "../../store/actions";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;
const CreateUsedItem = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const stocks = useSelector((state) => state.stock.stocks);
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getStocks())
  }, [dispatch])

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(t("message.successCreateMessage"));
    }
    return () => status.success;
  }, [status.success, t]);


  const onFinish = async (values) => {
    await dispatch(createUsedItem(values));
    form.resetFields();
  }

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }} >
        <Space direction="vertical">
          <Title level={3}>
            {t("usedItem.createUsedItem")}
          </Title>
          <Row >
            <Col span={12}>
              <Form
                colon={false}
                labelCol={{
                  xl: {
                    span: 8,
                  },
                }}
                wrapperCol={{
                  xl: {
                    span: 24,
                  },
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                form={form}
                layout={"vertical"}
              >
                <Form.Item
                  name="stock_id"
                  label={t("usedItem.itemNameCode")}
                  rules={[
                    {
                      required: true,
                      message: t("usedItem.pleaseSelectItem"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={t("usedItem.selectItemNameCode")}
                    optionFilterProp="children"
                    allowClear={true}
                    size="large"
                    style={{ borderRadius: "10px" }}
                  >

                    {stocks.map((stock) => (
                      <Option key={stock.id} value={stock.id}>
                        {stock.product.name} ( {stock.product.code} )
                      </Option>
                    ))}

                  </Select>
                </Form.Item>
                <Form.Item
                  name="quantity"
                  label={t("usedItem.qty")}
                  rules={[
                    {
                      required: true,
                      message: t("usedItem.pleaseEnterQty"),
                    },
                  ]}
                >
                  <InputNumber
                    placeholder={t("usedItem.enterQty")}
                    style={{ borderRadius: "10px", width: "100%" }}
                    size="large"
                  />
                </Form.Item>

                <Form.Item style={{ textAlign: "right" }}>
                  <LargeButton icon={<SaveOutlined />} text={t("component.save")} />
                </Form.Item>
              </Form>
            </Col>

            <Col xl={{ span: 12 }}>

            </Col>
          </Row>


        </Space>


      </Layout>
    </Spin>
  );
};



export default CreateUsedItem;
