import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en } from "./locales/en-US";
import { mm } from "./locales/mm";
console.log(en);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    lng: 'en', // default language
    interpolation: { escapeValue: false },
    resources: {
      en: {
        translation: en,
      },
      mm: {
        translation: mm,
      },
    },
  });

export default i18n;
