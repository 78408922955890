import React from "react";
import {
  Form,
  Typography,
  Space,
  Spin,
  Row,
  Col,
  DatePicker,
  Select,
  Input,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import { SaveOutlined } from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSalaryAndBenefit, getStaffs } from "../../store/actions";
import { getCommissions } from "../../store/actions/commission";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;

const CreateSalary = () => {
  const [form] = Form.useForm();
  const staffs = useSelector((state) => state.staff.staffs);
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const commissions = useSelector((state) => state.commission.commissions);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let saleAmount = 0;
  let year;
  let month;

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      form.resetFields();
      message.success(t("message.successCreateMessage"));
    }

    return () => status.success;
  }, [form, status.success, t]);


  useEffect(() => {
    dispatch(getStaffs());
    dispatch(getCommissions());
  }, [dispatch]);

  const onChange = (_, dateString) => {
    year = dateString.slice(0, 4);
    month = dateString.slice(5);
  };

  const onChangeStaff = (value) => {
    const index = staffs.findIndex((staff) => staff.id === value);
    const selectedStaff = staffs[index];
    console.log("selectedStaff:", selectedStaff)
    form.setFieldsValue({ salary: selectedStaff.salary });
  }



  const onChangeSaleAmount = (e) => {
    saleAmount = e.target.value;
  }

  const onChangeCommissionRate = (id) => {
    const index = commissions.findIndex((commission) => commission.id === id);
    const selectedCommission = commissions[index];
    const percentage = selectedCommission.commercial_percent;
    const commission_amount = saleAmount * percentage / 100;
    form.setFieldsValue({ commercial_amount: commission_amount })
  }

  const onFinish = async (values) => {
    const data = {
      ...values,
      month: parseInt(month),
      year: parseInt(year),
    }
    await dispatch(createSalaryAndBenefit(data));
    form.resetFields();
  }

  return (
    <Spin spinning={false}>
      <Layout style={{ margin: "20px 40px" }} >
        <Space direction="vertical">
          <Title level={3}>
            {t("salary.createSalary")}
          </Title>
          <DatePicker onChange={onChange} picker="month" />
          <Row >
            <Col span={12} >
              <Form
                colon={false}
                labelCol={{
                  xl: {
                    span: 8,
                  },
                }}
                wrapperCol={{
                  xl: {
                    span: 24,
                  },
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                form={form}
                layout={"vertical"}
              >
                <Form.Item
                  name="staff_id"
                  label={t("salary.staffNameCode")}
                  rules={[
                    {
                      required: true,
                      message: t("salary.pleaseSelectStaff"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={t("salary.selectStaff")}
                    optionFilterProp="children"
                    allowClear={true}
                    size="large"
                    style={{ borderRadius: "10px" }}
                    onChange={onChangeStaff}
                  >
                    {staffs.map((staff) => (
                      <Option value={staff.id} key={staff.id}>{staff.name} ( {staff.code} )</Option>
                    ))}

                  </Select>
                </Form.Item>

                {/* salary is not required , just for show , can remove */}
                <Form.Item
                  name="salary"
                  label={t("salary.salary")}
                  rules={[
                    {
                      required: true,
                      message: t("salary.pleaseEnterSalary"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("salary.enterSalary")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="sale_amount"
                  label={t("salary.saleAmount")}
                  rules={[
                    {
                      required: true,
                      message: t("salary.pleaseEnterSaleAmount"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("salary.enterSaleAmount")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                    onChange={onChangeSaleAmount}
                  />
                </Form.Item>
                <Form.Item
                  name="commercial_id"
                  label={t("salary.commissionRate")}
                  rules={[
                    {
                      required: true,
                      message: t("salary.pleaseSelectCommissionRate"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={t("salary.selectCommissionRate")}
                    optionFilterProp="children"
                    allowClear={true}
                    size="large"
                    style={{ borderRadius: "10px" }}
                    onChange={onChangeCommissionRate}
                  >
                    {commissions.map((commission) => (
                      <Option key={commission.id} value={commission.id}>{commission.sale_rate_price} - {commission.commercial_percent} %</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="commercial_amount"
                  label={t("salary.commission")}
                  rules={[
                    {
                      required: true,
                      message: t("salary.pleaseEnterCommission"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("salary.enterCommission")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>

                <Form.Item style={{ textAlign: "right" }}>
                  <LargeButton icon={<SaveOutlined />} text={t("component.save")} />
                </Form.Item>
              </Form>
            </Col>


            <Col span={12}>

            </Col>
          </Row>


        </Space>


      </Layout>
    </Spin>
  );
};

export default CreateSalary;
