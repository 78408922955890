import { DELETE_STAFF, SET_STAFF, SET_STAFFS } from "../type";

  const initialState = {
    staffs: [],
    staff: {},
  };
  
  const staff = (state = initialState, action) => {
    switch (action.type) {
      case SET_STAFF:
        return {
          ...state,
         staff : action.payload,
        };
      case SET_STAFFS:
        return {
          ...state,
          staffs: action.payload,
        };
      case DELETE_STAFF:
        return {
          ...state,
          staffs: state.staffs.filter((staff) => staff.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default staff;
  