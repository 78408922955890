import React, { useEffect } from "react";
import {
    Typography,
    Space,
    Row,
    Col,
    Spin,
    DatePicker,
    Select,
    message,
    Card,
    Divider,
    Table,
    Radio
} from "antd";
import Layout from "antd/lib/layout/layout";
import Label from "../../components/Label";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDailyReports, getItemGrossProfit, getReport } from "../../store/actions/report";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DollarCircleOutlined, TeamOutlined, LineChartOutlined, BarChartOutlined, CopyrightOutlined } from "@ant-design/icons"
import ShowText from "../../components/ShowText";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from "react-i18next";
import { cod, credit } from "../../utils/invoiceType";
import { getItems } from "../../store/actions/item";
const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ShowReport = () => {
    const [isSaleReport, setIsSaleReport] = useState(false);
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const report = useSelector((state) => state.report.report);
    const items = useSelector((state) => state.item.items);
    const dailyReports = useSelector((state) => state.report.dailyReports);
    let itemGrossProfit = useSelector((state) => state.report.itemGrossProfit);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [sDate, setSDate] = useState("");
    const [eDate, setEDate] = useState("");
    const [itemId, setItemId] = useState();
    const { t } = useTranslation();
    const [grossProfitOption, setGrossProfitOption] = useState("byDay");
    const [type, setType] = useState(cod);
    const [filteredItems, setFilteredItems] = useState();


    const iconStyle = { fontSize: "30px", color: "var(--white-color)" };
    const statusCardStyle = {
        width: "100%",
        border: "1px solid var(--border-color)",
        borderRadius: "8px",
        padding: "10px"
    };

    useEffect(() => {
        dispatch(getItems());
    }, [dispatch])

    useEffect(() => {
        dispatch(getReport(searchParams));
    }, [dispatch, searchParams]);

    useEffect(() => {
        dispatch(getDailyReports({ start_date: startDate, end_date: endDate }));
    }, [dispatch, startDate, endDate]);

    useEffect(() => {
        dispatch(getItemGrossProfit({ start_date: sDate, end_date: eDate, type: type }));
    }, [dispatch, sDate, eDate, type]);

    useEffect(() => {
        if (itemId !== undefined) {
            const filteredData = itemGrossProfit?.filter((item) => item.id === itemId);
            setFilteredItems(filteredData);
        } else {
            setFilteredItems(itemGrossProfit);
        }

    }, [itemId, itemGrossProfit, type]);

    useEffect(() => {
        if (itemId === undefined) {
            setFilteredItems(itemGrossProfit);
        }
    }, [itemGrossProfit, itemId])

    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);


    const onChange = (value) => {
        if (value === "profit_loss") {
            setIsSaleReport(false)
        } else {
            setIsSaleReport(true)
        }
    };

    const onChangeType = (value) => {
        setType(value);
    }

    const onChangeRadio = (e) => {
        setGrossProfitOption(e.target.value);
    }

    const onChangeItem = (value) => {
        if (value === undefined) {
            setItemId();
        } else {
            setItemId(value);
        }

    }


    //for excel export (gross profit by date)
    const fileName = "DailyReport";
    const transformedData = dailyReports.map((dailyReport) => {
        return {
            Date: dailyReport.day,
            BuyTotal: dailyReport.buy_total,
            SaleTotal: dailyReport.total,
            IncomeSaleTotal: dailyReport.final_total,
            PaidAmount: dailyReport.paid,
            CreditAmount: dailyReport.credit,
            GrossProfit: dailyReport.gross_profit,
        };
    });


    //for excel export ( gross profit by item)
    const fileName1 = "ItemGrossProfit";
    const transformedData1 = filteredItems?.map((item) => {
        return {
            ItemCode: item.code,
            ItemName: item.name,
            Quantity: item.qty,
            SaleRevenue: item.saleRevenue,
            Capital: item.capital,
            GrossProfit: item.grossProfit
        }
    })


    itemGrossProfit = itemGrossProfit?.map((item) => {
        const capital = type === cod ? item.product.cod_buy_price * item.quantity : item.product.credit_buy_price * item.quantity;
        const grossProfit = item.subtotal - capital;
        return {
            id: item.product.id,
            code: item.product.code,
            name: item.product.name,
            qty: item.quantity,
            saleRevenue: item.subtotal,
            capital: capital,
            grossProfit: grossProfit,
            key: item.key
        }
    })

    const columns = [
        {
            title: t("report.date"),
            dataIndex: "day",
        },
        {
            title: t("report.capital"),
            dataIndex: "buy_total",
            align: "right"
        },
        {
            title: t("report.saleRevenue"),
            dataIndex: "total",
            align: "right"
        },
        {
            title: t("report.revenue"),
            dataIndex: "final_total",
            align: "right"
        },
        {
            title: t("report.paidAmount"),
            dataIndex: "paid",
            align: "right"
        },
        {
            title: t("report.creditAmount"),
            dataIndex: "credit",
            align: "right"
        },
        {
            title: t("report.grossProfit"),
            dataIndex: "gross_profit",
            sorter: (a, b) => a.gross_profit - b.gross_profit,
            align: "right"
        },
    ];

    const itemGrossProfitColumns = [
        {
            title: t("report.itemCode"),
            dataIndex: "code",
        },
        {
            title: t("report.itemName"),
            dataIndex: "name",
        },
        {
            title: t("report.qty"),
            dataIndex: "qty",
            align: "right",
            sorter: {
                compare: (a, b) => a.qty - b.qty,
                multiple: 2,
            },
        },
        {
            title: t("report.saleRevenue"),
            dataIndex: "saleRevenue",
            align: "right"
        },
        {
            title: t("report.capital"),
            dataIndex: "capital",
            align: "right"
        },
        {
            title: t("report.grossProfit"),
            dataIndex: "grossProfit",
            align: "right",
            sorter: {
                compare: (a, b) => a.grossProfit - b.grossProfit,
                multiple: 1,
            },
        },
    ]

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }}>
                <Space direction="vertical" size="middle">
                    <Title level={3}>{t("report.report")}</Title>
                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <RangePicker
                                onChange={(val) => {
                                    if (val === null) {
                                        navigate(`/admin/show-report`)
                                    }
                                    else {
                                        navigate(`/admin/show-report?start_date=${dayjs(
                                            val[0]
                                        ).format("YYYY-MM-DD")}&end_date=${dayjs(val[1]).format(
                                            "YYYY-MM-DD"
                                        )}`)
                                    }
                                }}
                            />
                        </Col>
                        <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <Label text={t("report.type")} />
                            <Select
                                showSearch
                                placeholder={t("report.selectType")}
                                optionFilterProp="children"
                                allowClear={true}
                                size="medium"
                                style={{ borderRadius: "10px" }}
                                onChange={onChange}
                            >

                                <Option value="profit_loss" key="1">
                                    {t("report.profitLoss")}
                                </Option>
                                <Option value="sale_report" key="2">
                                    {t("report.saleReport")}
                                </Option>

                            </Select>
                        </Col>
                        <Col></Col>
                    </Row>

                    {isSaleReport &&
                        <Space direction="vertical" style={{ width: "100%", justifyContent: "right", marginTop: "20px" }}>
                            <Title level={4}>{t("report.saleReport")}</Title>
                            <Row gutter={[5, 5]}>
                                <Col md={{ span: 8 }}>
                                    <Card
                                        title={t("report.codSaleAmount")}
                                        bordered={true}
                                        style={statusCardStyle}
                                    >
                                        <Space>
                                            <Card style={{ backgroundColor: "var(--primary-color)", borderRadius: "5px" }}><DollarCircleOutlined style={iconStyle} /></Card>
                                            <ShowText text={report.cod_total} />
                                        </Space>
                                    </Card>
                                </Col>
                                <Col md={{ span: 8 }}>
                                    <Card
                                        title={t("report.creditSaleAmount")}
                                        bordered={true}
                                        style={statusCardStyle}
                                    >
                                        <Space>
                                            <Card style={{ backgroundColor: "var(--yellow-color)", borderRadius: "5px" }}><DollarCircleOutlined style={iconStyle} /></Card>
                                            <ShowText text={report.credit_total} />
                                        </Space>
                                    </Card>
                                </Col>
                                <Col md={{ span: 8 }}>
                                    <Card
                                        title={t("report.saleRevenue")}
                                        bordered={true}
                                        style={statusCardStyle}
                                    >
                                        <Space>
                                            <Card style={{ backgroundColor: "var(--green-color)", borderRadius: "5px" }}><DollarCircleOutlined style={iconStyle} /></Card>
                                            <ShowText text={report.sale_total} />
                                        </Space>
                                    </Card>
                                </Col>
                            </Row>
                        </Space>

                    }
                    {!isSaleReport &&
                        <Space direction="vertical" style={{ width: "100%", justifyContent: "right", marginTop: "20px" }}>
                            <Title level={4}>{t("report.profitLoss")}</Title>

                            <Row gutter={[5, 5]}>
                                <Col xl={{ span: 4 }} md={{ span: 8 }}>
                                    <Card
                                        title={t("report.revenue")}
                                        bordered={true}
                                        style={statusCardStyle}
                                    >
                                        <Space>
                                            <Card style={{ backgroundColor: "var(--primary-color)", borderRadius: "5px" }}><DollarCircleOutlined style={iconStyle} /></Card>
                                            <ShowText text={report.sale_total === null ? 0 : Number(report.sale_total) + Number(report.supplier_change) + Number(report.customer_change)} />
                                        </Space>
                                    </Card>
                                </Col>
                                <Col xl={{ span: 4 }} md={{ span: 8 }}>
                                    <Card
                                        title={t("report.capital")}
                                        bordered={true}
                                        style={statusCardStyle}
                                    >
                                        <Space>
                                            <Card style={{ backgroundColor: "var(--yellow-color)", borderRadius: "5px" }}><BarChartOutlined style={iconStyle} /></Card>
                                            <ShowText text={report.buy_total} />
                                        </Space>
                                    </Card>
                                </Col>
                                <Col xl={{ span: 4 }} md={{ span: 8 }}>
                                    <Card
                                        title={t("report.salary")}
                                        bordered={true}
                                        style={statusCardStyle}
                                    >
                                        <Space>
                                            <Card style={{ backgroundColor: "var(--green-color)", borderRadius: "5px" }}><TeamOutlined style={iconStyle} /></Card>
                                            <ShowText text={report.salary} />
                                        </Space>
                                    </Card>
                                </Col>
                                <Col xl={{ span: 4 }} md={{ span: 8 }}>
                                    <Card
                                        title={t("report.commission")}
                                        bordered={true}

                                        style={{
                                            width: "100%",
                                            border: "1px solid var(--border-color)",
                                            borderRadius: "8px",
                                            padding: "10px"
                                        }}
                                    >
                                        <Space>
                                            <Card style={{ backgroundColor: "var(--primary-color)", borderRadius: "5px" }}><CopyrightOutlined style={iconStyle} /></Card>
                                            <ShowText text={report.commercial_total} />
                                        </Space>
                                    </Card>
                                </Col>
                                <Col xl={{ span: 4 }} md={{ span: 8 }}>
                                    <Card
                                        title={t("report.expense")}
                                        bordered={true}

                                        style={{
                                            width: "100%",
                                            border: "1px solid var(--border-color)",
                                            borderRadius: "8px",
                                            padding: "10px"
                                        }}
                                    >
                                        <Space>
                                            <Card style={{ backgroundColor: "var(--red-color)", borderRadius: "5px" }}><DollarCircleOutlined style={iconStyle} /></Card>
                                            <ShowText text={report.expense_total} />
                                        </Space>
                                    </Card>
                                </Col>
                                <Col xl={{ span: 4 }} md={{ span: 8 }}>
                                    <Card
                                        title={t("report.profit")}
                                        bordered={true}

                                        style={{
                                            width: "100%",
                                            border: "1px solid var(--border-color)",
                                            borderRadius: "8px",
                                            padding: "10px"
                                        }}
                                    >
                                        <Space>
                                            <Card style={{ backgroundColor: "var(--green-color)", borderRadius: "5px" }}><LineChartOutlined style={iconStyle} /></Card>
                                            <ShowText text={report.profit} />
                                        </Space>
                                    </Card>
                                </Col>
                            </Row>
                        </Space>
                    }
                </Space>
                <Divider />
                <Space direction="vertical" size="middle">
                    <Title level={4} >{t("report.grossProfit")}</Title>
                    <Radio.Group defaultValue="byDay" buttonStyle="outline" onChange={onChangeRadio} >
                        <Radio.Button value="byDay" >{t("report.grossProfitPerDay")}</Radio.Button>
                        <Radio.Button value="byItem" >{t("report.grossProfitPerItem")}</Radio.Button>
                    </Radio.Group>
                </Space>
                {
                    grossProfitOption === "byDay" &&
                    <Space direction="vertical" size="middle" style={{ marginTop: '30px' }}>
                        <Title level={4} >{t("report.grossProfitPerDay")}</Title>

                        <Row gutter={[16, 16]}>
                            <Col xl={{ span: 21 }} md={{ span: 18 }}>
                                <RangePicker
                                    onChange={(val) => {
                                        if (val === null) {
                                            setStartDate("");
                                            setEndDate("");
                                        }
                                        else {
                                            setStartDate(dayjs(val[0]).format("YYYY-MM-DD"));
                                            setEndDate(dayjs(val[1]).format("YYYY-MM-DD"));
                                        }
                                    }}
                                />
                            </Col>
                            <Col xl={{ span: 3 }} md={{ span: 6 }}>
                                <ExportToExcel apiData={transformedData} fileName={fileName} />
                            </Col>
                        </Row>

                        <Table
                            bordered
                            columns={columns}
                            pagination={{ defaultPageSize: 10 }}
                            dataSource={dailyReports}
                        />
                    </Space>
                }
                {
                    grossProfitOption === "byItem" &&
                    <Space direction="vertical" size="middle" style={{ marginTop: '30px' }}>
                        <Title level={4} >{t("report.grossProfitPerItem")}</Title>
                        <Row gutter={[16, 16]}>
                            <Col xl={{ span: 6 }} md={{ span: 8 }}>
                                <RangePicker
                                    onChange={(val) => {
                                        if (val === null) {
                                            setSDate("");
                                            setEDate("");
                                        }
                                        else {
                                            setSDate(dayjs(val[0]).format("YYYY-MM-DD"));
                                            setEDate(dayjs(val[1]).format("YYYY-MM-DD"));
                                        }
                                    }}
                                />
                            </Col>
                            <Col xl={{ span: 6 }} md={{ span: 8 }}>
                                <Label text={t("report.type")} />
                                <Select
                                    showSearch
                                    placeholder={t("report.selectType")}
                                    optionFilterProp="children"
                                    size="medium"
                                    style={{ borderRadius: "10px" }}
                                    onChange={onChangeType}
                                    value={type}
                                >

                                    <Option value={cod} key="1">
                                        COD
                                    </Option>
                                    <Option value={credit} key="2">
                                        CREDIT
                                    </Option>

                                </Select>
                            </Col>
                            <Col xl={{ span: 9 }} md={{ span: 8 }}>
                                <Label text={t("report.itemNameCode")} />
                                <Select
                                    showSearch
                                    placeholder={t("report.selectItemName")}
                                    optionFilterProp="children"
                                    size="medium"
                                    style={{ borderRadius: "10px" }}
                                    onChange={onChangeItem}
                                    allowClear={true}
                                >
                                    {items.map((item) => (
                                        <Option key={item.id} value={item.id}>{item.name}( {item.code} ) </Option>
                                    ))}

                                </Select>
                            </Col>

                            <Col xl={{ span: 3 }} md={{ span: 6 }}>
                                <ExportToExcel apiData={transformedData1} fileName={fileName1} />
                            </Col>
                        </Row>

                        <Table
                            bordered
                            columns={itemGrossProfitColumns}
                            pagination={{ defaultPageSize: 10 }}
                            dataSource={filteredItems}
                        />
                    </Space>
                }

            </Layout>
        </Spin>
    );
};

export default ShowReport;
