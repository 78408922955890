import { DELETE_STOCK, SET_LESS_STOCKS, SET_OUT_OF_STOCKS, SET_STOCK, SET_STOCKS } from "../type";

const initialState = {
    stocks: [],
    stock: {},
    outOfStocks: [],
    lessStocks: []
};

const stock = (state = initialState, action) => {
    switch (action.type) {
        case SET_STOCK:
            return {
                ...state,
                stock: action.payload,
            };
        case SET_STOCKS:
            return {
                ...state,
                stocks: action.payload,
            };
        case DELETE_STOCK:
            return {
                ...state,
                stocks: state.stocks.filter(
                    (stock) => stock.id !== action.payload
                ),
            };
        case SET_OUT_OF_STOCKS:
            return {
                ...state,
                outOfStocks: action.payload,
            };
        case SET_LESS_STOCKS:
            return {
                ...state,
                lessStocks: action.payload,
            };
        default:
            return state;
    }
};

export default stock;
