import React, { useEffect } from "react";
import {
  Form,
  Typography,
  Space,
  Spin,
  Row,
  Col,
  InputNumber,
  message,
  Input
} from "antd";
import Layout from "antd/lib/layout/layout";
import { SaveOutlined } from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { editUsedItem, getUsedItem } from "../../store/actions";
import {useTranslation} from "react-i18next";

const { Title } = Typography;
const EditUsedItem = () => {
  const [form] = Form.useForm();
  const param = useParams();
  const id = param.id;

  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const usedItem = useSelector((state) => state.usedItem.usedItem);

  const dispatch = useDispatch();
  const {t} = useTranslation();
   
  useEffect(() => {
    dispatch(getUsedItem(id))
  }, [dispatch, id]);

  useEffect(() => {
    error.message !== null && message.error(error.message);
    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(t("message.successEditMessage"));
    }
    return () => status.success;
  }, [form, status.success, t]);
 
  useEffect(() => {
    form.setFieldsValue({ item_code: usedItem.stock?.product?.name });
    form.setFieldsValue({ quantity: usedItem.quantity });
  }, [form, usedItem]);

  const onFinish = async (values) => {
    const formData = new FormData();
    formData.append("stock_id", usedItem.id);  //coz not editable, pass its original stock id
    formData.append("quantity", values.quantity);

    await dispatch(editUsedItem(id, formData));
  };

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }} >
        <Space direction="vertical">
          <Title level={3}>
            {t("usedItem.editUsedItem")}
          </Title>
          <Row >
            <Col span={12}>
              <Form
                colon={false}
                labelCol={{
                  xl: {
                    span: 8,
                  },
                }}
                wrapperCol={{
                  xl: {
                    span: 24,
                  },
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                form={form}
                layout={"vertical"}
              >
                 {(usedItem) && (
             
                <Form.Item
                  name="item_code"
                  label= {t("usedItem.itemName")}
                >

                  <Input
                    placeholder= {t("usedItem.enterItemName")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                    disabled={true}
                  />
                </Form.Item>)}

                <Form.Item
                  name="quantity"
                  label={t("usedItem.qty")}
                  rules={[
                    {
                      required: true,
                      message: t("usedItem.pleaseEnterQty"),
                    },
                  ]}
                >
                  <InputNumber
                    placeholder={t("usedItem.enterQty")}
                    style={{ borderRadius: "10px", width: "100%" }}
                    size="large"
                  />
                </Form.Item>

                <Form.Item style={{ textAlign: "right" }}>
                  <LargeButton icon={<SaveOutlined />} text={t("component.save")} />
                </Form.Item>
              </Form>
            </Col>

            <Col span={12}>

            </Col>
          </Row>


        </Space>


      </Layout>
    </Spin>
  );
};

export default EditUsedItem;
