import React, { useEffect } from "react";
import {
    Typography,
    Space,
    Row,
    Col,
    Table,
    Spin,
    Select,
    message
} from "antd";
import Layout from "antd/lib/layout/layout";
import Label from "../../components/Label";
import { useSelector, useDispatch } from "react-redux";
import { getPriceHistories } from "../../store/actions/priceHistory";
import { getReadableDateDisplay } from "../../utils/convertToHumanReadableTime";
import { getItems } from "../../store/actions";
import { useState } from "react";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from "react-i18next";
const { Title } = Typography;
const { Option } = Select;
const ShowPriceHistory = () => {
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const priceHistories = useSelector((state) => state.priceHistory.priceHistories);
    const items = useSelector((state) => state.item.items);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [filteredPriceHistories, setFilteredPriceHistories] = useState([]);

    useEffect(() => {
        error.message !== null && message.error(error.message);
        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        dispatch(getPriceHistories());
        dispatch(getItems());
    }, [dispatch])

    useEffect(() => {
        setFilteredPriceHistories(priceHistories);
    }, [priceHistories]);

    const onChange = (value) => {
        if (value === undefined) {
            setFilteredPriceHistories(priceHistories);
        }
        else {
            const filteredResult = priceHistories.filter((priceHistory) => priceHistory.product.name === value);
            setFilteredPriceHistories(filteredResult);
        }
    }

    //for excel export
    const fileName = "PriceHistory";
    const transformedData = filteredPriceHistories.map((priceHistory) => {
        return {
            Date: getReadableDateDisplay(priceHistory.created_at),
            Code: priceHistory.product.code,
            Name: priceHistory.product.name,
            CodBuyPrice: priceHistory.cod_buy_price,
            CodSalePrice: priceHistory.cod_sale_price,
            CreditBuyPrice: priceHistory.credit_buy_price,
            CreditSalePrice: priceHistory.credit_sale_price,
        };
    });

    const columns = [
        {
            title: t("item.date"),
            dataIndex: "created_at",
            render: (created_at) => getReadableDateDisplay(created_at)
        },
        {
            title: t("item.image"),
            dataIndex: "product",
            render: (product) => (
                <img src={product.image} alt="ပစ္စည်းပုံ" width={100} height={100} />
            ),
        },
        {
            title: t("item.code"),
            dataIndex: "product",
            render: (product) => product.code,
        },
        {
            title: t("item.name"),
            dataIndex: "product",
            render: (product) => product.name,
        },
        {
            title: t("item.codBuyPrice"),
            dataIndex: "cod_buy_price",
        },
        {
            title: t("item.codSalePrice"),
            dataIndex: "cod_sale_price",
        },
        {
            title: t("item.creditBuyPrice"),
            dataIndex: "credit_buy_price",
        },
        {
            title: t("item.creditSalePrice"),
            dataIndex: "credit_sale_price",
        },

    ];

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }}>
                <Space direction="vertical" size="middle">
                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 21 }} md={{ span: 18 }}>
                            <Title level={3}>{t("item.priceHistoryList")}</Title>
                        </Col>
                        <Col xl={{ span: 3 }} md={{ span: 6 }}>
                            <ExportToExcel apiData={transformedData} fileName={fileName} />
                        </Col>

                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xl={{ span: 6 }} md={{ span: 12 }}>
                            <Label text={t("item.itemName")} />
                            <Select
                                showSearch
                                placeholder={t("item.selectItemName")}
                                optionFilterProp="children"
                                allowClear={true}
                                size="medium"
                                style={{ borderRadius: "10px" }}
                                onChange={onChange}
                            >
                                {items.map((item) => (
                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                ))}

                            </Select>
                        </Col>
                    </Row>
                    <Table
                        bordered
                        columns={columns}
                        pagination={{ defaultPageSize: 10 }}
                        dataSource={filteredPriceHistories}
                    />
                </Space>
            </Layout>
        </Spin>
    );
};

export default ShowPriceHistory;
