import React, { useEffect } from "react";
import {
  Form,
  Typography,
  Space,
  Spin,
  Row,
  Col,
  Input,
  InputNumber,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import { SaveOutlined } from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import { useSelector, useDispatch } from "react-redux";
import { createCommission } from "../../store/actions/commission";
import {useTranslation} from "react-i18next";
const { Title } = Typography;

const CreateCommission = () => {
  const [form] = Form.useForm();
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onFinish = async (values) => {
    await dispatch(createCommission(values));  
  }
  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      form.resetFields();
      message.success(t("message.successCreateMessage"));
    }

    return () => status.success;
  }, [form, status.success, t]);


  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }} >
        <Space direction="vertical">
          <Title level={3}>
            {t("commission.createCommission")}
          </Title>
          
          <Row >
            <Col span={12} >
              <Form
                colon={false}
                labelCol={{
                  xl: {
                    span: 8,
                  },
                }}
                wrapperCol={{
                  xl: {
                    span: 24,
                  },
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                form={form}
                layout={"vertical"}
              >
               
                <Form.Item
                  name="sale_rate_price"
                  label= {t("commission.saleAmount")}
                  rules={[
                    {
                      required: true,
                      message:  t("commission.pleaseEnterSaleAmount"),
                    },
                  ]}
                >
                  <InputNumber
                    placeholder= {t("commission.enterSaleAmount")}
                    style={{ borderRadius: "10px", width: "100%" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="commercial_percent"
                  label= {t("commission.commissionRate")}
                  rules={[
                    {
                      required: true,
                      message:  t("commission.pleaseEnterCommissionRate"),
                    },
                  ]}
                >
                  <Input
                    placeholder= {t("commission.enterCommissionRate")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                    suffix="%"
                  />

                </Form.Item>

                <Form.Item style={{ textAlign: "right" }}>
                  <LargeButton icon={<SaveOutlined />} text= {t("component.save")} />
                </Form.Item>
              </Form>
            </Col>


            <Col span={12}>

            </Col>
          </Row>

        </Space>


      </Layout>
    </Spin>
  );
};

export default CreateCommission;
