import {
  DELETE_ITEM,
  SET_BEST_SELLER_ITEMS,
  SET_ITEM,
  SET_ITEMS,
  SET_SALE_ITEMS,
} from "../type";

const initialState = {
  items: [],
  item: {},
  bestSellerItems: [],
  saleItems: [],
};

const item = (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEM:
      return {
        ...state,
        item: action.payload,
      };
    case SET_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case DELETE_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
      };
    case SET_BEST_SELLER_ITEMS:
      return {
        ...state,
        bestSellerItems: action.payload,
      };
    case SET_SALE_ITEMS:
      return {
        ...state,
        saleItems: action.payload,
      };
    default:
      return state;
  }
};

export default item;
