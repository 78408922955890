import React, { useEffect } from "react";
import {
  Form,
  Typography,
  Space,
  Spin,
  Row,
  Col,
  Select,
  Input,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import { SaveOutlined } from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { registerUser } from "../../store/actions";
import { useTranslation } from "react-i18next";
import { owner, manager, cashier, saleman } from "../../utils/position";
const { Title } = Typography;
const { Option } = Select;
const CreateAccount = () => {
  const [form] = Form.useForm();
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      form.resetFields();
      message.success(t("message.successCreateMessage"));
    }

    return () => status.success;
  }, [form, status.success, t]);
  const onFinish = async (values) => {
    await dispatch(registerUser(values));
  };

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }} >
        <Space direction="vertical">
          <Title level={3}>
            {t("account.createAccount")}
          </Title>
          <Row >
            <Col span={12}>
              <Form
                colon={false}
                labelCol={{
                  xl: {
                    span: 8,
                  },
                }}
                wrapperCol={{
                  xl: {
                    span: 24,
                  },
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                form={form}
                layout={"vertical"}
              >
                <Form.Item
                  name="name"
                  label={t("account.name")}
                  rules={[
                    {
                      required: true,
                      message: t("account.pleaseEnterName"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("account.enterName")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label={t("account.phone")}
                  rules={[
                    {
                      required: true,
                      message: t("account.pleaseEnterPhone"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("account.enterPhone")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="position"
                  label={t("account.position")}
                  rules={[
                    {
                      required: true,
                      message: t("account.pleaseSelectPosition"),
                    },
                  ]}
                >


                  <Select
                    showSearch
                    placeholder={t("staff.enterPosition")}
                    optionFilterProp="children"
                    allowClear={true}
                    size="large"
                    style={{ borderRadius: "10px" }}
                  // onChange={onChange}
                  >
                    <Option key={owner} value={owner}>
                      {t("account.owner")}
                    </Option>
                    <Option key={manager} value={manager}>
                      {t("account.manager")}
                    </Option>
                    <Option key={cashier} value={cashier}>
                      {t("account.cashier")}
                    </Option>
                    <Option key={saleman} value={saleman}>
                      {t("account.saleman")}
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                  <LargeButton icon={<SaveOutlined />} text={t("component.save")} />
                </Form.Item>
              </Form>
            </Col>


            <Col span={12}>

            </Col>
          </Row>


        </Space>


      </Layout>
    </Spin>
  );
};



export default CreateAccount;
