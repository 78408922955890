import React, { useState } from "react";
// ant design styles
import { Layout, Menu, Avatar, Space, Popover, Button, Typography, Row, Col, Image } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import {
    Link,
    Routes,
    Route,
    useLocation,
    Navigate
} from "react-router-dom";

// ant design icons
import {
    UserOutlined,
    DashboardOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    ShopOutlined,
    UnorderedListOutlined,
    PlusOutlined,
    StockOutlined,
    ShoppingOutlined,
    ShoppingCartOutlined,
    SwapOutlined,
    LockOutlined,
    UserAddOutlined,
    SolutionOutlined,
    GoldOutlined,
    MinusSquareOutlined,
    ImportOutlined,
    ExportOutlined,
    TeamOutlined,
    DollarCircleOutlined,
    FileExcelOutlined,
    FileTextOutlined,
    FolderOpenOutlined,
    BarChartOutlined,
    CarryOutOutlined,
    FrownOutlined,
    RetweetOutlined,
    GroupOutlined

} from "@ant-design/icons";

import Dashboard from "../pages/Dashboard";
import ChangePassword from "../pages/user/ChangePassword";
import ShowAccounts from "../pages/account/ShowAccounts";
import CreateAccount from "../pages/account/CreateAccount";
import ShowMerchants from "../pages/merchant/ShowMerchants";
import CreateMerchant from "../pages/merchant/CreateMerchant";
import ShowItems from "../pages/item/ShowItems";
import EditMerchant from "../pages/merchant/EditMerchant";
import EditItem from "../pages/item/EditItem";
import ShowStocks from "../pages/stock/showStocks";
import CreateStock from "../pages/stock/CreateStock";
import EditStock from "../pages/stock/EditStock";
import CreateItems from "../pages/item/CreateItems";
import ShowUsedItems from "../pages/used_item/ShowUsedItems";
import CreateUsedItem from "../pages/used_item/CreateUsedItem";
import CreateStaff from "../pages/staff/CreateStaff";
import ShowSalaries from "../pages/salary/ShowSalaries";
import CreateSalary from "../pages/salary/CreateSalary";
import ShowCommission from "../pages/commission/ShowCommission";
import CreateCommission from "../pages/commission/CreateCommission";
import EditUsedItem from "../pages/used_item/EditUsedItem";
import EditStaff from "../pages/staff/EditStaff";
import EditSalary from "../pages/salary/EditSalary";
import EditCommission from "../pages/commission/EditCommission";
import ShowSupplierExchanges from "../pages/supplier_exchange/ShowSupplierExchanges";
import CreateSupplierExchange from "../pages/supplier_exchange/CreateSupplierExchange";
import ShowSupplierExchangeDetails from "../pages/supplier_exchange/ShowSupplierExchangeDetails";
import EditSupplierExchange from "../pages/supplier_exchange/EditSupplierExchange";
import ShowCustomerExchanges from "../pages/customer_exchange/ShowCustomerExchanges";
import CreateCustomerExchange from "../pages/customer_exchange/CreateCustomerExchange";
import ShowCustomerExchangeDetails from "../pages/customer_exchange/ShowCustomerExchangeDetails";
import EditCustomerExchange from "../pages/customer_exchange/EditCustomerExchange";
import ShowPurchaseInvoices from "../pages/purchase_invoice/ShowPurchaseInvoices";
import CreatePurchaseInvoice from "../pages/purchase_invoice/CreatePurchaseInvoice";
import ShowPurchaseInvoiceDetails from "../pages/purchase_invoice/ShowPurchaseInvoiceDetails";
import ShowExpenses from "../pages/expense/ShowExpenses";
import CreateExpenses from "../pages/expense/CreateExpenses";
import CreateExpenseName from "../pages/expense_name/CreateExpenseName";
import EditExpenseName from "../pages/expense_name/EditExpenseName";
import ShowExpenseNames from "../pages/expense_name/ShowExpenseNames";
import EditExpense from "../pages/expense/EditExpense";
import ShowSaleItems from "../pages/sale_item/ShowSaleItems";
import ShowPurchaseRecords from "../pages/purchase_record/ShowPurchaseRecords";
import ShowFOCs from "../pages/foc/ShowFOCs";
import ShowPriceHistory from "../pages/price_history/ShowPriceHistory";
import ShowSaleInvoices from "../pages/sale_invoice/ShowSaleInvoices";
import ShowSaleInvoiceDetails from "../pages/sale_invoice/ShowSaleInvoiceDetails";
import CreateSaleCredit from "../pages/sale_invoice/CreateSaleCredit";
import CreatePurchaseCredit from "../pages/purchase_invoice/CreatePurchaseCredit";
import ShowStaffs from "../pages/staff/showStaffs";
import ShowStaffDetails from "../pages/staff/ShowStaffDetails";

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { logout } from "../store/actions";
import ShowOutOfStocks from "../pages/stock/ShowOutOfStocks";
import ShowCategories from "../pages/category/ShowCategories";
import CreateCategory from "../pages/category/CreateCategory";
import EditCategory from "../pages/category/EditCategory";
import ShowCustomers from "../pages/customer/ShowCustomers";
import CreateCustomer from "../pages/customer/CreateCustomer";
import EditCustomer from "../pages/customer/EditCustomer";
import ShowReport from "../pages/report/ShowReport";
import engFlag from "../assets/images/lang-eng.jpg";
import mmFlag from "../assets/images/lang-myan.png";
import { useTranslation } from "react-i18next";
import { saleman, owner, manager, cashier } from "../utils/position";
import CreateSaleInvoice from "../pages/sale_invoice/CreateSaleInvoice";

const { Header, Footer, Sider, Content } = Layout;
const { Title, Text } = Typography;
const text = (
    <Title level={4} style={{ textAlign: "center" }}>
        Profile
    </Title>
);

const Admin = () => {

    const [collapsed, setCollapsed] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogout = () => {
        dispatch(logout());
        navigate("/auth/login", { replace: true });
    };

    const user = useSelector((state) => state.auth.user);

    const content = (
        <Space direction="vertical" style={{ textAlign: "center", width: "100%" }}>
            <Title level={5}>
                {user.name}
            </Title>
            <Text>{user.position}</Text>
            <Button danger onClick={handleLogout}>
                Logout
            </Button>
        </Space>
    );

    const { t, i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState("en");
    const langs = [
        {
            label: "English",
            value: "en",
            image: engFlag
        },
        {
            label: "Myanmar",
            value: "mm",
            image: mmFlag
        }
    ];
    const handleLangChange = (lang) => {
        i18n.changeLanguage(lang);
        setSelectedLang(lang);
    }

    const [visible, setVisible] = useState(false);


    const langContent = (
        <Space direction="vertical" style={{ textAlign: "center", width: "100%" }}>
            {
                langs.map((lang) => (
                    <Button onClick={() => { handleLangChange(lang.value) }} type="link">
                        <Row style={{ width: "100px" }} >
                            <Col xl={{ span: 8 }} ><img src={lang.image} alt={lang.label} width={30} height={20} style={{ borderRadius: "3px" }} /></Col>
                            <Col xl={{ span: 16 }}><Text>{lang.label}</Text></Col>
                        </Row>
                    </Button>

                ))
            }
        </Space>
    );

    const menuIconStyle = { color: "#1890ff" };
    const { pathname } = useLocation();
    let selectedKey;
    switch (pathname) {
        case "/admin/dashboard": selectedKey = "Dashboard";
            break;
        case "/admin/create-sale-invoice": selectedKey = "SaleScreen";
            break;
        case "/admin/change-password": selectedKey = "ChangePassword";
            break;
        case "/admin/show-accounts": selectedKey = "ShowAccounts";
            break;
        case "/admin/create-account": selectedKey = "CreateAccount";
            break;
        case "/admin/show-merchants": selectedKey = "ShowMerchants";
            break;
        case "/admin/create-merchant": selectedKey = "CreateMerchant";
            break;
        case "/admin/show-customers": selectedKey = "ShowCustomers";
            break;
        case "/admin/create-customer": selectedKey = "CreateCustomer";
            break;
        case "/admin/show-items": selectedKey = "ShowItems";
            break;
        case "/admin/show-categories": selectedKey = "ShowCategories";
            break;
        case "/admin/show-stocks": selectedKey = "ShowStocks";
            break;
        case "/admin/show-purchase-invoices": selectedKey = "ShowPurchaseInvoices";
            break;
        case "/admin/create-purchase-invoice": selectedKey = "CreatePurchaseInvoice";
            break;
        case "/admin/show-used-items": selectedKey = "ShowUsedItems";
            break;
        case "/admin/show-supplier-exchanges": selectedKey = "ShowSupplierExchanges";
            break;
        case "/admin/create-supplier-exchange": selectedKey = "CreateSupplierExchange";
            break;
        case "/admin/show-customer-exchanges": selectedKey = "ShowCustomerExchanges";
            break;
        case "/admin/create-customer-exchange": selectedKey = "CreateCustomerExchange";
            break;
        case "/admin/show-staffs": selectedKey = "ShowStaffs";
            break;
        case "/admin/create-staff": selectedKey = "CreateStaff";
            break;
        case "/admin/show-salaries": selectedKey = "ShowSalaries";
            break;
        case "/admin/show-commission": selectedKey = "ShowCommission";
            break;
        case "/admin/show-expenses": selectedKey = "ShowExpenses";
            break;
        case "/admin/show-expense-names": selectedKey = "ShowExpenseNames";
            break;
        case "/admin/show-sale-items": selectedKey = "ShowSaleItems";
            break;
        case "/admin/show-sale-invoices": selectedKey = "ShowSaleInvoices";
            break;
        case "/admin/show-purchase-records": selectedKey = "ShowPurchaseRecords";
            break;
        case "/admin/show-focs": selectedKey = "ShowFOCs";
            break;
        case "/admin/show-price-history": selectedKey = "ShowPriceHistory";
            break;
        case "/admin/show-out-of-stocks": selectedKey = "ShowOutOfStocks";
            break;
        case "/admin/show-report": selectedKey = "ShowReport";
            break;

        default: selectedKey = "Dashboard";
    }

    const isSaleMan = user.position === saleman;
    const isOwner = user.position === owner;
    const isManager = user.position === manager;
    const isCashier = user.position === cashier;

    return (
        <Layout>
            <Header
                style={{ paddingTop: "13px", backgroundColor: "var(--white-color)" }}
            >
                <Button
                    style={{
                        float: "left",
                        backgroundColor: "var(--primary-color)",
                        color: "var(--white-color)",
                        marginRight: "3px",
                    }}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    {React.createElement(
                        collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
                    )}
                </Button>
                <Popover
                    placement="bottom"
                    content={content}
                    title={text}
                    trigger="click"

                >
                    <Avatar
                        style={{ float: "right", backgroundColor: "var(--primary-color)" }}
                        icon={<UserOutlined />}
                        size="large"

                    />
                </Popover>
                <Popover
                    placement="bottom"
                    content={<div onClick={() => { setVisible(!visible) }}>{langContent}</div>}
                    trigger="click"
                    visible={visible}
                >
                    <Button style={{ float: "right", marginRight: "50px" }} type="link" onClick={() => { setVisible(!visible) }}>
                        <Image src={selectedLang === "en" ? engFlag : mmFlag} alt="English" width={40} height={25} preview={false} style={{ borderRadius: "3px" }} />
                    </Button>

                </Popover>
                <Title style={{ color: "var(--primary-color)" }} level={3}>
                    DOCTOR GYI
                </Title>
            </Header>
            <Layout>
                <Sider
                    collapsed={collapsed}
                    style={{ backgroundColor: "var(--white-color)" }}
                >
                    <Menu mode="inline" defaultSelectedKeys={[selectedKey]}>
                        {(isManager || isCashier || isOwner) &&
                            <Menu.Item key="Dashboard" icon={<DashboardOutlined style={menuIconStyle} />}>
                                <Link to="/admin/dashboard">{t("admin.dashboard")}</Link>
                            </Menu.Item>
                        }

                        <Menu.Item key="SaleScreen" icon={<ShopOutlined style={menuIconStyle} />}>
                            <Link to="/admin/create-sale-invoice">{t("admin.saleScreen")}</Link>
                        </Menu.Item>

                        {(isManager || isCashier || isOwner) &&
                            <>
                                <Menu.Item key="ChangePassword" icon={<LockOutlined style={menuIconStyle} />}>
                                    <Link to="/admin/change-password">{t("admin.changePassword")}</Link>
                                </Menu.Item>
                                <SubMenu
                                    key="Accounts"
                                    title={t("admin.accounts")}

                                    icon={<UserOutlined style={menuIconStyle} />}
                                >
                                    <Menu.Item key="ShowAccounts" icon={<UnorderedListOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-accounts">{t("admin.list")}</Link>
                                    </Menu.Item>
                                    <Menu.Item key="CreateAccount" icon={<UserAddOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/create-account">{t("admin.addNew")}</Link>
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu
                                    key="Merchants"
                                    title={t("admin.merchants")}
                                    icon={<SolutionOutlined style={menuIconStyle} />}
                                >
                                    <Menu.Item key="ShowMerchants" icon={<UnorderedListOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-merchants">{t("admin.list")}</Link>
                                    </Menu.Item>
                                    <Menu.Item key="CreateMerchant" icon={<UserAddOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/create-merchant">{t("admin.addNew")}</Link>
                                    </Menu.Item>
                                </SubMenu>
                            </>
                        }

                        <SubMenu
                            key="Customers"
                            title={t("admin.customers")}
                            icon={<TeamOutlined style={menuIconStyle} />}
                        >
                            <Menu.Item key="ShowCustomers" icon={<UnorderedListOutlined style={menuIconStyle} />}>
                                <Link to="/admin/show-customers">{t("admin.list")}</Link>
                            </Menu.Item>
                            <Menu.Item key="CreateCustomer" icon={<UserAddOutlined style={menuIconStyle} />}>
                                <Link to="/admin/create-customer">{t("admin.addNew")}</Link>
                            </Menu.Item>
                        </SubMenu>



                        {(isManager || isCashier || isOwner) &&
                            <>
                                <SubMenu
                                    key="items"
                                    title={t("admin.items")}
                                    icon={<GoldOutlined style={menuIconStyle} />}
                                >
                                    <Menu.Item key="ShowItems" icon={<UnorderedListOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-items">{t("admin.itemList")}</Link>
                                    </Menu.Item>
                                    <Menu.Item key="ShowCategories" icon={<GroupOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-categories">{t("admin.categoryList")}</Link>
                                    </Menu.Item>
                                    <Menu.Item key="ShowStocks" icon={<StockOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-stocks"> {t("admin.stockList")}</Link>
                                    </Menu.Item>
                                    <Menu.Item key="ShowPurchaseInvoices" icon={<ShoppingOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-purchase-invoices"> {t("admin.purchaseInvoiceList")}</Link>
                                    </Menu.Item>
                                    <Menu.Item key="CreatePurchaseInvoice" icon={<ShoppingCartOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/create-purchase-invoice">{t("admin.addPurchaseInvoice")}</Link>
                                    </Menu.Item>
                                    <Menu.Item key="ShowUsedItems" icon={<MinusSquareOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-used-items">{t("admin.usedItemList")} </Link>
                                    </Menu.Item>

                                </SubMenu>
                                <SubMenu
                                    key="SupplierExchange"
                                    title={t("admin.supplierExchange")}
                                    icon={<ImportOutlined style={menuIconStyle} />}
                                >
                                    <Menu.Item key="ShowSupplierExchanges" icon={<UnorderedListOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-supplier-exchanges">{t("admin.list")} </Link>
                                    </Menu.Item>
                                    <Menu.Item key="CreateSupplierExchange" icon={<SwapOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/create-supplier-exchange">{t("admin.toExchangeItem")} </Link>
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu
                                    key="CustomerExchange"
                                    title={t("admin.customerExchange")}
                                    icon={<ExportOutlined style={menuIconStyle} />}
                                >
                                    <Menu.Item key="ShowCustomerExchanges" icon={<UnorderedListOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-customer-exchanges">{t("admin.list")}</Link>
                                    </Menu.Item>
                                    <Menu.Item key="CreateCustomerExchange" icon={<SwapOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/create-customer-exchange">{t("admin.toExchangeItem")} </Link>
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu
                                    key="Staffs"
                                    title={t("admin.staffs")}
                                    icon={<TeamOutlined style={menuIconStyle} />}
                                >
                                    <Menu.Item key="ShowStaffs" icon={<UnorderedListOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-staffs">{t("admin.list")} </Link>
                                    </Menu.Item>
                                    <Menu.Item key="CreateStaff" icon={<UserAddOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/create-staff">{t("admin.addNew")} </Link>
                                    </Menu.Item>
                                    <Menu.Item key="ShowSalaries" icon={<DollarCircleOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-salaries">{t("admin.salary")} </Link>
                                    </Menu.Item>
                                    <Menu.Item key="ShowCommission" icon={<PlusOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-commission">{t("admin.commission")} </Link>
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu
                                    key="Expenses"
                                    title={t("admin.expenses")}
                                    icon={<FileExcelOutlined style={menuIconStyle} />}
                                >
                                    <Menu.Item key="ShowExpenses" icon={<UnorderedListOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-expenses">{t("admin.list")} </Link>
                                    </Menu.Item>
                                    <Menu.Item key="ShowExpenseNames" icon={<FileTextOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-expense-names">{t("admin.expenseNames")} </Link>
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu
                                    key="GeneralList"
                                    title={t("admin.generalList")}
                                    icon={<FolderOpenOutlined style={menuIconStyle} />}
                                >
                                    <Menu.Item key="ShowSaleItems" icon={<BarChartOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-sale-items">{t("admin.saleItemList")} </Link>
                                    </Menu.Item>
                                    <Menu.Item key="ShowSaleInvoices" icon={<FileTextOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-sale-invoices">{t("admin.saleInvoiceList")} </Link>
                                    </Menu.Item>
                                    <Menu.Item key="ShowPurchaseRecords" icon={<ShoppingCartOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-purchase-records">{t("admin.purchaseRecord")} </Link>
                                    </Menu.Item>
                                    <Menu.Item key="ShowFOCs" icon={<CarryOutOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-focs">{t("admin.focList")} </Link>
                                    </Menu.Item>
                                    <Menu.Item key="ShowPriceHistory" icon={<RetweetOutlined style={menuIconStyle} />} >
                                        <Link to="/admin/show-price-history">{t("admin.priceChanges")} </Link>
                                    </Menu.Item>
                                    <Menu.Item key="ShowOutOfStocks" icon={<FrownOutlined style={menuIconStyle} />}>
                                        <Link to="/admin/show-out-of-stocks">{t("admin.outOfStockList")} </Link>
                                    </Menu.Item>
                                </SubMenu>
                            </>
                        }

                        {
                            isSaleMan &&
                            <>
                                <Menu.Item key="ShowSaleInvoices" icon={<FileTextOutlined style={menuIconStyle} />}>
                                    <Link to="/admin/show-sale-invoices">{t("admin.saleInvoiceList")} </Link>
                                </Menu.Item>
                                <Menu.Item key="ChangePassword" icon={<LockOutlined style={menuIconStyle} />}>
                                    <Link to="/admin/change-password">{t("admin.changePassword")}</Link>
                                </Menu.Item>
                            </>


                        }

                        {
                            isOwner &&
                            <Menu.Item key="ShowReport" icon={<FolderOpenOutlined style={menuIconStyle} />}>
                                <Link to="/admin/show-report">{t("admin.report")} </Link>
                            </Menu.Item>
                        }

                    </Menu>
                </Sider>
                <Layout>
                    <Content style={{ minHeight: "520px" }}>
                        {
                            (isManager || isOwner || isCashier) &&
                            <Routes>
                                <Route path="/" element={<Navigate to="/admin/dashboard" />} />
                                <Route path="dashboard" element={<Dashboard />} />
                                <Route path="create-sale-invoice" element={<CreateSaleInvoice />} />
                                <Route path="change-password" element={<ChangePassword />} />

                                <Route path="show-accounts" element={<ShowAccounts />} />
                                <Route path="create-account" element={<CreateAccount />} />

                                <Route path="show-merchants" element={<ShowMerchants />} />
                                <Route path="create-merchant" element={<CreateMerchant />} />
                                <Route path="edit-merchants/:id" element={<EditMerchant />} />

                                <Route path="show-customers" element={<ShowCustomers />} />
                                <Route path="create-customer" element={<CreateCustomer />} />
                                <Route path="edit-customers/:id" element={<EditCustomer />} />

                                <Route path="show-items" element={<ShowItems />} />
                                <Route path="create-items" element={<CreateItems />} />
                                <Route path="edit-items/:id" element={<EditItem />} />

                                <Route path="show-categories" element={<ShowCategories />} />
                                <Route path="create-category" element={<CreateCategory />} />
                                <Route path="edit-categories/:id" element={<EditCategory />} />

                                <Route path="show-stocks" element={<ShowStocks />} />
                                <Route path="create-stock" element={<CreateStock />} />
                                <Route path="edit-stocks/:id" element={<EditStock />} />

                                <Route path="show-purchase-invoices" element={<ShowPurchaseInvoices />} />
                                <Route path="create-purchase-invoice" element={<CreatePurchaseInvoice />} />
                                <Route path="show-purchase-invoices/:id" element={<ShowPurchaseInvoiceDetails />} />
                                <Route path="create-purchase-credits/:id" element={<CreatePurchaseCredit />} />

                                <Route path="show-used-items" element={<ShowUsedItems />} />
                                <Route path="create-used-item" element={<CreateUsedItem />} />
                                <Route path="edit-used-items/:id" element={<EditUsedItem />} />

                                <Route path="show-supplier-exchanges" element={<ShowSupplierExchanges />} />
                                <Route path="create-supplier-exchange" element={<CreateSupplierExchange />} />
                                <Route path="show-supplier-exchanges/:id" element={<ShowSupplierExchangeDetails />} />
                                <Route path="edit-supplier-exchanges/:id" element={<EditSupplierExchange />} />

                                <Route path="show-customer-exchanges" element={<ShowCustomerExchanges />} />
                                <Route path="create-customer-exchange" element={<CreateCustomerExchange />} />
                                <Route path="show-customer-exchanges/:id" element={<ShowCustomerExchangeDetails />} />
                                <Route path="edit-customer-exchanges/:id" element={<EditCustomerExchange />} />

                                <Route path="show-staffs" element={<ShowStaffs />} />
                                <Route path="create-staff" element={<CreateStaff />} />
                                <Route path="edit-staff/:id" element={<EditStaff />} />
                                <Route path="show-staffs/:id" element={<ShowStaffDetails />} />

                                <Route path="show-salaries" element={<ShowSalaries />} />
                                <Route path="create-salary" element={<CreateSalary />} />
                                <Route path="edit-salaries/:id" element={<EditSalary />} />

                                <Route path="show-commission" element={<ShowCommission />} />
                                <Route path="create-commission" element={<CreateCommission />} />
                                <Route path="edit-commissions/:id" element={<EditCommission />} />

                                <Route path="show-expenses" element={<ShowExpenses />} />
                                <Route path="create-expenses" element={<CreateExpenses />} />
                                <Route path="edit-expenses/:id" element={<EditExpense />} />

                                <Route path="create-expense-name" element={<CreateExpenseName />} />
                                <Route path="show-expense-names" element={<ShowExpenseNames />} />
                                <Route path="edit-expense-names/:id" element={<EditExpenseName />} />

                                {/* general-list */}
                                <Route path="show-sale-items" element={<ShowSaleItems />} />
                                <Route path="show-purchase-records" element={<ShowPurchaseRecords />} />

                                <Route path="show-focs" element={<ShowFOCs />} />

                                <Route path="show-price-history" element={<ShowPriceHistory />} />
                                <Route path="show-out-of-stocks" element={<ShowOutOfStocks />} />

                                {isOwner &&
                                    <Route path="show-report" element={<ShowReport />} />
                                }

                                <Route path="show-sale-invoices" element={<ShowSaleInvoices />} />
                                <Route path="show-sale-invoices/:id" element={<ShowSaleInvoiceDetails />} />
                                <Route path="create-sale-credits/:id" element={<CreateSaleCredit />} />
                            </Routes>
                        }
                        {
                            isSaleMan &&
                            <Routes>

                                <Route path="create-sale-invoice" element={<CreateSaleInvoice />} />
                                <Route path="show-sale-invoices" element={<ShowSaleInvoices />} />
                                <Route path="show-customers" element={<ShowCustomers />} />
                                <Route path="create-customer" element={<CreateCustomer />} />
                                <Route path="edit-customers/:id" element={<EditCustomer />} />
                                <Route path="show-sale-invoices/:id" element={<ShowSaleInvoiceDetails />} />
                                <Route path="create-sale-credits/:id" element={<CreateSaleCredit />} />
                                <Route path="change-password" element={<ChangePassword />} />
                            </Routes>
                        }

                    </Content>
                    <Footer
                        style={{
                            backgroundColor: "var(--white-color)",
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "var(--primary-color)",
                        }}
                    >
                        DOCTOR_GYI DEVELOPED BY RCS
                    </Footer>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default Admin;
