import { call } from "../../services/api";
import { deleteExchangeErrorMessage, serverErrorMessage } from "../../utils/messages";
import { ADD_ERROR, DELETE_SUPPLIER_EXCHANGE, REMOVE_ERROR, SET_LOADING, SET_SUCCESS, SET_SUPPLIER_EXCHANGE, SET_SUPPLIER_EXCHANGES } from "../type";

export const getSupplierExchanges = (searchParams) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("get", `supplier-changes?${searchParams}`);
      const result = response.data;

      const transformResult = result.map((data) => {
        return {
          ...data,
          key: data.id,
        };
      });
      dispatch({
        type: SET_SUPPLIER_EXCHANGES,
        payload: transformResult,
      });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: serverErrorMessage,
        });
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const createSupplierExchange = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    dispatch({ type: SET_LOADING });
    try {
      await call("post", "supplier-changes", data);  //create one supplier exchange (which includes supplier-exchanged-items)
      dispatch({ type: SET_SUCCESS, payload: true });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: serverErrorMessage,
        });
      }
      else {
        dispatch({
          type: ADD_ERROR,
          payload: data.data.message,
        });
      }

    }
    setTimeout(() => {
      dispatch({ type: SET_SUCCESS, payload: false });
    }, 1);
    dispatch({ type: SET_LOADING });
  };
};

export const deleteSupplierExchange = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    dispatch({ type: SET_LOADING });
    try {
      await call("delete", `supplier-changes/${id}`);

      dispatch({ type: SET_SUCCESS, payload: true });
      dispatch({ type: DELETE_SUPPLIER_EXCHANGE, payload: id });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: deleteExchangeErrorMessage,  //if internal server error, it is because there is no enough stock to return.
        });
      }

    }
    setTimeout(() => {
      dispatch({ type: SET_SUCCESS, payload: false });
    }, 1);
    dispatch({ type: SET_LOADING });
  };
};

export const getSupplierExchange = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("get", `supplier-changes/${id}`);
      const result = response.data;

      dispatch({
        type: SET_SUPPLIER_EXCHANGE,
        payload: result,
      });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: serverErrorMessage,
        });
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const confirmSupplierExchange = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    dispatch({ type: SET_LOADING });
    try {
      await call("post", `supplier-changes/${id}?_method=PUT`, data);

      dispatch({ type: SET_SUCCESS, payload: true });
      dispatch({
        type: REMOVE_ERROR,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem("jwtToken");
        dispatch({
          type: ADD_ERROR,
          payload: data.message,
        });
      }

      if (status === 500) {
        dispatch({
          type: ADD_ERROR,
          payload: serverErrorMessage,
        });
      }
    }
    setTimeout(() => {
      dispatch({ type: SET_SUCCESS, payload: false });
    }, 1);
    dispatch({ type: SET_LOADING });
  };
};
