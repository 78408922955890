import React, { useEffect } from "react";
import { Form, Input, Typography, Space, Spin, Row, Col, message } from "antd";
import Layout from "antd/lib/layout/layout";
import { SaveOutlined } from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import { useParams } from "react-router-dom";
import { editMerchant, getMerchant } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useTranslation } from "react-i18next";
const { Title } = Typography;
const { TextArea } = Input;

const EditMerchant = () => {
    const [form] = Form.useForm();
    const param = useParams();
    const dispatch = useDispatch();
    const merchant = useSelector((state) => state.merchant.merchant);
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);
    const { t } = useTranslation();

    const id = param.id;

    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        if (status.success) {
            message.success(t("message.successEditMessage"));
        }

        return () => status.success;
    }, [form, status.success, t]);

    useEffect(() => {
        dispatch(getMerchant(id));
    }, [dispatch, id]);

    useEffect(() => {
        form.setFieldsValue({ code: merchant.code });
        form.setFieldsValue({ name: merchant.name });
        form.setFieldsValue({ company_name: merchant.company_name });
        form.setFieldsValue({ company_phone: merchant.company_phone });
        form.setFieldsValue({ company_email: merchant.company_email });
        form.setFieldsValue({ company_address: merchant.company_address });
        form.setFieldsValue({ remark: merchant.remark });
    }, [form, merchant]);

    const onFinish = async (values) => {
        await dispatch(editMerchant(id, values));
    };

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }} >
                <Space direction="vertical">
                    <Title level={3}>
                        {t("merchant.editMerchant")}
                    </Title>
                    <Row >
                        <Col span={12} >
                            <Form
                                colon={false}
                                labelCol={{
                                    xl: {
                                        span: 8,
                                    },
                                }}
                                wrapperCol={{
                                    xl: {
                                        span: 24,
                                    },
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                form={form}
                                layout={"vertical"}
                            >
                                <Form.Item
                                    name="code"
                                    label={t("merchant.code")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("merchant.pleaseEnterCode"),
                                        },
                                    ]}
                                >


                                    <Input
                                        placeholder={t("merchant.enterCode")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="name"
                                    label={t("merchant.name")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("merchant.pleaseEnterName"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("merchant.enterName")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="company_name"
                                    label={t("merchant.comName")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("merchant.pleaseEnterComName"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("merchant.enterCode")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="company_phone"
                                    label={t("merchant.comPhone")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("merchant.pleaseEnterComPhone"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("merchant.enterComPhone")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="company_email"
                                    label={t("merchant.comEmail")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("merchant.pleaseEnterComEmail"),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t("merchant.enterComEmail")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="company_address"
                                    label={t("merchant.comAddress")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("merchant.pleaseEnterComAddress"),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t("merchant.enterComAddress")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="remark"
                                    label={t("merchant.remark")}
                                >
                                    <TextArea maxLength={100} style={{ height: 120 }} onChange={() => { }} />
                                </Form.Item>
                                <Form.Item style={{ textAlign: "right" }}>
                                    <LargeButton icon={<SaveOutlined />} text={t("component.save")} />
                                </Form.Item>
                            </Form>
                        </Col>

                        <Col xl={{ span: 12 }}>

                        </Col>
                    </Row>


                </Space>
            </Layout>
        </Spin>
    );
};
export default EditMerchant;