import React from "react";
import {
  Form,
  Typography,
  Space,
  Spin,
  Row,
  Col,
  DatePicker,
  Input,
  Select,
  message

} from "antd";
import Layout from "antd/lib/layout/layout";
import { SaveOutlined } from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { editSalaryAndBenefit, getSalaryAndBenefit } from "../../store/actions";
import { getCommissions } from "../../store/actions/commission";
import moment from 'moment';
const { Title } = Typography;
const { Option } = Select;
const EditSalary = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const salary = useSelector((state) => state.salaryAndBenefit.salaryAndBenefit)
  const commissions = useSelector((state) => state.commission.commissions);

  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  let year;
  let month;

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(t("message.successEditMessage"));
    }

    return () => status.success;
  }, [form, status.success, t]);

  useEffect(() => {
    dispatch(getCommissions());
  }, [dispatch])
  console.log("commisssions:", commissions)

  useEffect(() => {
    dispatch(getSalaryAndBenefit(id));
  }, [dispatch, id]);

  console.log("Salary:", salary)

  useEffect(() => {
    form.setFieldsValue({ staff_id: salary?.staff?.name });
    form.setFieldsValue({ salary: salary?.staff?.salary });
    form.setFieldsValue({ commercial_id: salary?.commercial?.id });
    form.setFieldsValue({ commercial_amount: salary?.commercial_amount });
  }, [form, salary]);

  const onFinish = async (values) => {
    const data = {
      ...values,
      staff_id: salary?.staff?.id,
      month: parseInt(month),
      year: parseInt(year),
    }
    await dispatch(editSalaryAndBenefit(id, data));
  };

  const onChange = (_, dateString) => {
    console.log("Date String:", dateString)
    year = dateString.slice(0, 4);
    month = dateString.slice(5);
    console.log("month:", month);
    console.log("year", year)
  };


  return (
    <Spin spinning={false}>
      <Layout style={{ margin: "20px 40px" }} >
        <Space direction="vertical">
          <Title level={3}>
            {t("salary.editSalary")}
          </Title>
          <DatePicker onChange={onChange} picker="month" defaultValue={moment(`${salary?.year}-${salary?.month}`, 'YYYY-MM')} />
          <Row >
            <Col span={12} >
              <Form
                colon={false}
                labelCol={{
                  xl: {
                    span: 8,
                  },
                }}
                wrapperCol={{
                  xl: {
                    span: 24,
                  },
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                form={form}
                layout={"vertical"}
              >
                <Form.Item
                  name="staff_id"
                  label={t("salary.staffName")}
                  rules={[
                    {
                      required: true,
                      message: t("salary.pleaseSelectStaff"),
                    },
                  ]}
                >
                  <Input
                    // placeholder={salary.staff.name}
                    style={{ borderRadius: "10px" }}
                    size="large"
                    disabled={true}
                  />
                </Form.Item>

                {/* salary is not required , just for show , can remove */}
                <Form.Item
                  name="salary"
                  label={t("salary.salary")}
                  rules={[
                    {
                      required: true,
                      message: t("salary.pleaseEnterSalary"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("salary.enterSalary")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  name="sale_amount"
                  label={t("salary.saleAmount")}
                // rules={[
                //   {
                //     required: true,
                //     message: t("salary.pleaseEnterSaleAmount"),
                //   },
                // ]}
                >
                  <Input
                    placeholder={t("salary.enterSaleAmount")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                    onChange={() => { }}
                  />
                </Form.Item>
                <Form.Item
                  name="commercial_id"
                  label={t("salary.commissionRate")}
                  rules={[
                    {
                      required: true,
                      message: t("salary.pleaseSelectCommissionRate"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={t("salary.selectCommissionRate")}
                    optionFilterProp="children"
                    allowClear={true}
                    size="large"
                    style={{ borderRadius: "10px" }}
                    onChange={() => { }}
                  >
                    {commissions.map((commission) => (
                      <Option key={commission.id} value={commission.id}>{commission.sale_rate_price} - {commission.commercial_percent} %</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="commercial_amount"
                  label={t("salary.commission")}
                  rules={[
                    {
                      required: true,
                      message: t("salary.pleaseEnterCommission"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("salary.enterCommission")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>

                <Form.Item style={{ textAlign: "right" }}>
                  <LargeButton icon={<SaveOutlined />} text={t("component.save")} />
                </Form.Item>
              </Form>
            </Col>


            <Col span={12}>

            </Col>
          </Row>


        </Space>


      </Layout>
    </Spin>
  );
};

export default EditSalary;
