import React,{useEffect} from "react";
import { Form, Input, Typography, Space, Spin, Row, Col, message } from "antd";
import Layout from "antd/lib/layout/layout";
import { SaveOutlined } from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import { useSelector, useDispatch } from "react-redux";
import { createCustomer } from "../../store/actions/customer";
import { useTranslation } from 'react-i18next';

const { Title } = Typography;
const CreateCustomer = () => {
    const status = useSelector((state) => state.status);
    const error = useSelector((state) => state.error);

    const dispatch = useDispatch();
    const { t} = useTranslation();

    const [form] = Form.useForm();
    const onFinish = async (values) => {
        await dispatch(createCustomer(values));
    }
    useEffect(() => {
        error.message !== null && message.error(error.message);

        return () => error.message;
    }, [error.message]);

    useEffect(() => {
        if (status.success) {
            form.resetFields();
            message.success(t("message.successCreateMessage"));
        }

        return () => status.success;
    }, [form, status.success, t]);

    return (
        <Spin spinning={status.loading}>
            <Layout style={{ margin: "20px 40px" }} >
                <Space direction="vertical">
                    <Title level={3}>
                    {t("customer.createCustomer")}
                    </Title>
                    <Row >
                        <Col span={12}>
                            <Form
                                colon={false}
                                labelCol={{
                                    xl: {
                                        span: 8,
                                    },
                                }}
                                wrapperCol={{
                                    xl: {
                                        span: 24,
                                    },
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                form={form}
                                layout={"vertical"}
                            >
                                <Form.Item
                                    name="code"
                                    label={t("customer.code")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("customer.pleaseEnterCode"),
                                        },
                                    ]}
                                >


                                    <Input
                                        placeholder={t("customer.enterCode")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="name"
                                    label={t("customer.name")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("customer.pleaseEnterName"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("customer.enterName")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="phone"
                                    label={t("customer.phone")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("customer.pleaseEnterPhone"),
                                        },
                                    ]}
                                >

                                    <Input
                                        placeholder={t("customer.enterPhone")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />

                                </Form.Item>
                                <Form.Item
                                    name="address"
                                    label={t("customer.address")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("customer.pleaseEnterAddress"),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t("customer.enterAddress")}
                                        style={{ borderRadius: "10px" }}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item style={{ textAlign: "right" }}>
                                    <LargeButton icon={<SaveOutlined />} text={t("component.save")} />
                                </Form.Item>
                            </Form>
                        </Col>


                        <Col xl={{ span: 12 }}>

                        </Col>
                    </Row>


                </Space>
            </Layout>


        </Spin>
    );
};

export default CreateCustomer;
