import { call } from "../../services/api";
import { serverErrorMessage } from "../../utils/messages";
import { ADD_ERROR, REMOVE_ERROR, SET_LOADING, SET_PRICE_HISTORIES } from "../type";

export const getPriceHistories = () => {
    return async (dispatch) => {
      dispatch({ type: SET_LOADING });
      try {
        const response = await call("get", "price-tracks");
        const result = response.data;
  
        const transformResult = result.map((data) => {
          return {
            ...data,
            key: data.id,
          };
        });
        dispatch({
          type: SET_PRICE_HISTORIES,
          payload: transformResult,
        });
        dispatch({
          type: REMOVE_ERROR,
        });
      } catch (error) {
        const { status, data } = error.response;
  
        if (status === 401) {
          localStorage.removeItem("jwtToken");
          dispatch({
            type: ADD_ERROR,
            payload: data.message,
          });
        }
  
        if (status === 500) {
          dispatch({
            type: ADD_ERROR,
            payload: serverErrorMessage,
          });
        }
      }
      dispatch({ type: SET_LOADING });
    };
  };
  