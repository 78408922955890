import React, { useState, useEffect } from "react";
import {
  Form,
  Typography,
  Space,
  Spin,
  Row,
  Col,
  Input,
  Select,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
  SaveOutlined,
} from "@ant-design/icons";
import LargeButton from "../../components/LargeButton";
import InputUpload from "../../components/InputUpload";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { editItem, getCategories, getItem } from "../../store/actions";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;
const { Option } = Select;
const EditItem = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const param = useParams();
  const id = param.id;

  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const item = useSelector((state) => state.item.item);
  const categories = useSelector((state) => state.category.categories);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch])

  useEffect(() => {
    dispatch(getItem(id))
  }, [dispatch, id]);

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(t("message.successEditMessage"));
    }

    return () => status.success;
  }, [form, status.success, t]);

  useEffect(() => {
    form.setFieldsValue({ code: item.code });
    form.setFieldsValue({ category: item.category?.name });
    form.setFieldsValue({ name: item.name });
    form.setFieldsValue({ cod_buy_price: item.cod_buy_price });
    form.setFieldsValue({ cod_sale_price: item.cod_sale_price });
    form.setFieldsValue({ credit_buy_price: item.credit_buy_price });
    form.setFieldsValue({ credit_sale_price: item.credit_sale_price });
    setFileList([
      {
        uid: item.id,
        name: item.name?.name,
        status: "done",
        url: item.image,
      },
    ]);

  }, [form, item]);

  const onFinish = async (values) => {
    const formData = new FormData();
    formData.append("code", values.code);
    //if values.category is string type, it means user does not select category again ,so pass original category.id that need in api
    formData.append("category_id", typeof values.category === 'string' ? item.category.id : values.category);
    formData.append("name", values.name);
    formData.append("cod_buy_price", values.cod_buy_price);
    formData.append("cod_sale_price", values.cod_sale_price);
    formData.append("credit_buy_price", values.credit_buy_price);
    formData.append("credit_sale_price", values.credit_sale_price);

    if (fileList[0].status !== "done") {
      formData.append("image", fileList[0].originFileObj);
    }
    await dispatch(editItem(id, formData));
  };

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }} >
        <Space direction="vertical">
          <Title level={3}>
            {t("item.editItem")}
          </Title>
          <Row >

            <Col span={12} >
              <Form
                colon={false}
                labelCol={{
                  xl: {
                    span: 8,
                  },
                }}
                wrapperCol={{
                  xl: {
                    span: 24,
                  },
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                form={form}
                layout={"vertical"}
              >
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <InputUpload fileList={fileList} setFileList={setFileList} />
                  <Text type="secondary">{t("item.pleaseInsertImage")}</Text>
                </Space>
                <Form.Item
                  name="code"
                  label={t("item.code")}
                  rules={[
                    {
                      required: true,
                      message: t("item.pleaseCode"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("item.enterCode")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="category"
                  label={t("item.category")}
                  rules={[
                    {
                      required: true,
                      message: t("item.pleaseCategory"),
                    },
                  ]}
                >

                  <Select
                    showSearch
                    placeholder={t("item.enterCategory")}
                    optionFilterProp="children"
                    allowClear={true}
                    size="large"
                    style={{ borderRadius: "10px" }}
                  >

                    {categories.map((category) => (
                      <Option value={category.id} key={category.id}>
                        {category.name}
                      </Option>
                    ))}

                  </Select>

                </Form.Item>
                <Form.Item
                  name="name"
                  label={t("item.name")}
                  rules={[
                    {
                      required: true,
                      message: t("item.pleaseName"),
                    },
                  ]}
                >

                  <Input
                    placeholder={t("item.enterName")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="cod_buy_price"
                  label={t("item.codBuyPrice")}
                  rules={[
                    {
                      required: true,
                      message: t("item.pleaseCodBuyPrice"),
                    },
                  ]}
                >

                  <Input
                    placeholder={t("item.enterCodBuyPrice")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="cod_sale_price"
                  label={t("item.codSalePrice")}
                  rules={[
                    {
                      required: true,
                      message: t("item.pleaseCodSalePrice"),
                    },
                  ]}
                >

                  <Input
                    placeholder={t("item.enterCodSalePrice")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="credit_buy_price"
                  label={t("item.creditBuyPrice")}
                  rules={[
                    {
                      required: true,
                      message: t("item.pleaseCreditBuyPrice"),
                    },
                  ]}
                >

                  <Input
                    placeholder={t("item.enterCreditBuyPrice")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="credit_sale_price"
                  label={t("item.creditSalePrice")}
                  rules={[
                    {
                      required: true,
                      message: t("item.pleaseCreditBuyPrice"),
                    },
                  ]}
                >

                  <Input
                    placeholder={t("item.enterCreditSalePrice")}
                    style={{ borderRadius: "10px" }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                  <LargeButton icon={<SaveOutlined />} onClick={() => { }} bgColor="var(--primary-color)" text={t("component.save")} />
                </Form.Item>
              </Form>
            </Col>

            <Col xl={{ span: 12 }}>
            </Col>
          </Row>


        </Space>

      </Layout>
    </Spin>
  );
};


export default EditItem;
