import { DELETE_COMMISSION, SET_COMMISSION, SET_COMMISSIONS } from "../type";

  const initialState = {
    commissions: [],
    commission: {},
  };
  
  const commission = (state = initialState, action) => {
    switch (action.type) {
      case SET_COMMISSION:
        return {
          ...state,
          commission: action.payload,
        };
      case SET_COMMISSIONS:
        return {
          ...state,
          commissions: action.payload,
        };
      case DELETE_COMMISSION:
        return {
          ...state,
          commissions: state.commissions.filter((commission) => commission.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default commission;
  