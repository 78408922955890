import React, { useEffect } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Table,
  Spin,
  Popconfirm,
  message
} from "antd";
import Layout from "antd/lib/layout/layout";
import { PlusSquareOutlined, DeleteOutlined } from "@ant-design/icons";
import MediumButton from "../../components/MediumButton";
import IconButton from "../../components/IconButton";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteUser, getUsers } from "../../store/actions";
import ExportToExcel from "../../components/ExportToExcel";
import { useTranslation } from "react-i18next";
const { Title } = Typography;

const ShowAccounts = () => {
  const navigate = useNavigate();
  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  const users = useSelector((state) => state.auth.users);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    error.message !== null && message.error(error.message);
    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (status.success) {
      message.success(t("message.successDeleteMessage"));
    }
    return () => status.success;
  }, [status.success,t]);

  const handleDelete = async (id) => {
    await dispatch(deleteUser(id));
  }

  //for excel export
  const fileName = "Users";
  const transformedData = users.map((user)=>{
    return {
      User: user.name,
      Position: user.position,
    };
  });

  const columns = [
    {
      title: t("account.name"),
      dataIndex: "name",
    },
    {
      title: t("account.position"),
      dataIndex: "position",
    },
    {
      title: t("component.actions"),
      dataIndex: "id",
      render: (id) => (
        <Space direction="horizontal">
          <Popconfirm
            title={t("component.sureDelete")}
            okText="Yes"
            cancelText="No"
            onConfirm={() => { handleDelete(id) }}
          >
            <IconButton icon={<DeleteOutlined />} onClick={() => { }} bgColor="var(--red-color)"> </IconButton>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px 40px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 18 }} md={{ span: 12 }}>
              <Title level={3}>{t("account.accountList")}</Title>
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 6 }}>
              <MediumButton icon={<PlusSquareOutlined />} text={t("component.addNew")} bgColor="var(--primary-color)" onClick={() => navigate("/admin/create-account")} />
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 6 }}>
             <ExportToExcel apiData={transformedData} fileName={fileName}/>
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={users}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

export default ShowAccounts;
