import { DELETE_EXPENSE_NAME, SET_EXPENSES_NAMES, SET_EXPENSE_NAME } from "../type";

  const initialState = {
    expenseNames: [],
    expenseName: {},
  };
  
  const expenseName = (state = initialState, action) => {
    switch (action.type) {
      case SET_EXPENSE_NAME:
        return {
          ...state,
          expenseName: action.payload,
        };
      case SET_EXPENSES_NAMES:
        return {
          ...state,
          expenseNames: action.payload,
        };
      case DELETE_EXPENSE_NAME:
        return {
          ...state,
          expenseNames: state.expenseNames.filter((expenseName) => expenseName.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default expenseName;
  