import { SET_PURCHASE_FOCS, SET_SALE_FOCS } from "../type";

const initialState = {
    purchaseFocs: [],
    saleFocs: [],
};
const foc = (state = initialState, action) => {
    switch (action.type) {
        case SET_PURCHASE_FOCS:
            return {
                ...state,
                purchaseFocs: action.payload,
            };
        case SET_SALE_FOCS:
            return {
                ...state,
                saleFocs: action.payload,
            };
       
        default:
            return state;
    }
};
export default foc;